import {
  OVERVIEW_ANALYSIS,
  REFERENCE_ANALYSIS,
  ReportAnalysisTypeMap,
  SUB_TYPE_ABS,
  SUB_TYPE_ALL,
  SUB_TYPE_REL,
} from 'constants/AnalysisConstant'
import {ElementPDF} from 'components/molcules/GenerateReportPDF'
import {ReportInfoProps} from 'components/molcules/Report/ReportInfo'
import {RenderingItem} from 'pages/Analysis/EEGIndividual/Report/ReportItem'
import {
  getPersonalData,
  getDataRaw,
  getBandPower,
  getICAComponent,
  getOccipital,
  getRatio,
  getSpectrum,
  getRoi,
  RoiType,
  IcaType,
} from 'components/molcules/GenerateReportPDF/EEGReport/data'
import {
  LABEL_LIST,
  SUB_TYPE_ALL_ABS,
  SUB_TYPE_ALL_REL,
  SUB_TYPE_DMN_ABS,
  SUB_TYPE_DMN_REL,
} from 'pages/Analysis/EEGNorm/Report'
import {AnalysisResultItem} from 'api/analysisApi'
import overviewEEGPdf from 'components/molcules/GenerateReportPDF/Overview'
import generateReferencePdf from 'components/molcules/GenerateReportPDF/ReferencePDF'

const getListROI = (
  list: AnalysisResultItem[],
  idx: number,
  type: string,
  position: string,
  indexPage: number,
) => {
  let idxDescription = 0
  const data = list.map((item, index: number) => {
    return {
      title: `${indexPage}-${idx}-${index + 1}-${
        LABEL_LIST[index]
      }(${position})`,
      img: item.imgPath,
    }
  })
  if (idx === 3 || idx === 4) {
    idxDescription = 1
  }
  const firstPage = {
    title: `${indexPage}-${idx}-${type}`,
    description: list[0].titleDescription
      ? list[0].titleDescription
          .split('///')
          [idxDescription].replace(/<br\s*\/?>/gi, '\n')
          .replace(/<[^>]*>/g, '')
      : '',
  }
  return [firstPage, ...data]
}

const getListDataIca = (
  list: AnalysisResultItem[],
  index: number,
  componentList: string,
) => {
  const result: IcaType[] = []
  for (let i = 0; i < list.length; i += 2) {
    const icaItem: IcaType = {
      img: list[i].imgPath,
      title: `${index}-${i + 1} Component${i + 1}`,
    }
    if (i === 0) {
      icaItem.componentList = componentList
      icaItem.titlePage = `${index}.ICA components`
      icaItem.description = list[0].titleDescription
        ? list[0].titleDescription
        : ''
    }
    if (i + 1 < list.length) {
      icaItem.img1 = list[i + 1].imgPath
      icaItem.title1 = `${index}-${i + 2} Component${i + 2}`
    }

    result.push(icaItem)
  }
  return result
}

const getContentEEGIndividualPDF = (
  listChecked: string[],
  renderingItems: RenderingItem[],
  userInfo: ReportInfoProps | undefined,
  componentList: number[],
  screen: 'EEG' | 'NORM',
  listSelectSpectrum: AnalysisResultItem[],
  t: any,
): ElementPDF[][] => {
  let contentPDF: ElementPDF[][] = []
  let index = 1
  renderingItems.forEach((item) => {
    const {codeId} = item[0]

    // get data for rawData
    if (
      codeId === ReportAnalysisTypeMap.RawData &&
      listChecked.includes(codeId)
    ) {
      let dataAdd: ElementPDF[][] = []
      const rawData = item[1]?.filter((value) => value.imgType === 'PDF')
      if (rawData && rawData[0]) dataAdd = getDataRaw(rawData[0], index)
      contentPDF = [...contentPDF, ...dataAdd]
      index += 1
    }

    // get data for ica components
    if (
      codeId === ReportAnalysisTypeMap.ICAComponents &&
      listChecked.includes(codeId)
    ) {
      const listDataICA = item
        ? item[1].filter((value) => value.componentType !== 0)
        : []

      const dataForGenerateIca = getListDataIca(
        listDataICA,
        index,
        componentList.join(','),
      )

      contentPDF = [...contentPDF, ...getICAComponent(dataForGenerateIca)]
      index += 1
    }

    // get data for band power
    if (
      codeId === ReportAnalysisTypeMap.BandPower &&
      listChecked.includes(codeId)
    ) {
      const listTitle = ['Band', 'Absolute', 'Relative']
      const sortBand = [SUB_TYPE_ALL, SUB_TYPE_ABS, SUB_TYPE_REL]
      const listDataBand = item
        ? item[1]
            .sort((a: {subType: number}, b: {subType: number}) => {
              return sortBand.indexOf(a.subType) - sortBand.indexOf(b.subType)
            })
            .map((item, i: number) => {
              return {
                title: `${index}-${i + 1} ${listTitle[i]} `,
                img: item.imgPath,
              }
            })
        : []
      const description = item ? item[0].titleDescription : ''
      contentPDF = [
        ...contentPDF,
        ...getBandPower(listDataBand, index, description),
      ]
      index += 1
    }

    // get data for power spectrum
    if (
      codeId === ReportAnalysisTypeMap.PowerSpectrum &&
      listChecked.includes(codeId)
    ) {
      contentPDF = [...contentPDF, ...getSpectrum(listSelectSpectrum, index)]
      index += 1
    }

    // get data for occipital alpha peak
    if (
      codeId === ReportAnalysisTypeMap.OccipitalAlphaPeak &&
      listChecked.includes(codeId)
    ) {
      const occipitalTitle = item ? item[0].titleDescription : undefined
      const occipitalImg = item ? item[1] : undefined
      contentPDF = [
        ...contentPDF,
        ...getOccipital(occipitalTitle, occipitalImg, index),
      ]
      index += 1
    }

    // get data for power ratio
    if (
      codeId === ReportAnalysisTypeMap.PowerRatio &&
      listChecked.includes(codeId)
    ) {
      const ratioTitle = item ? item[0].titleDescription : undefined
      const ratioImg = item ? item[1] : undefined
      contentPDF = [...contentPDF, ...getRatio(ratioTitle, ratioImg, index)]
      index += 1
    }

    // get data for Source ROI power
    if (
      codeId === ReportAnalysisTypeMap.SourceROIPower &&
      listChecked.includes(codeId)
    ) {
      const listAllAbsolute = item
        ? item[1]
            .filter((value) => value.subType === SUB_TYPE_ALL_ABS)
            .sort((value) => value.bandType)
        : []
      const listAllRelative = item
        ? item[1]
            .filter((value) => value.subType === SUB_TYPE_ALL_REL)
            .sort((value) => value.bandType)
        : []
      const listDMNAbsolute = item
        ? item[1]
            .filter((value) => value.subType === SUB_TYPE_DMN_ABS)
            .sort((value) => value.bandType)
        : []
      const listDMNRelative = item
        ? item[1]
            .filter((value) => value.subType === SUB_TYPE_DMN_REL)
            .sort((value) => value.bandType)
        : []

      const dataRoi = [
        ...getListROI(listAllAbsolute, 1, 'All', 'Absolute', index),
        ...getListROI(listAllRelative, 2, 'All', 'Relative', index),
        ...getListROI(listDMNAbsolute, 3, 'DMN', 'Absolute', index),
        ...getListROI(listDMNRelative, 4, 'DMN', 'Relative', index),
      ]

      contentPDF = [...contentPDF, ...getRoi(dataRoi as RoiType[], index)]
    }
  })

  const header: ElementPDF[] = [
    {
      type: 'text',
      content: 'II.EEG',
      fontStyle: 'bold',
      fontSize: 14,
      uppercase: false,
      x: 10,
      y: 23,
    },
  ]
  if (userInfo) {
    contentPDF = [
      getPersonalData({
        ...userInfo,
        t,
        screen,
      }),
      ...contentPDF,
    ]
  }

  if (userInfo && contentPDF.length > 1) {
    contentPDF[1].forEach((element) => {
      element.y += 10
      if (element.y1 && element.type !== 'fill') {
        element.y1 += 10
      }
    })
    contentPDF[1] = [...header, ...contentPDF[1]]
  }

  contentPDF = [
    ...contentPDF,
    ...overviewEEGPdf(listChecked, screen),
    ...generateReferencePdf(listChecked),
  ]

  return contentPDF
}

export const listCommonDownloadPdf = [
  {
    id: ReportAnalysisTypeMap.RawData,
    type: 'raw',
    label: '01. Raw Data',
    checked: false,
    disable: false,
  },
  {
    id: ReportAnalysisTypeMap.ICAComponents,
    type: 'ica',
    label: '02. ICA components',
    checked: false,
    disable: false,
  },
  {
    id: ReportAnalysisTypeMap.BandPower,
    type: 'band',
    label: '03. Band power – Topomap',
    checked: false,
    disable: false,
  },
  {
    id: ReportAnalysisTypeMap.PowerSpectrum,
    type: 'spectrum',
    label: '04. Power spectrum',
    checked: false,
    disable: false,
  },
  {
    id: ReportAnalysisTypeMap.OccipitalAlphaPeak,
    type: 'occipital',
    label: '05. Occipital alpha peak',
    checked: false,
    disable: false,
  },
  {
    id: ReportAnalysisTypeMap.PowerRatio,
    type: 'ratio',
    label: '06. Power ratio',
    checked: false,
    disable: false,
  },
]
export const listAdditionalDownloadPdf = [
  {
    id: ReportAnalysisTypeMap.SourceROIPower,
    type: 'roi',
    label: '07. Source ROI power',
    checked: false,
    disable: false,
  },
  {
    id: ReportAnalysisTypeMap.ThreeDView,
    type: '3dView',
    label: '08. 3D View',
    checked: false,
    disable: true,
  },
]
export const listFooterDownloadIndividualPdf = [
  {
    id: OVERVIEW_ANALYSIS,
    type: 'overview',
    label: 'Overview',
    checked: false,
    disable: false,
  },
  {
    id: REFERENCE_ANALYSIS,
    type: 'reference',
    label: 'Reference',
    checked: false,
    disable: false,
  },
]

export default getContentEEGIndividualPDF
