/* eslint-disable prettier/prettier */
import styled, {keyframes} from 'styled-components'
import {createStyles} from '@material-ui/core/styles'

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  
  100% {
    transform: rotate(359deg);
  }
`
interface StyledSettingsProps {
  open: boolean
  width: number
}
const Container = styled.div<StyledSettingsProps>`
  z-index: 9999;
  position: fixed;
  right: ${(props) => (props.open ? 20 : -props.width)}px;
  top: 200px;
  display: flex;
  transition: all 0.3s ease-in-out;
`
export const SettingContainer = styled.div`
  cursor: pointer;
  color: #fff;
  margin-top: 16px;
  background-color: #4d5d6b;
  font-size: 20px;
  border-radius: 20px 0 0 20px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  & .MuiSvgIcon-root {
    animation: ${spin} 3s infinite linear;
  }
`

export const ContentContainer = styled.div<{width: number}>`
  padding: 16px;
  width: ${(props) => props.width}px;
  background: white;
  border: 2px solid #4d5d6b;
  box-shadow: -3px 2px 12px -1px rgb(0 0 0 / 15%);
`
export const Title = styled.p`
  color: #fff;
  padding: 8px 10px;
  background: #2f404f;
  margin: 0;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  font-weight: bold;
`
export const SelectContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: 35px;
`

export const ListContainer = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
export const SelectOption = styled.select`
  width: 43%;
  height: 40px;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 15px) calc(1em + 4px),
    calc(100% - 10px) calc(1em + 4px), calc(100% - 2.3em) 0.8em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  padding-right: 40px;
  background-color: #ffffff;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  color: inherit;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  -webkit-appearance: none;
  &:focus {
    border-color: #1ab394 !important;
  }
`
export const BtnDownload = styled.div`
  width: 43%;
  height: 40px;
`
export const DownloadPdfStyle = () =>
  createStyles({
    select: {
      '&:focus': {
        borderColor: '#1ab394',
      },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    iconFileExcel: {
      height: '15px',
      marginRight: '5px',
    },
    btnDownloadPdf: {
      height: '24px',
      display: 'flex',
      alignItems: 'center',
    },
  })
export default Container
