import {
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import ReorderIcon from '@material-ui/icons/Reorder'
import {fetchSalesUserLogListApi, UserLogListRequestDto} from 'api/salesApi'
import useFailureModal from 'features/modal/useFailureModal'
import {ClientName} from 'components/atoms/ClientName'
import RangeDatePicker from 'components/Calender/CalenderRangePicker'
import SearchBar from 'components/Forms/SearchBar'
import Pagination from 'components/molcules/Pagination'
import {useOutlinedInputStyles} from 'components/Table/useTableToolbarStyles'
import useAuth from 'features/auth/useAuth'
import {
  dateToPeriodString,
  getDefaultPeriodString,
  isoStringToDateAndTimeString,
} from 'helpers/dateHelper'
import React from 'react'
import {TFunction, useTranslation} from 'react-i18next'
import {useQuery} from 'react-query'

/**
 * 사용자 계정 관리 관련 테이블
 */
// TODO: 사용자 계정 관리에 맞게 interface 수정해야함
interface HeadCell {
  id: keyof SalesUserLogContent
  label: string
}
const headCells: HeadCell[] = [
  {id: 'logTime', label: 'IDate'},
  {id: 'orgName', label: 'IOrgName'},
  {id: 'userId', label: 'IUserName'},
  {id: 'content', label: 'IContents'},
]
const useTableStyles = makeStyles(() =>
  createStyles({
    container: {
      marginBottom: 15,
      minHeight: 500,
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    tableHeader: {
      height: 50,
    },
    hover: {
      height: 50,
      '&:hover': {
        backgroundColor: '#F8F8F8 !important',
      },
    },
    rowButton: {
      minWidth: 65,
      alignSelf: 'center',
    },
  }),
)
interface EnhancedTableProps {
  classes: ReturnType<typeof useTableStyles>
}
function EnhancedTableHead(props: EnhancedTableProps) {
  const {classes} = props
  const {t} = useTranslation()
  return (
    <TableHead>
      <TableRow className={classes.tableHeader}>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align='center' padding='none'>
            {t(headCell.label)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface UserLogProps {
  isLoading: boolean
  data: ModelResponse<SalesUserLog> | undefined
  handlePageChange: (page: number) => void
  handleSizeChange: (page: number) => void
}

function UserLogTable({
  isLoading,
  data,
  handlePageChange,
  handleSizeChange,
}: UserLogProps) {
  const classes = useTableStyles()

  const rowsPerPage = 5

  const item = data?.data?.content ?? []
  const emptyRows = rowsPerPage - item.length

  return (
    <TableContainer className={classes.container}>
      <Table
        stickyHeader
        className={classes.table}
        aria-labelledby='tableTitle'
        aria-label='enhanced table'
        size='small'
      >
        <EnhancedTableHead classes={classes} />
        <TableBody>
          {data?.data.content &&
            data.data.content.map((item) => {
              return (
                <TableRow hover role='checkbox' className={classes.hover}>
                  <TableCell align='center'>
                    {isoStringToDateAndTimeString(item.logDate)}
                  </TableCell>
                  <TableCell align='center'>{item.orgName ?? '-'}</TableCell>
                  <TableCell align='center'>
                    <ClientName
                      firstName={item?.firstName ?? '-'}
                      lastName={item?.lastName ?? '-'}
                    />
                  </TableCell>
                  <TableCell align='center'>{item.content ?? '-'}</TableCell>
                </TableRow>
              )
            })}
          {emptyRows > 0 && (
            <TableRow
              style={{height: 43 * emptyRows, backgroundColor: '#F9F9FB'}}
            >
              <TableCell colSpan={12} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Pagination
        totalPageCount={data?.data.totalPages ?? 0}
        currentPageIndex={data?.data.pageable.pageNumber ?? 0}
        itemCountPerPage={data?.data.size ?? 0}
        setCurrentPageIndex={handlePageChange}
        loading={isLoading}
        onItemCountPerPageChanged={(event) => {
          handleSizeChange(parseInt(event.target.value as string, 10))
        }}
      />
    </TableContainer>
  )
}

/**
 * 사용자 관리 페이지 관련
 */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    gap: 10,
  },
  containerTitle: {
    padding: 10,
    fontWeight: 600,
    color: '#35495D',
    marginLeft: 20,
  },
  navContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    flexWrap: 'wrap',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    flex: 1,
  },

  searchButton: {
    height: 56,
    marginTop: 15,
  },

  indexInputContainer: {
    marginTop: 6,
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    gap: 5,
  },
  selectBox: {
    flexGrow: 1,
    backgroundColor: '#fff',
  },
  disableText: {
    color: '#a6a6a6',
  },
  button: {
    height: 55,
    minWidth: 110,
    maxWidth: 110,
  },
  tableWrap: {
    backgroundColor: '#fff',

    marginTop: 10,
    overflow: 'Hidden',
    // margin: 15,
  },
  chipsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    marginLeft: 15,
    marginRight: 15,
  },
  textField: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(1),
  },
  align: {
    justify: 'center',
  },
  dateSearch: {
    marginLeft: 10,
  },
  dateWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    flex: 1,
    '& h6': {
      margin: '0 10px',
    },
  },
  hover: {
    '&:hover': {
      backgroundColor: '#F8F8F8 !important',
    },
  },
}))

const initialQuery = {
  page: 0,
  size: 10,
  searchName: '',
  searchValue: '',
  startDate: getDefaultPeriodString().startDate,
  endDate: getDefaultPeriodString().endDate,
}

const searchNameList = [
  {label: 'ISelect', value: '', isDisabled: true},
  {label: 'IOrgNameShort', value: 'NAME', isDisabled: false},
  {label: 'IUserName', value: 'USER', isDisabled: false},
]

const searchNameListMap = (
  classes: any,
  t: TFunction<'translation', undefined>,
) =>
  searchNameList.map((item) => (
    <MenuItem key={item.label} value={item.value} disabled={item.isDisabled}>
      {item.isDisabled ? (
        <Typography className={classes.disableText}>{t('ISelect')}</Typography>
      ) : (
        t(item.label)
      )}
    </MenuItem>
  ))

export default function UserLogTablePage() {
  const {t} = useTranslation()
  const classes = useStyles()
  const outlinedInputClasses = useOutlinedInputStyles()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const [searchValue, setSearchValue] = React.useState('')
  const [selectOptionValue, setSelectOptionValue] = React.useState('')
  const inputSearchKey = React.useRef(1)
  const {user: currentUser} = useAuth()
  const [query, setQuery] = React.useState<UserLogListRequestDto>({
    ...initialQuery,
    uid: currentUser?.uid ?? '',
  })

  const {isLoading, data} = useQuery(
    ['fetchUsersLogs', query],
    async () => {
      try {
        const response = await fetchSalesUserLogListApi(query)
        return response
      } catch (error) {
        await onFailureModalOpen(error.message)
        throw new Error()
      }
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess: () => {},
      retry: false,
    },
  )

  const handleDateValue = (value: PeriodDate) => {
    // Refactor start date plus 1 for match with  server date
    value.startDate.setDate(value.startDate.getDate() + 1)
    const {startDate, endDate} = dateToPeriodString(value as PeriodDate)
    setQuery((prev) => ({
      ...prev,
      page: 0,
      startDate,
      endDate,
    }))
  }

  const handleChangeSearchName = (
    e: React.ChangeEvent<{
      value: unknown
    }>,
  ) => {
    setSelectOptionValue(e.target.value as string)
  }

  const handleInputSearch = (value: string) => {
    setSearchValue(value)
  }

  const handleSearchEvent = () => {
    setQuery((prev) => ({
      ...prev,
      page: 0,
      searchName: selectOptionValue,
      searchValue,
    }))
  }

  const handleViewAll = () => {
    handleInputSearch(initialQuery.searchValue)
    setSelectOptionValue(initialQuery.searchName)
    inputSearchKey.current += 1
    setQuery({
      ...initialQuery,
      uid: currentUser?.uid ?? '',
    })
  }

  const handlePageChange = (pageNumber: number) => {
    setQuery((prev) => ({
      ...prev,
      page: pageNumber,
    }))
  }

  const handleSizeChange = (pageSize: number) => {
    setQuery((prev) => ({
      ...prev,
      size: pageSize,
    }))
  }
  // 엑셀 다운로드
  // const handleExcelDownload = async () => {
  //   const result = await fileDownload({
  //     path: '/api/manager/v1/org/log/download/excel',
  //     params: {
  //       size: 10,
  //       page,
  //       oid: organization?.oid,
  //     },
  //     fileName: getDateForFile(),
  //   })
  //   console.log({result})
  // }

  return (
    <div>
      <Grid className={classes.navContainer}>
        <Grid item xs={12}>
          <div className={classes.dateContainer}>
            <RangeDatePicker
              onChangeValue={handleDateValue}
              dateValue={{
                startDate: new Date(query.startDate as string),
                endDate: new Date(query.endDate as string),
              }}
            />
            <Typography className={classes.padding} />
            <div className={classes.indexInputContainer}>
              <FormControl variant='outlined' className={classes.selectBox}>
                <Select
                  value={selectOptionValue}
                  onChange={handleChangeSearchName}
                  displayEmpty
                  color='primary'
                  input={
                    <OutlinedInput
                      id='outlined-age-simple'
                      classes={outlinedInputClasses}
                    />
                  }
                >
                  {searchNameListMap(classes, t)}
                </Select>
              </FormControl>
              <SearchBar
                onChangeValue={handleInputSearch}
                onSearchEvent={handleSearchEvent}
                disabledValue={false}
                key={inputSearchKey.current}
              />
              <Button
                variant='contained'
                color='primary'
                startIcon={<ReorderIcon />}
                disableElevation
                onClick={handleViewAll}
              >
                {t('IReadAll')}
              </Button>
              {/* 엑셀 다운로드 버튼 추후에 추가될 가능성이 있음  */}
              {/* <Button
                variant='contained'
                color='secondary'
                disableElevation
                onClick={() => handleExcelDownload()}
              >
                {t('IExcelDownload')}
              </Button> */}
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.tableWrap}>
        <UserLogTable
          isLoading={isLoading}
          data={data}
          handlePageChange={handlePageChange}
          handleSizeChange={handleSizeChange}
        />
      </Grid>
    </div>
  )
}
