import {faCogs} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import DeleteIcon from '@material-ui/icons/Delete'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import {finalizeIcaApi} from 'api/analysisApi'
import {Colors} from 'components/common/useBaseStyle'
import useFailureModal from 'features/modal/useFailureModal'
import {PrimaryButton} from 'components/CustomButtons/PrimaryButton'
import {darken} from 'polished'
import React, {KeyboardEvent, MutableRefObject, useRef, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'

const StyledInputText = styled.input`
  box-sizing: border-box;
  width: 93px;
  border: 1px solid #e5e6e7;
  border-radius: 5px;
  padding: 10px 15px 10px 15px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;

  &:focus,
  &:focus-visible {
    border: 1px solid ${Colors.primary};
    outline: none;
  }
`

const StyledTsrSettings = styled.div`
  & .Column {
    display: flex;
    flex-direction: column;
    justify-items: center;
    gap: 5px;
  }

  & .Row {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  & .Contents {
    & .RejectionInput {
      margin-bottom: 10px;

      ${PrimaryButton} {
        align-self: normal;
        background-color: ${Colors.warning};
        border: 1px solid ${Colors.warning};

        &:active {
          background-color: ${darken(0.1, Colors.warning)};
          border-color: ${darken(0.1, Colors.warning)};
        }

        &:focus-visible {
          transform: scale(1.1);
          outline: none;
        }
      }
    }

    & .Rejection {
      & .Title {
        color: #333333;
        background-color: #eef1f6;
        padding: 7px 10px;
        font-size: 12px;

        & .MuiSvgIcon-root {
          font-size: 16px;
          color: inherit;
          margin-right: 5px;
        }
        & .Text {
          font-size: inherit;
          color: inherit;
          font-weight: bold;
        }
      }

      & .List {
        margin: 0;
        padding: 0;
        gap: 0;

        & .Item {
          box-sizing: border-box;
          border-bottom: 1px dashed #eeeeee;
          padding: 7px 0;

          &:last-child {
            border-bottom: 1px dashed transparent;
          }

          & .Time {
            border: 1px solid #eee;
            border-radius: 5px;
            padding: 2px 10px;
          }
          & .DeleteButton {
            color: #333333;
            margin-left: auto;
            border: none;
            background-color: transparent;

            & .MuiSvgIcon-root {
              cursor: pointer;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
`

const specialKeys = [
  'Delete',
  'Backspace',
  'ArrowLeft',
  'ArrowRight',
  'Shift',
  'Tab',
]

const availableKeys = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '.',
  ...specialKeys,
]

const handleKeyPress =
  (ref: MutableRefObject<HTMLInputElement>) =>
  (e: KeyboardEvent<HTMLInputElement>) => {
    const {key} = e
    if (!availableKeys.includes(key)) {
      e.preventDefault()
      return false
    }

    if (specialKeys.includes(key)) {
      return true
    }

    let {value} = ref.current
    const doubleDotRegex = /../g
    const match = value.match(doubleDotRegex)
    if (match !== null) {
      value = value.replace('..', '.')
      ref.current.value = value
    }

    const floatOneRegex = /^\d*[.]\d{1}$/
    if (floatOneRegex.test(value)) {
      e.preventDefault()
      return false
    }

    return true
  }

type Rejection = [number, number]

const flatten = (rejections: Rejection[]): number[] =>
  rejections.reduce<number[]>((accr, curr) => accr.concat(curr), [])

export interface TsrSettingsProps {
  analysisTime: number
  requestId: number
  onProcess: () => void
}

export function TsrSettings({
  requestId,
  analysisTime,
  onProcess,
}: TsrSettingsProps) {
  const {t} = useTranslation()
  const startTimeRef = useRef() as React.MutableRefObject<HTMLInputElement>
  const endTimeRef = useRef() as React.MutableRefObject<HTMLInputElement>
  const {onOpen: onFailureModalOpen} = useFailureModal()

  const handleStartTimeKeyPressed = handleKeyPress(startTimeRef)
  const handleEndTimeKeyPressed = handleKeyPress(endTimeRef)

  const [rejections, setRejections] = useState<Rejection[]>([])

  const addRejection = (start: number, end: number) =>
    setRejections((rejections) => [...rejections, [start, end]])

  const removeRejection = (start: number, end: number) =>
    setRejections((rejections) =>
      rejections.filter(([rStart, rEnd]) => rStart !== start && rEnd !== end),
    )

  const isValidRejectionRange = (start: number, end: number) => {
    const startViolation = rejections.some(
      ([rStart, rEnd]) => start >= rStart && start < rEnd,
    )
    if (startViolation) {
      return false
    }

    const endViolation = rejections.some(
      ([rStart, rEnd]) => end > rStart && end <= rEnd,
    )
    if (endViolation) {
      return false
    }

    return true
  }

  const handleBtnReject = () => {
    if (analysisTime <= 0) {
      onFailureModalOpen(t('ITsr_v_analysisTimeTooShort'))
      return
    }

    if (startTimeRef.current.value === '') {
      startTimeRef.current.focus()
      return
    }

    if (endTimeRef.current.value === '') {
      startTimeRef.current.focus()
      return
    }

    const start = Number.parseFloat(startTimeRef.current.value)
    const end = Number.parseFloat(endTimeRef.current.value)

    if (Number.isNaN(start)) {
      startTimeRef.current.focus()
      return
    }

    if (Number.isNaN(end)) {
      endTimeRef.current.focus()
      return
    }

    if (start >= end) {
      onFailureModalOpen(t('ITsr_v_startTimeMoreThanEndTime'))
      startTimeRef.current.focus()
      return
    }

    if (start >= analysisTime) {
      onFailureModalOpen(t('ITsr_v_startTimeMoreThanAnalysisTime'))
      startTimeRef.current.focus()
      return
    }

    if (end > analysisTime) {
      onFailureModalOpen(t('ITsr_v_endTimeMoreThanAnalysisTime'))
      endTimeRef.current.focus()
      return
    }

    if (!isValidRejectionRange(start, end)) {
      onFailureModalOpen(t('ITsr_v_overlapped'))
      startTimeRef.current.focus()
      return
    }

    const totalRejectedTime = rejections.reduce(
      (total, [rStart, rEnd]) => total + (rEnd - rStart),
      0,
    )
    const remainingTime = analysisTime - totalRejectedTime - (end - start)
    if (remainingTime < 30) {
      onFailureModalOpen(t('ITsr_v_analysisTimeFinal'))
      return
    }

    addRejection(start, end)
    startTimeRef.current.value = ''
    endTimeRef.current.value = ''
    startTimeRef.current.focus()
  }

  const handleSubmit = () => {
    const flattened = flatten(rejections).sort((a, b) => a - b)

    finalizeIcaApi({
      requestId,
      timeArray: flattened ?? [],
    })
      .then(() => {
        onProcess()
      })
      .catch((err) => onFailureModalOpen(err.message))
  }

  return (
    <StyledTsrSettings>
      <div className='Header'>
        <h3 className='Title'>{t('IManualTsr')}</h3>
        <h5 className='SubTitle'>
          {t('IManualTsrDesc', {time: analysisTime})}
        </h5>
      </div>
      <div className='Contents'>
        <div className='RejectionInput Row'>
          <StyledInputText
            placeholder={t('IStartTime')}
            type='text'
            ref={startTimeRef}
            onKeyPress={handleStartTimeKeyPressed}
            pattern='/^(?!0\d)\d*([.]\d)?$/'
            tabIndex={0}
          />
          ~
          <StyledInputText
            placeholder={t('IEndTime')}
            type='text'
            ref={endTimeRef}
            onKeyDown={handleEndTimeKeyPressed}
            pattern='/^(?!0\d)\d*([.]\d)?$/'
            tabIndex={0}
          />
          <PrimaryButton onClick={handleBtnReject} tabIndex={0}>
            {t('IReject')}
          </PrimaryButton>
        </div>
        <div className='Rejection Column'>
          <div className='Title Row'>
            <AccessTimeIcon />
            <span className='Text'>{t('IRejectionTime')}</span>
          </div>
          <ul className='List Column'>
            {rejections.map(([start, end]) => (
              <li className='Item Row' key={`${start}_${end}`}>
                <span className='Time'>{start}</span>
                <span>~</span>
                <span className='Time'>{end}</span>
                <button
                  className='DeleteButton'
                  type='button'
                  onClick={() => removeRejection(start, end)}
                >
                  <DeleteIcon />
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className='Footer'>
        <PrimaryButton onClick={handleSubmit}>
          <FontAwesomeIcon className='Icon' icon={faCogs} />
          {t('IFinalAnalysis')}
        </PrimaryButton>
      </div>
    </StyledTsrSettings>
  )
}
