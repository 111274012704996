import getJsPDF from 'components/molcules/GenerateReportPDF/ConfigFontPDF'
import {ElementPDF} from 'components/molcules/GenerateReportPDF'

const getTablePersonalData = (max: number): ElementPDF[] => [
  {
    type: 'text',
    content: 'Ⅰ. Personal Data',
    fontStyle: 'bold',
    fontSize: 12,
    uppercase: false,
    x: 10,
    y: 23,
  },
  {
    type: 'fill',
    content: '',
    color: '#f4f4f4',
    x: 10,
    y: 30,
    x1: 190,
    y1: 10,
  },
  {
    type: 'line',
    content: '',
    color: '#d9d9d9',
    x: 10,
    y: 30,
  },
  {
    type: 'line',
    content: '',
    color: '#d9d9d9',
    x: 10,
    y: 44 + max * 4,
    lineHeight: 0.25,
  },
]

export type UserInfoPDF = {
  title: string
  value: string
}

const X_CENTER_EEG = [25, 51, 69, 89, 117, 142, 164, 188]
const MAX_WIDTH_EEG = [30, 19, 10, 25, 27, 20, 24, 20]
const X_CENTER_HRV = [27, 56, 77, 101, 133, 162, 188]
const MAX_WIDTH_HRV = [30, 19, 10, 25, 27, 20, 20]

const getMaxLineHeight = (
  info: UserInfoPDF[],
  screen?: 'EEG' | 'HRV',
): number[] => {
  const pdf = getJsPDF()
  pdf.setFont('NotoSansKR', 'normal')
  pdf.setTextColor('#333333')
  pdf.setFontSize(8)
  const maxWidth = screen === 'EEG' ? MAX_WIDTH_EEG : MAX_WIDTH_HRV
  return info.map((item, index) => {
    const lines = pdf.splitTextToSize(item.value, maxWidth[index])
    return lines.length
  })
}

const personalData = (
  info: UserInfoPDF[],
  screen?: 'EEG' | 'HRV',
): ElementPDF[] => {
  const listLine = getMaxLineHeight(info, screen)
  const max = Math.max(...listLine)
  const data: ElementPDF[] = [...getTablePersonalData(max)]
  const xCenter = screen === 'EEG' ? X_CENTER_EEG : X_CENTER_HRV
  const maxWidth = screen === 'EEG' ? MAX_WIDTH_EEG : MAX_WIDTH_HRV

  const infoDa: ElementPDF[][] = info.map((item, index) => [
    {
      type: 'center',
      content: item.title,
      fontStyle: 'normal',
      fontSize: 8,
      color: '#333333',
      x: xCenter[index],
      y: 36,
      maxWidth: maxWidth[index],
    },
    {
      type: 'center',
      content: item.value,
      color: '#333333',
      fontStyle: 'normal',
      fontSize: 8,
      x: xCenter[index],
      y: 45 + (max - listLine[index]) * 2,
      maxWidth: maxWidth[index],
    },
  ])

  return [...data, ...infoDa.flat()]
}

export default personalData
