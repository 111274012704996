import {HRVAnalysisTypeMap} from 'constants/AnalysisConstant'
import {ElementPDF} from 'components/molcules/GenerateReportPDF'
import {AnalysisResultItem} from 'api/analysisApi'
import {ReportInfoProps} from 'components/molcules/Report/ReportInfo'
import {
  getHRVFrequencyDomain,
  getHRVNonlinear,
  getHRVTimeDomain,
  getHRVTimeSeries,
  getPersonalData,
} from 'components/molcules/GenerateReportPDF/HRVIndividual/data'

const getContentHRVPDF = (
  listChecked: string[],
  renderingItems: {
    [key: string]: AnalysisResultItem[]
  },
  userInfo: ReportInfoProps | undefined,
  t: any,
): ElementPDF[][] => {
  let contentPDF: ElementPDF[][] = []
  let index = 1

  const keys = Object.keys(renderingItems)
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < keys.length; i++) {
    const item = renderingItems[keys[i]]
    const {analysisType} = item[0]
    let dataAdd: ElementPDF[][] = []

    if (item.length < 2 || !listChecked.includes(analysisType)) {
      // eslint-disable-next-line no-continue
      continue
    }

    // get data for Time Series
    if (analysisType === HRVAnalysisTypeMap.TimeSeries)
      dataAdd = getHRVTimeSeries(item, index)

    // get data for Time Domain
    if (analysisType === HRVAnalysisTypeMap.TimeDomain)
      dataAdd = getHRVTimeDomain(item, index)

    // get data for Frequency Domain
    if (analysisType === HRVAnalysisTypeMap.FrequencyDomain)
      dataAdd = getHRVFrequencyDomain(item, index)

    // get data for Nonlinear
    if (analysisType === HRVAnalysisTypeMap.Nonlinear)
      dataAdd = getHRVNonlinear(item, index)

    contentPDF = [...contentPDF, ...dataAdd]
    index += 1
  }

  const header: ElementPDF[] = [
    {
      type: 'text',
      content: 'Ⅱ. HRV',
      fontStyle: 'bold',
      fontSize: 12,
      uppercase: false,
      x: 10,
      y: 60,
    },
  ]
  if (contentPDF.length) {
    contentPDF[0].forEach((element) => {
      element.y += 40
      if (element.y1 && element.type !== 'fill') {
        element.y1 += 40
      }
    })

    if (userInfo) {
      contentPDF[0] = [
        ...getPersonalData({...userInfo, t, screen: 'HRV'}),
        ...header,
        ...contentPDF[0],
      ]
    } else contentPDF[0] = [...header, ...contentPDF[0]]
  }

  return contentPDF
}

export const getAnalysisTitle = (analysisType: string): string => {
  if (analysisType === HRVAnalysisTypeMap.TimeSeries)
    return 'RR Time Series Data'
  if (analysisType === HRVAnalysisTypeMap.TimeDomain)
    return 'Time-Domain Results'
  if (analysisType === HRVAnalysisTypeMap.FrequencyDomain)
    return 'Frequency-Domain Results'
  if (analysisType === HRVAnalysisTypeMap.Nonlinear) return 'Nonlinear Results'
  return 'Unknown'
}

export const checkListDownloadPdf = [
  {
    id: HRVAnalysisTypeMap.TimeSeries,
    label: `01. ${getAnalysisTitle(HRVAnalysisTypeMap.TimeSeries)}`,
    checked: false,
  },
  {
    id: HRVAnalysisTypeMap.TimeDomain,
    label: `02. ${getAnalysisTitle(HRVAnalysisTypeMap.TimeDomain)}`,
    checked: false,
  },
  {
    id: HRVAnalysisTypeMap.FrequencyDomain,
    label: `03. ${getAnalysisTitle(HRVAnalysisTypeMap.FrequencyDomain)}`,
    checked: false,
  },
  {
    id: HRVAnalysisTypeMap.Nonlinear,
    label: `04. ${getAnalysisTitle(HRVAnalysisTypeMap.Nonlinear)}`,
    checked: false,
  },
]

export default getContentHRVPDF
