import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation, useParams} from 'react-router-dom'
import {navigate} from 'AppHistory'
import {number, object, SchemaOf, string} from 'yup'
import {CircularProgress} from '@material-ui/core'
import useQuery from 'hooks/useQuery'
import useLanguageReport from 'hooks/useLanguageReport'
import {fetchIndividualEegStatus} from 'api/analysisApi'
import TranslationSelect from 'components/atoms/TranslationSelect'
import ReportFetcher from 'pages/Analysis/EEGIndividual/Report/ReportFetcher'
import IcaReportFetcher, {
  IcaProcess,
} from 'pages/Analysis/EEGIndividual/ReAnalysis/IcaReport/IcaReportFetcher'
import TsrReportFetcher from 'pages/Analysis/EEGIndividual/ReAnalysis/TsrReport/TsrReportFetcher'
import RouteConstant from 'constants/RouteConstant'
import {Spacing} from 'components/common/useBaseStyle'
import styled from 'styled-components'

interface PathParams {
  id: number
  version: string
}

const QueryItemScheme: SchemaOf<PathParams> = object({
  id: number()
    .typeError('IInvalidRequest')
    .required('IInvalidRequest')
    .min(1, 'IInvalidRequest'),
  version: string().required('IInvalidRequest'),
})

export const ReportWrap = styled.div`
  display: flex;
  flex-direction: column;
`
export const ReportTranslationSelectWrap = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-right: ${Spacing.report.reportTranslationMargin}px;
`
export const ReportFetcherWrap = styled.div`
  display: flex;
  flex-direction: row;
`

export const ReportCustomPadding = styled.div`
  padding: ${Spacing.report.reportCustomPadding}px;
`
export const Loading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

function EEGReportPage() {
  const {t} = useTranslation()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [pathParams, setPathParams] = useState<PathParams>()
  const [icaProcessing, setIcaProcessing] = useState<IcaProcess>(
    'ica_process_success',
  )

  const {id} = useParams<{
    id?: string | undefined
  }>()
  function decodeHtmlEntity(str: string) {
    const textarea = document.createElement('textarea')
    textarea.innerHTML = str
    const result = textarea.value
    textarea.remove()
    return result
  }

  const location = useLocation()
  const param = decodeHtmlEntity(`${location.search}${location.hash}`)
  const clientNameIndex = param.indexOf('clientName=') + 'clientName='.length
  const endIndex = param.indexOf('&version', clientNameIndex)
  const clientNameEncode =
    endIndex === -1
      ? param.substring(clientNameIndex)
      : param.substring(clientNameIndex, endIndex)

  const userNameFromURL = JSON.parse(decodeURIComponent(clientNameEncode))

  const clientName = t('IGetFullName', {
    firstName: userNameFromURL.firstName ?? '',
    lastName: userNameFromURL.lastName ?? '',
    interpolation: {escapeValue: false},
  })

  const version = useQuery().get('version') ?? '3.0'

  const fetchStatus = async () => {
    try {
      if (pathParams) {
        const {data: status} = await fetchIndividualEegStatus(pathParams.id)
        switch (status) {
          case 'SUCCESS':
            setIcaProcessing('ica_process_success')
            setLoading(false)
            break
          case 'ICA':
            setIcaProcessing('ica_process_ica')
            setLoading(false)
            break
          case 'TIME_REJECTION':
            setIcaProcessing('ica_process_tsr')
            setLoading(false)
            break
          case 'PROCESSING':
            setIcaProcessing('ica_process_loading')
            break
          case 'ERROR':
            setIcaProcessing('ica_process_failure')
            setLoading(false)
            break
          default:
            break
        }
      }
    } catch (err) {
      setIcaProcessing('ica_process_failure')
      setLoading(false)
    }
  }

  useEffect(() => {
    QueryItemScheme.validate({
      id,
      version,
    })
      .then((value) => setPathParams(value))
      .catch((err) => setError(err.message))
  }, [id, version])

  useEffect(() => {
    fetchStatus()
  }, [pathParams])

  useEffect(() => {
    if (icaProcessing === 'ica_process_failure') {
      navigate(RouteConstant.ANALYSIS_EEG_19.path)
    }

    if (icaProcessing !== 'ica_process_loading') return
    setLoading(true)
    const intervalId = setInterval(async () => {
      await fetchStatus()
    }, 3000)
    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId)
  }, [icaProcessing])

  useLanguageReport()

  if (loading)
    return (
      <div>
        <Loading>
          <CircularProgress size='3rem' color='primary' />
        </Loading>
      </div>
    )
  if (!loading && error !== undefined) return <div>{t(error)}</div>
  if (!loading && pathParams !== undefined)
    return (
      <div id=''>
        <ReportWrap style={{width: '100%'}}>
          <ReportTranslationSelectWrap>
            <TranslationSelect />
          </ReportTranslationSelectWrap>
          <ReportFetcherWrap>
            <ReportCustomPadding />
            {icaProcessing === 'ica_process_success' && (
              <ReportFetcher
                id={pathParams.id}
                version={pathParams.version}
                clientName={clientName}
                screen='individual'
              />
            )}
            {icaProcessing === 'ica_process_ica' && (
              <IcaReportFetcher
                id={pathParams.id}
                clientName={clientNameEncode}
                screen='individual'
              />
            )}
            {icaProcessing === 'ica_process_tsr' && (
              <TsrReportFetcher
                id={pathParams.id}
                clientName={clientNameEncode}
                screen='individual'
              />
            )}
            <ReportCustomPadding />
          </ReportFetcherWrap>
          <ReportCustomPadding />
        </ReportWrap>
      </div>
    )
  return null
}
export default EEGReportPage
