import React, {useEffect, useState} from 'react'
import {useAsync} from 'react-async-hook'
import {useTranslation} from 'react-i18next'
import {useLocation, useRouteMatch} from 'react-router-dom'
import useQuery from 'hooks/useQuery'
import useLanguageReport from 'hooks/useLanguageReport'
import {CircularProgress} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import ReplayIcon from '@material-ui/icons/Replay'
import {
  AnalysisResultInfo,
  fetchNormativeEEGResult,
  FetchNormativeEEGResultRequest,
  AnalysisUserInfo,
  AnalysisResultItem,
} from 'api/analysisApi'
import CardWithTitle from 'components/atoms/CardWithTitle'
import ReportInfo, {
  ReportInfoProps,
} from 'components/molcules/Report/ReportInfo'
import TranslationSelect from 'components/atoms/TranslationSelect'
import PdfDownload, {ElementPDF} from 'components/molcules/GenerateReportPDF'
import CheckBox from 'components/Checkbox'
import barChart from 'assets/img/PDF/bar-chart.svg'
import generateTableOfContents from 'components/molcules/GenerateReportPDF/TableOfContents'
import {
  AnalysisDivision,
  CodeType,
  CodeTypeMap,
  ListDownloadSelectPdf,
  ReportAnalysisTypeMap,
} from 'constants/AnalysisConstant'
import ReportItem, {
  RenderingItem,
} from 'pages/Analysis/EEGIndividual/Report/ReportItem'
import {
  ReportWrap,
  ReportTranslationSelectWrap,
  ReportFetcherWrap,
  ReportCustomPadding,
} from 'pages/Analysis/EEGIndividual/Report'
import {getEyeStatus, getGenderStatus} from 'helpers/analysisHelper'
import getContentEEGIndividualPDF, {
  listCommonDownloadPdf,
  listAdditionalDownloadPdf,
  listFooterDownloadIndividualPdf,
} from 'components/molcules/GenerateReportPDF/EEGReport/helper'
import {
  isoStringToDateAndTimeString,
  parseAgeFromStrings,
} from 'helpers/dateHelper'

import {
  SelectContainer,
  ListContainer,
  Header,
} from 'components/molcules/GenerateReportPDF/styles'

export const SUB_TYPE_ALL_ABS = 7
export const SUB_TYPE_ALL_REL = 10
export const SUB_TYPE_DMN_ABS = 8
export const SUB_TYPE_DMN_REL = 11
export const LABEL_LIST = [
  'Delta',
  'Theta',
  'Alpha1',
  'Alpha2',
  'Beta1',
  'Beta2',
  'Beta3',
  'Gamma',
]

function ReportPage() {
  const {t, i18n} = useTranslation()

  // ID: path_parameter. ex) /report/:id -> :id 가 id에 매칭
  const {
    params: {id},
  } = useRouteMatch<{id: string}>()

  // version: query_parameter. ex) /report/292?version=3.0.1
  function decodeHtmlEntity(str: string) {
    const textarea = document.createElement('textarea')
    textarea.innerHTML = str
    const result = textarea.value
    textarea.remove()
    return result
  }

  const location = useLocation()
  const param = decodeHtmlEntity(`${location.search}${location.hash}`)
  const clientNameIndex = param.indexOf('clientName=') + 'clientName='.length
  const endIndex = param.indexOf('&version', clientNameIndex)
  const clientNameEncode =
    endIndex === -1
      ? param.substring(clientNameIndex)
      : param.substring(clientNameIndex, endIndex)

  const userNameFromURL = JSON.parse(decodeURIComponent(clientNameEncode))

  const clientName = t('IGetFullName', {
    firstName: userNameFromURL.firstName ?? '',
    lastName: userNameFromURL.lastName ?? '',
    interpolation: {escapeValue: false},
  })

  const version = useQuery().get('version') ?? '3.0'
  const codeTypeDivision: AnalysisDivision = 'EEG'
  const codeType: CodeType = CodeTypeMap.Normative
  const locale = i18n.language
  const params: FetchNormativeEEGResultRequest = {
    normRequestIds: [Number.parseInt(id, 10)],
    codeTypeDivision,
    codeType,
    codeVersion: version ?? '3.0',
    locale,
  }
  const asyncResult = useAsync(fetchNormativeEEGResult, [params], {
    executeOnMount: true,
    executeOnUpdate: false,
  })

  const [renderingItems, setRenderingItems] = useState<RenderingItem[]>()
  const [resultInfo, setResultInfo] = useState<AnalysisResultInfo>()
  const [componentList, setComponentList] = useState<number[]>([])
  const [listChecked, setListChecked] = useState<string[]>([])
  const [contentPage, setContentPage] = useState<any>()
  const [userInfo, setUserInfo] = useState<AnalysisUserInfo>({
    name: '',
    chartNo: '',
    birth: '',
    measureDate: '',
    gender: '',
    ecEo: '',
    id: '',
  })
  const [listSelectSpectrum, setListSelectSpectrum] = useState<
    AnalysisResultItem[]
  >([])
  const [checkListDownloadIndividualPdf, setCheckListDownloadIndividualPdf] =
    useState<ListDownloadSelectPdf[]>([])

  const handleCheckAll = () => {
    const listId = checkListDownloadIndividualPdf
      .filter((item) => !item.disable)
      .map((item) => {
        return item.id
      })
    if (listChecked.length === listId.length) {
      setListChecked([])
    } else {
      setListChecked([...listId])
    }
  }
  const handleCheckItem = (id: string) => {
    let listId: string[] = []
    if (listChecked.includes(id)) {
      listId = listChecked.filter((item) => item !== id)
    } else {
      listId = [...listChecked, id]
    }
    setListChecked([...listId])
  }

  useEffect(() => {
    if (asyncResult.result?.list) {
      const [result] = asyncResult.result.list
      const {codeList, resultDetailList} = result
      const sortedCodeList = codeList.sort((code: any) => code.codeSort)
      const renderingItems: RenderingItem[] = sortedCodeList.map(
        (code: any) => {
          const items = resultDetailList.filter(
            (item: any) => item.analysisType === code.codeId,
          )
          return [code, items]
        },
      )

      const dataUser: ReportInfoProps | undefined = resultInfo
      if (dataUser) dataUser.clientName = clientName

      const contentPDF: ElementPDF[][] = getContentEEGIndividualPDF(
        listChecked,
        renderingItems,
        dataUser,
        componentList,
        'NORM',
        listSelectSpectrum,
        t,
      )

      setContentPage([
        generateTableOfContents(listChecked, 'NORM'),
        ...contentPDF,
      ])
    }
  }, [listChecked, listSelectSpectrum, resultInfo])

  useEffect(() => {
    if (asyncResult.result?.list) {
      const [result] = asyncResult.result.list

      const {codeList, resultDetailList, resultInfo, componentList} = result
      const sortedCodeList = codeList.sort((code: any) => code.codeSort)
      const renderingItems: RenderingItem[] = sortedCodeList.map(
        (code: any) => {
          const items = resultDetailList.filter(
            (item: any) => item.analysisType === code.codeId,
          )
          return [code, items]
        },
      )
      const sourceRoi = renderingItems.find(
        (item: any) => item[0]?.codeId === ReportAnalysisTypeMap.SourceROIPower,
      )

      if (sourceRoi?.[1]?.length === 0) {
        setCheckListDownloadIndividualPdf([
          ...listCommonDownloadPdf,
          ...listFooterDownloadIndividualPdf,
        ])
      } else {
        setCheckListDownloadIndividualPdf([
          ...listCommonDownloadPdf,
          ...listAdditionalDownloadPdf,
          ...listFooterDownloadIndividualPdf,
        ])
      }
      // get data for backdrop of pdf
      const age = parseAgeFromStrings(resultInfo.birth, resultInfo.measureDate)
      const userInfo: AnalysisUserInfo = {
        name: clientName ?? '',
        chartNo: resultInfo.chartNo ?? '',
        birth: `${resultInfo.birth}(${age === -1 ? '-' : age})`,
        measureDate: isoStringToDateAndTimeString(
          resultInfo.measureDate ?? '',
        ).split(' ')[0],
        gender: resultInfo.gender ? getGenderStatus(resultInfo.gender) : '',
        ecEo: resultInfo.ecEo ? getEyeStatus(resultInfo.ecEo || '-') : '',
        id: resultInfo.id,
      }

      setUserInfo(userInfo)
      setRenderingItems(renderingItems)
      setResultInfo(resultInfo)
      setComponentList(componentList)
    }
  }, [asyncResult.result])
  useEffect(() => {
    asyncResult.execute(params)
  }, [locale])

  const getListSelectSpectrum = (item: AnalysisResultItem[]) => {
    setListSelectSpectrum([...item])
  }

  useLanguageReport()

  return (
    <ReportWrap style={{width: '100%'}}>
      <ReportTranslationSelectWrap>
        <TranslationSelect />
      </ReportTranslationSelectWrap>
      <ReportFetcherWrap style={{width: '100%'}}>
        <ReportCustomPadding />
        <CardWithTitle title={t('IEEGNormReportVersion', {version})}>
          {/* height - header - footer - CardHeader & border - margin */}
          <div
            style={{
              minHeight: 'calc(100vh - 91px - 77px - 77px - 15px)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {asyncResult.loading && (
              <CircularProgress size='3rem' color='secondary' />
            )}
            {asyncResult.error && (
              <div>
                {asyncResult.error.message}
                <IconButton
                  onClick={() => asyncResult.execute(params)}
                  size='small'
                >
                  <ReplayIcon />
                </IconButton>
              </div>
            )}
            <div style={{width: '100%'}}>
              <div id='download-pdf'>
                <PdfDownload
                  screen='NORM'
                  userInfo={userInfo}
                  contentPage={contentPage}
                  listChecked={listChecked}
                >
                  <Header>
                    <img src={barChart} alt='' />
                    <span>Norm DB</span>
                  </Header>
                  <SelectContainer>
                    <CheckBox
                      onChange={handleCheckAll}
                      checked={
                        checkListDownloadIndividualPdf.filter(
                          (item) => !item.disable,
                        ).length === listChecked.length
                      }
                    >
                      {t('ISelectAll')}
                    </CheckBox>

                    <ListContainer>
                      {checkListDownloadIndividualPdf.map((item) => (
                        <CheckBox
                          checked={listChecked.includes(item.id)}
                          onChange={() => handleCheckItem(item.id)}
                          disabled={item.disable}
                        >
                          {item.label}
                        </CheckBox>
                      ))}
                    </ListContainer>
                  </SelectContainer>
                </PdfDownload>
              </div>
              {resultInfo !== undefined && (
                <ReportInfo
                  resultType='NORM'
                  {...resultInfo}
                  clientName={clientName}
                  eeg
                />
              )}
              {renderingItems !== undefined &&
                renderingItems.map((item, index) => (
                  <ReportItem
                    key={index}
                    renderingItem={item}
                    requestId={Number.parseInt(id, 10)}
                    codeType={codeType}
                    codeTypeDivision={codeTypeDivision}
                    componentList={componentList}
                    screen='norm'
                    selectedBand={getListSelectSpectrum}
                  />
                ))}
            </div>
          </div>
        </CardWithTitle>
        <ReportCustomPadding />
      </ReportFetcherWrap>
      <ReportCustomPadding />
    </ReportWrap>
  )
}

export default ReportPage
