import React, {useEffect} from 'react'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import {SmallButtonStyles} from 'components/V2Buttons/NormalButton/Styles'
import {
  StyledBrainContainer,
  StyledBrainImgContainer,
  StyledBrainImgInwrap,
  StyledTextImgWrapLeft,
  StyledTextImgWrapRight,
  StyledTherapyPointWrap,
  StyledTextReadInfoContainer,
  StyledTextReadInfoName,
  StyledTextReadInfoValue,
  StyledTextSpan,
  StyledTherapyPointCheckbox,
  MainContainer,
  GroupNameContainer,
  GroupName,
  ProtocolSliderContainer,
  ProtocolSlider,
  TitleProtocol,
} from 'components/V2Dialog/TherapyDialog/Style'
import CommonDialog from 'components/V2Dialog/Templates/CommonDialog/CommonDialog'
import {VerySmallWidthModalStyles} from 'components/V2Modal/Modal/Styles'
import {isNotDefined} from 'helpers/commonHelper'
import {useTranslation} from 'react-i18next'
import therapyBrain from 'assets/img/therapy_brain.png'
import textLeft from 'assets/img/text_left.png'
import textRight from 'assets/img/text_right.png'
import arrowLeft from 'assets/img/arrow_button_left_gray.png'
import arrowRight from 'assets/img/arrow_button_right_gray.png'
import {useRecentReadDialog} from 'features/therapy/useTherapyDialog'
import {SvgIcon} from 'components/common/useSvgIcons'
import {StyledButtonGroup} from 'components/V2Modal/Modal/Modal'
import NormalButton from 'components/V2Buttons/NormalButton/NormalButton'
import useTherapyDialogViewModels from '../TherapyDialogViewModels'

const TherapyRecentReadDialog = () => {
  const {t} = useTranslation()

  const {
    recentList,
    open,
    onClose: handleClose,
    selectedIdx = 0,
    onNext,
    onPrev,
    onIndex,
  } = useRecentReadDialog()

  // ViewModels
  const {
    therapyPoint,
    therapyPointLoading,
    setTherapyPointLoading,
    makeTimeText,
    setInitialStates,
    getBeforeTherapyData,
  } = useTherapyDialogViewModels()

  const handleCloseButtonClick = () => {
    onIndex(0)
    setInitialStates()
    handleClose()
  }

  const handleHistoryMoveClick = (direction: string) => {
    if (direction === 'LEFT' && selectedIdx - 1 >= 0) {
      if (selectedIdx - 1 >= 0) onPrev()
    } else if (
      direction === 'RIGHT' &&
      recentList.dataList.length > selectedIdx + 1
    ) {
      onNext()
    }
  }

  useEffect(() => {
    if (open) {
      setTherapyPointLoading(true)
    }
  }, [open])

  useEffect(() => {
    if (isNotDefined(recentList)) {
      setTherapyPointLoading(false)
      // return
    }

    if (typeof recentList.dataList !== 'undefined') {
      getBeforeTherapyData(recentList.dataList[selectedIdx])
    }

    setTherapyPointLoading(false)
  }, [recentList, selectedIdx])

  const sectionGroupName = recentList?.groupName ? (
    <GroupNameContainer>
      <GroupName>
        <PeopleAltIcon fontSize='small' />
        <span>{recentList?.groupName}</span>
      </GroupName>
    </GroupNameContainer>
  ) : (
    <></>
  )

  const sectionSlider =
    recentList?.dataList && recentList?.dataList.length > 1 ? (
      <ProtocolSliderContainer>
        {recentList.dataList.map((data, index) => (
          <ProtocolSlider
            style={{
              backgroundColor: index === selectedIdx ? '#3EBCBD' : '#F7F7F7',
            }}
          />
        ))}
      </ProtocolSliderContainer>
    ) : (
      <></>
    )

  const sectionFooter = (
    <StyledButtonGroup>
      <NormalButton
        theme={{
          ...SmallButtonStyles,
          buttonWidth: 202,
          buttonHeight: 35,
          buttonBackgroundColor: '#3EBCBD',
          buttonFontWeight: 400,
          buttonFontSize: 12,
        }}
        title={t('IOk')}
        event={handleCloseButtonClick}
        disabled={false}
      />
    </StyledButtonGroup>
  )

  const sectionMain = (
    <StyledBrainContainer>
      <TitleProtocol>{`${t('IProtocol')} ${selectedIdx + 1}`}</TitleProtocol>
      <StyledBrainImgContainer>
        <StyledTextImgWrapLeft
          style={{
            top: '43%',
            left: '-25px',
            cursor:
              typeof recentList.dataList !== 'undefined' && selectedIdx > 0
                ? 'pointer'
                : 'default',
            position: 'absolute',
          }}
          onClick={() => {
            handleHistoryMoveClick('LEFT')
          }}
        >
          <img
            style={{
              width: 'auto',
              height: '21px',
              transform: 'rotate(180deg)',
              opacity:
                typeof recentList.dataList !== 'undefined' && selectedIdx > 0
                  ? 1
                  : 0,
            }}
            src={arrowRight}
            alt='text_left'
          />
        </StyledTextImgWrapLeft>
        <StyledBrainImgInwrap>
          <img src={therapyBrain} alt='therapy_brain' />
          <StyledTherapyPointWrap>
            {therapyPoint.map((item, idx) => {
              return (
                <StyledTherapyPointCheckbox
                  key={idx}
                  type='checkbox'
                  name={item.name}
                  // value={item.value === 'Y'}
                  data-idx={idx}
                  className={`${item.name} therapyPointRecent`}
                  checked={item.value === 'Y'}
                  disabled
                />
              )
            })}
          </StyledTherapyPointWrap>
          <StyledTextImgWrapLeft>
            <img src={textLeft} alt='text_left' />
          </StyledTextImgWrapLeft>
          <StyledTextImgWrapRight>
            <img src={textRight} alt='text_right' />
          </StyledTextImgWrapRight>
        </StyledBrainImgInwrap>

        <StyledTextImgWrapRight
          style={{
            top: '43%',
            right: '-25px',
            width: 'auto',
            position: 'absolute',
            cursor:
              typeof recentList.dataList !== 'undefined' &&
              selectedIdx < recentList.dataList.length - 1
                ? 'pointer'
                : 'default',
            opacity:
              typeof recentList.dataList !== 'undefined' &&
              selectedIdx < recentList.dataList.length - 1
                ? 1
                : 0,
          }}
          onClick={() => {
            handleHistoryMoveClick('RIGHT')
          }}
        >
          <img
            style={{width: 'auto', height: '21px'}}
            src={arrowRight}
            alt='text_right'
          />
        </StyledTextImgWrapRight>
      </StyledBrainImgContainer>
      <StyledTextReadInfoContainer>
        <div style={{display: 'flex'}}>
          <StyledTextReadInfoName>{t('IFrequency')}</StyledTextReadInfoName>
          <StyledTextReadInfoValue>
            {typeof recentList.dataList !== 'undefined'
              ? recentList.dataList[selectedIdx].frequency
              : '-'}
            Hz
          </StyledTextReadInfoValue>
        </div>
        <div style={{display: 'flex'}}>
          <StyledTextReadInfoName>{t('IRunningTime')}</StyledTextReadInfoName>
          <StyledTextReadInfoValue>
            {makeTimeText(
              typeof recentList.dataList !== 'undefined'
                ? recentList.dataList[selectedIdx].therapyTime
                : 0,
              t,
            )}
          </StyledTextReadInfoValue>
        </div>
      </StyledTextReadInfoContainer>
      {sectionSlider}
    </StyledBrainContainer>
  )

  const body = (
    <>
      {sectionGroupName}
      <MainContainer>{sectionMain}</MainContainer>
    </>
  )

  return (
    <CommonDialog
      open={!therapyPointLoading && open}
      modalTitle={t('ICareDetail')}
      handleClose={handleCloseButtonClick}
      theme={VerySmallWidthModalStyles}
      headerType='ONE_ROW'
      body={body}
      footer={sectionFooter}
      style={{
        opacity: therapyPointLoading ? 0 : 1,
        userSelect: therapyPointLoading ? 'none' : 'auto',
      }}
    />
  )
}

export default TherapyRecentReadDialog
