import React, {useEffect, useMemo, useState} from 'react'
import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {SchemaOf} from 'yup'
import {
  Checkbox,
  DialogContent,
  IconButton,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {createStyles, Theme} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import {isOrganizationHealth} from 'Authority'
import * as storage from 'api/storageApi'
import {editPatientApi, PatientEditRequest} from 'api/patientApi'
import {HdAnalysisTypes} from 'api/healthCareApi'
import {Colors} from 'components/common/useBaseStyle'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'
import useStyles from 'components/Dialog/User/Style'
import {Tab, TabPanel, Tabs} from 'components/Tab'
import {
  Gender,
  GenderSelectItems,
  Hand,
  HandSelectItems,
} from 'constants/CommonConstant'
import useAuth from 'features/auth/useAuth'
import useFailureModal from 'features/modal/useFailureModal'
import useSuccessModal from 'features/modal/useSuccessModal'
import {useUpdateDialog} from 'features/patient/usePatientDialog'
import usePatient from 'features/patient/usePatient'
import usePatientSearch from 'features/patient/usePatientSearch'
import useProductList from 'features/patient/useProductList'
import {isDefined, isNotDefined} from 'helpers/commonHelper'
import {formatDate} from 'helpers/dateHelper'
import SelectBoldCountryNumber from '../BoldSelect/SelectBoldCountryNumber'
import SelectBoldDoctor from '../BoldSelect/SelectBoldDoctor'
import SelectBoldYear from '../BoldSelect/SelectBoldYear'
import CheckUpItems from '../PatientAddDialog/fields/checkupItems'
import {calculateAge} from '../PatientAddDialog'

export const editStyles = (theme: Theme) =>
  createStyles({
    container: {
      '& > .MuiDialog-container > .MuiPaper-root': {
        [theme.breakpoints.up('xs')]: {
          minWidth: 354,
        },
        [theme.breakpoints.up('sm')]: {
          minWidth: 589,
        },
        [theme.breakpoints.up('md')]: {
          minWidth: 800,
        },
      },
    },
    inputContainer: {
      padding: '20px 80px',
    },
    containerTitle: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      flex: 1,
    },
    closeButtonWrap: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 10,
      marginRight: 10,
    },
    closeButton: {
      backgroundColor: '#f4f4f4',
      width: 20,
      height: 20,
    },
    closeIcon: {
      width: 20,
      height: 20,
      color: '#c5c5c5',
    },
    title: {
      padding: 0,
      color: '#4b4b4b',
      margin: 0,
      marginLeft: 30,
      paddingBottom: 15,
      fontSize: 24,
    },
    titleWrap: {
      display: 'flex',
      flexDirection: 'row',
    },
    cardContent: {
      margin: '0 20px',
    },
    smallPadding: {
      padding: theme.spacing(0.5),
    },
    padding: {
      padding: theme.spacing(1),
    },
    infoContainer: {
      display: 'flex',
      flexDirection: 'column',
      margin: '18px 0',
      flexWrap: 'wrap',
      // alignItems: 'center',
      '& input:focus': {outline: `none`, borderColor: `${Colors.primary}`},

      '& .subTitle': {
        fontSize: '1.0rem',
        fontWeight: 'normal',
        textAlign: 'left',
        margin: '5px 0',
        minWidth: 50,
        maxWidth: 200,
        flexWrap: 'wrap',
        marginTop: 5,
        color: '#757575',
      },

      '& .subContent': {
        fontSize: '1.0rem',
        fontWeight: 'normal',
        textAlign: 'left',
        margin: '5px 0',
        flexWrap: 'wrap',
        marginTop: 5,
        flexGrow: 1,
        // marginLeft: 10,
      },
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'row',
      margin: '18px 0',
      flexWrap: 'wrap',
    },
    subTitle: {
      fontSize: '1.0rem',
      fontWeight: 'normal',
      textAlign: 'left',
      margin: '5px 0',
      minWidth: 50,
      maxWidth: 200,
      flexBasis: 200,
      flexWrap: 'wrap',
      marginTop: 5,
      color: '#757575',
    },
    subContent: {
      fontSize: '1.0rem',
      fontWeight: 'normal',
      textAlign: 'left',
      margin: '5px 0',
      flexWrap: 'wrap',
      marginTop: 5,
      flexGrow: 1,
      marginLeft: 10,
    },

    buttonContainerBgColor: {
      backgroundColor: '#f4f4f4',
      display: 'flex',
      justifyItems: 'center',
      justifyContent: 'center',
    },
    buttonContainer: {
      display: 'flex',
      padding: 20,
      width: 400,

      '& button': {
        minWidth: 100,
        padding: '8px 0',
        flex: 1,
        margin: '0 10px',
      },
      '& button:nth-child(1)': {
        marginLeft: 20,
      },
      '& button:nth-child(3)': {
        marginRight: 20,
      },
      '& .MuiButton-containedSecondary': {
        color: 'white',
      },
    },
    textField: {flex: 1},
    selectContainer: {
      width: '100%',
      height: 45,
    },
    radioContainer: {
      flex: 1,
    },
    radioWrap: {
      '& span': {
        fontSize: 11,
      },
    },
    okButton: {
      backgroundColor: 'white',
      border: 0,
      color: '#4b4b4b',
    },
    line: {
      background: '#eeeeee',
      marginLeft: 30,
      marginRight: 30,
    },
    labelText: {
      color: '#868e96',
      fontSize: 16,
    },
  })

const PatientEditDialog = () => {
  const organizationType = storage.getOrganizationType()
  const isHealthOrg = isOrganizationHealth(organizationType)
  const {t, i18n} = useTranslation()
  const classes = useStyles()
  const inputClasses = useBoldInputStyle()
  const chartNoDisabled = true
  const [key, setKey] = useState(0)

  // redux
  const {user: currentUser} = useAuth()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onSuccessModalOpen} = useSuccessModal()

  const {
    open,
    onClose: handleClose,
    entity: patient,
    uuid: patientId,
    listProducts,
    onFetch,
    onGetProducts,
  } = useUpdateDialog()
  const {idOver20Product} = useProductList()
  const {onRefresh: onPatientListFetch} = usePatientSearch()
  const {onFetch: onFetchPatient} = usePatient()
  const auth = useAuth()

  // const open = useAppSelector(selectPatientEditDialogOpen)
  // const patient = useAppSelector(selectPatientEntity)
  // const patientId = useAppSelector(selectPatientId)

  // state
  const [tabIndex, setTabIndex] = useState<number>(0)
  const [changeBirth, setBirth] = useState<Date>()
  const [usedEmail, setUsedEmail] = React.useState(false)

  const docNo = React.useState('')

  const UpdatePatientSchema: SchemaOf<{healthProductId: number}> = yup
    .object()
    .shape({
      healthProductId: isHealthOrg
        ? yup.number().nullable().required(t('IMessageRequiredProduct'))
        : yup.number().nullable().notRequired(),
    })
    .defined()

  // form
  const {
    control,
    handleSubmit,
    formState: {errors},
    setValue,
    setError,
    reset,
  } = useForm({resolver: yupResolver(UpdatePatientSchema)})

  // callback
  const handleBirthData = (data: Date) => {
    if (data) {
      setBirth(data)
    }
  }
  const handleTabChanged = (
    event: React.ChangeEvent<Record<string, unknown>>,
    newValue: number,
  ) => {
    setTabIndex(newValue)
  }

  const handleCleanClose = () => {
    reset()
    handleClose()
  }

  const isAdult = useMemo(() => {
    if (!changeBirth || !changeBirth.getMonth) {
      return false
    }
    const Age = calculateAge(changeBirth)
    return Number.isNaN(Age) ? false : Age >= 20
  }, [changeBirth])

  const onSubmit = async (data: PatientEditRequest) => {
    const productId =
      listProducts.find(
        (item: HdAnalysisTypes) => item.productId === data.healthProductId,
      )?.id || null

    if (!isAdult && idOver20Product.includes(data.healthProductId || 0)) {
      setError('healthProductId', {
        type: 'manual',
        message: t('IErrorMessageAIDementiaPrediction'),
      })
      setValue('healthProductId', null)
      return
    }

    try {
      const result = await editPatientApi({
        ...data,
        email: usedEmail ? '' : data.email,
        birth: formatDate(new Date(changeBirth ?? data.birth)),
        healthProductId: productId,
      })
      if (result.success) {
        onSuccessModalOpen(t('ISuccess'))
        onPatientListFetch()
        onFetchPatient(data.uuid)
        handleCleanClose()
      }
    } catch (e) {
      onFailureModalOpen(e.message)
    }
  }

  const onSubmitHandler: SubmitHandler<any> = (data) =>
    onSubmit(data as PatientEditRequest)

  useEffect(() => {
    if (open && isDefined(patientId)) {
      onFetch(patientId)
    }
    if (open && auth.user?.orgId) {
      onGetProducts(auth.user?.orgId?.toString())
    }
  }, [open, patientId, auth.user?.orgId?.toString()])

  useEffect(() => {
    if (isNotDefined(patient)) {
      return
    }
    setValue('uuid', patientId)
    setValue('chartNo', patient.chartNo)
    setValue('firstName', patient.firstName)
    setValue('lastName', patient.lastName)
    setValue('phone', patient.phone)
    setValue('birth', patient.birth)
    setValue('gender', patient.gender)
    setValue('hand', patient.hand)
    setValue('docNo', patient.primaryId)
    setValue('uid', currentUser?.uid)
    setValue('countryCode', patient?.countryCode?.code ?? 'KR')
    setValue('email', patient.email)
    setBirth(new Date(patient.birth))
    const productId =
      listProducts.find((item) => item.id === patient.healthProductId)
        ?.productId || null
    setValue('healthProductId', productId)
    setKey(key + 1)
  }, [patient])

  return (
    <Dialog
      open={open}
      onClose={handleCleanClose}
      className={classes.container}
    >
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className={classes.longTitleContainer}>
          <div className={classes.containerTitle}>
            <div className={classes.closeButtonWrap}>
              <IconButton
                color='secondary'
                aria-label='favorite'
                className={classes.closeButton}
                onClick={handleCleanClose}
              >
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </div>
            <Typography className={classes.title}>
              {t('ICustomerUpdate')}
            </Typography>
          </div>
        </div>

        <Divider className={classes.titleDivider} />
        <DialogContent className={classes.contentWrap}>
          <Tabs
            value={tabIndex}
            indicatorColor='primary'
            textColor='primary'
            onChange={handleTabChanged}
            aria-label='disabled tabs example'
          >
            <Tab label={t('IBaseInfo')} />
            <Tab label={t('IDiseaseInfo')} />
          </Tabs>
          <TabPanel value={tabIndex} index={0}>
            <div className={classes.infoContainer}>
              <Typography className={inputClasses.inputLabel}>
                {t('IChartNo')}
              </Typography>
              <Typography className={classes.smallPadding} />
              <Controller
                name='chartNo'
                defaultValue={patient?.chartNo}
                control={control}
                render={({field}) => (
                  <input
                    className={inputClasses.input}
                    disabled={chartNoDisabled}
                    // error={!!errors.chartNo}
                    // helperText={errors.chartNo?.message}
                    {...field}
                  />
                )}
              />
              <span>{errors.chartNo && errors.chartNo?.message}</span>
            </div>
            <div className={classes.infoContainer}>
              <Typography className={inputClasses.inputLabel}>
                {t('IName')}
              </Typography>
              <Typography className={classes.smallPadding} />
              {(i18n.language === 'ko' || i18n.language === 'ja') && (
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <Controller
                    name='lastName'
                    control={control}
                    defaultValue={patient?.lastName}
                    render={({field}) => (
                      <input
                        className={inputClasses.input}
                        placeholder={t('ILastName')}
                        {...field}
                      />
                    )}
                  />
                  <Typography className={classes.smallPadding} />
                  <Controller
                    name='firstName'
                    control={control}
                    defaultValue={patient?.firstName}
                    render={({field}) => (
                      <input
                        className={inputClasses.input}
                        placeholder={t('IFirstName')}
                        {...field}
                      />
                    )}
                  />
                </div>
              )}
              {i18n.language !== 'ko' && i18n.language !== 'ja' && (
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <Controller
                    name='firstName'
                    control={control}
                    defaultValue={patient?.firstName}
                    render={({field}) => (
                      <input
                        className={inputClasses.input}
                        placeholder={t('IFirstName')}
                        {...field}
                      />
                    )}
                  />
                  <Typography className={classes.smallPadding} />
                  <Controller
                    name='lastName'
                    control={control}
                    defaultValue={patient?.lastName}
                    render={({field}) => (
                      <input
                        className={inputClasses.input}
                        placeholder={t('ILastName')}
                        {...field}
                      />
                    )}
                  />
                </div>
              )}
              <span>{errors.lastName && errors.lastName?.message}</span>
              <span>{errors.firstName && errors.firstName?.message}</span>
            </div>
            <div className={classes.infoContainer}>
              <Typography className={inputClasses.inputLabel}>
                {t('ITel')}
              </Typography>
              <Typography className={classes.smallPadding} />
              <div style={{display: 'flex', flexDirection: 'row', gap: 10}}>
                <Controller
                  name='countryCode'
                  control={control}
                  defaultValue='KR'
                  render={({field}) => <SelectBoldCountryNumber {...field} />}
                />
                <Controller
                  name='phone'
                  control={control}
                  rules={{
                    required: 'Email required',
                    pattern: {
                      value:
                        /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
                      message: t('ITelInvalid'),
                    },
                  }}
                  defaultValue={patient?.phone}
                  render={({field}) => (
                    <input className={inputClasses.input} {...field} />
                  )}
                />
              </div>
            </div>
            <div className={classes.infoContainer}>
              <Typography className={inputClasses.inputLabel}>
                {t('IBirthday')}
              </Typography>
              <Typography className={classes.smallPadding} />
              <SelectBoldYear
                key={key}
                onDateChange={(e) => handleBirthData(e)}
                oldDate={new Date(patient?.birth ?? new Date())}
              />
            </div>
            <div className={classes.infoContainer}>
              <Typography className={inputClasses.inputLabel}>
                {t('IGender')}
              </Typography>

              <Controller
                name='gender'
                control={control}
                defaultValue={patient?.gender ?? Gender.MALE}
                render={({field}) => (
                  <RadioGroup
                    row
                    className={`${classes.radioContainer} subContent`}
                    {...field}
                  >
                    {GenderSelectItems.map((gender, index) => (
                      <div key={index}>
                        <Typography className={classes.smallPadding} />
                        <FormControlLabel
                          control={<Radio color='primary' />}
                          label={
                            <p style={{marginRight: '30px'}}>
                              {t(gender.label)}
                            </p>
                          }
                          value={gender.value}
                        />
                      </div>
                    ))}
                  </RadioGroup>
                )}
              />
            </div>
            <div className={classes.infoContainer}>
              <Typography className={inputClasses.inputLabel}>
                {t('IFrequentlyUsedHands')}
              </Typography>

              <Controller
                name='hand'
                control={control}
                defaultValue={patient?.hand ?? Hand.UNKNOWN}
                render={({field}) => (
                  <RadioGroup
                    row
                    className={`${classes.radioContainer} subContent`}
                    {...field}
                  >
                    {HandSelectItems.map((hand) => (
                      <FormControlLabel
                        control={<Radio color='primary' />}
                        label={
                          <p style={{marginRight: '30px'}}>{t(hand.label)}</p>
                        }
                        value={hand.value}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            </div>
            <div className={classes.infoContainer}>
              <Typography className={inputClasses.inputLabel}>
                {t('IEmail')}
              </Typography>
              <Typography className={classes.smallPadding} />
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <Controller
                  name='email'
                  control={control}
                  rules={{
                    pattern: {
                      value:
                        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
                      message: t('IEmailInvalid'),
                    },
                  }}
                  render={({field}) => (
                    <input
                      className={inputClasses.input}
                      placeholder={t('IEmailRequired')}
                      disabled={usedEmail}
                      defaultValue={patient?.email ?? ''}
                      autoComplete='off'
                      // error={!!errors.chartNo}
                      // helperText={errors.chartNo?.message}
                      {...field}
                    />
                  )}
                />
                <Typography className={classes.padding} />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={usedEmail}
                      onChange={() => {
                        setUsedEmail(!usedEmail)
                      }}
                      name={t('IEmail')}
                      color='primary'
                    />
                  }
                  label={
                    <Typography
                      style={{
                        color: '#5a6268',
                        fontSize: '17px',
                        paddingBottom: 3,
                      }}
                    >
                      {t('INoEmail')}
                    </Typography>
                  }
                />
              </div>
            </div>
            <div className={classes.infoContainer}>
              <Typography className={inputClasses.inputLabel}>
                {t('ICareDoctor')}
              </Typography>
              <Typography className={classes.smallPadding} />
              <Controller
                name='docNo'
                control={control}
                defaultValue={docNo}
                render={({field}) => (
                  <SelectBoldDoctor error={!!errors.docNo} {...field} />
                )}
              />
            </div>
            {isHealthOrg && (
              <CheckUpItems
                control={control}
                products={listProducts}
                errors={errors}
              />
            )}
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            {t('INotYetImplemented')}
          </TabPanel>
        </DialogContent>
        <div className={classes.buttonContainerBgColor}>
          <div className={classes.buttonContainer}>
            <Button
              variant='contained'
              color='default'
              disableElevation
              onClick={handleCleanClose}
              className={classes.okButton}
            >
              {t('IDiscardEdit')}
            </Button>
            <Button
              variant='contained'
              disableElevation
              color='primary'
              type='submit'
            >
              {t('IOk')}
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  )
}

export default PatientEditDialog
