import Button from '@material-ui/core/Button'
import {AnalysisResultIndicator, AnalysisResultItem} from 'api/analysisApi'

import NeuralIndex from 'assets/img/neural_index.png'
import classNames from 'classnames'
import ReportDescription from 'components/Report/ReportDescription'
import useStyles from 'components/molcules/Report/SourceROIPower.style'
import ReportHeader from 'components/Report/ReportHeader'
import {Tab, TabPanel, Tabs} from 'components/Tab'
import React, {ReactNode, useMemo, useState} from 'react'
import MUICarousel from 'react-material-ui-carousel'
import {
  SUB_TYPE_ALL_ABS,
  SUB_TYPE_ALL_REL,
  SUB_TYPE_DMN_ABS,
  SUB_TYPE_DMN_REL,
} from 'pages/Analysis/EEGNorm/Report'

interface CarouselButtonProps {
  index: number
  currentIndex: number
  classes: ReturnType<typeof useStyles>
  onClick: () => void
  children: ReactNode
}

function CarouselButton({
  index,
  currentIndex,
  classes,
  onClick,
  children,
}: CarouselButtonProps) {
  const isActive = useMemo(() => index === currentIndex, [index, currentIndex])

  const buttonClasses = classNames({
    [classes.carouselButton]: !isActive,
    [classes.carouselButtonActive]: isActive,
  })

  return (
    <Button onClick={onClick} type='button' className={buttonClasses}>
      {children}
    </Button>
  )
}

interface CarouselProps {
  description: string
  indicator: SourceROIIndicator
  classes: ReturnType<typeof useStyles>
  items: AnalysisResultItem[]
}

function Carousel({description, indicator, items, classes}: CarouselProps) {
  const sortedItems = items.sort((item) => item.bandType)

  const [carouselIndex, setCarouselIndex] = useState(0)

  const buttonLabelList = [
    'Delta',
    'Theta',
    'Alpha1',
    'Alpha2',
    'Beta1',
    'Beta2',
    'Beta3',
    'Gamma',
  ]
  return (
    <div>
      <div className={classes.carouselButtonContainer}>
        {buttonLabelList.map((label, buttonIndex) => (
          <CarouselButton
            key={buttonIndex}
            index={buttonIndex}
            currentIndex={carouselIndex}
            onClick={() => setCarouselIndex(buttonIndex)}
            classes={classes}
          >
            {label}
          </CarouselButton>
        ))}
      </div>
      <ReportDescription htmlText={description} />
      <MUICarousel
        index={carouselIndex}
        autoPlay={false}
        animation='slide'
        indicators={false}
        timeout={200}
        cycleNavigation={false}
        navButtonsAlwaysVisible
        onChange={(value: number) => {
          if (carouselIndex === value) return
          setCarouselIndex(value)
        }}
      >
        {sortedItems.map((item) => (
          <div
            style={{display: 'flex', flexDirection: 'column'}}
            key={item.subType}
          >
            <div className={classes.carouselIndicator}>
              {indicator.toUpperCase()}
            </div>
            <img
              src={NeuralIndex}
              alt='neural description'
              className={classes.carouselNeuralIndex}
            />
            <div key={item.componentType} className={classes.carouselContainer}>
              <img src={item.imgPath} alt={item.psdScale} />
            </div>
          </div>
        ))}
      </MUICarousel>
    </div>
  )
}

type SourceROIIndicator = 'ALL' | 'DMN'

interface SourceROITabProps {
  absIndicator: number
  relIndicator: number
  indicator: SourceROIIndicator
  classes: ReturnType<typeof useStyles>
  description: string
  items: AnalysisResultItem[]
}

function SourceROITab({
  indicator,
  absIndicator,
  relIndicator,
  items,
  description,
  classes,
}: SourceROITabProps) {
  const [tabIndex, setTabIndex] = React.useState(0)
  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleTabChanged = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue)
  }
  const absItems = items.filter((item) => item.subType === absIndicator)
  const relItems = items.filter((item) => item.subType === relIndicator)

  return (
    <div>
      <Tabs
        value={tabIndex}
        onChange={handleTabChanged}
        indicatorColor='secondary'
        textColor='secondary'
      >
        <Tab label='Absolute' />
        <Tab label='Relative' />
      </Tabs>
      <TabPanel index={0} value={tabIndex}>
        <Carousel
          description={description}
          items={absItems}
          classes={classes}
          indicator={indicator}
        />
      </TabPanel>
      <TabPanel index={1} value={tabIndex}>
        <Carousel
          description={description}
          items={relItems}
          classes={classes}
          indicator={indicator}
        />
      </TabPanel>
    </div>
  )
}

interface SourceROIPowerProps {
  indicator: AnalysisResultIndicator
  items: AnalysisResultItem[]
}

function SourceROIPower({indicator, items}: SourceROIPowerProps) {
  const classes = useStyles()
  // source roi의 description은 ///를 구분자로 하여 두 가지 내용이 합쳐져 있다.
  const splitDescription = indicator.titleDescription.split('///')

  return (
    <div>
      <ReportHeader
        index={indicator.codeSort}
        title={indicator.codeName}
        description={indicator.titleDescription}
      />
      <SourceROITab
        indicator='ALL'
        absIndicator={SUB_TYPE_ALL_ABS}
        relIndicator={SUB_TYPE_ALL_REL}
        description={splitDescription[0]}
        items={items}
        classes={classes}
      />
      <SourceROITab
        indicator='DMN'
        absIndicator={SUB_TYPE_DMN_ABS}
        relIndicator={SUB_TYPE_DMN_REL}
        description={splitDescription[1]}
        items={items}
        classes={classes}
      />
    </div>
  )
}

export default SourceROIPower
