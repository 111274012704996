import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {SearchedHRVNorm} from 'api/analysisApi'
import TableButton from 'components/atoms/Buttons/TableButton'
import {ClientName} from 'components/atoms/ClientName'
import AnalysisSearch from 'components/molcules/AnalysisSearch'
import Pagination from 'components/molcules/Pagination'
import PeriodSearch from 'components/molcules/PeriodSearch'
import TableCell from 'components/Table/TableCell'
import useStyles from 'components/Table/useTableStyles'
import useToolbarStyles from 'components/Table/useTableToolbarStyles'
import useHrvNorm from 'features/analysis/useHrvNorm'
import useFailureModal from 'features/modal/useFailureModal'
import {statusToI18nString} from 'helpers/analysisHelper'
import {
  isoStringToDateAndTimeString,
  periodDateToString,
} from 'helpers/dateHelper'
import {isProd, isStage} from 'helpers/envHelper'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

function TableToolbar() {
  const classes = useToolbarStyles()

  const {query, search, period, onSearch} = useHrvNorm()

  const handleSearch = (search: AnalysisSearchKind) =>
    onSearch({
      ...query,
      paging: {page: 0, size: 10},
      search,
    })

  const handlePeriod = (period: PeriodDate) =>
    onSearch({
      ...query,
      paging: {page: 0, size: 10},
      period: periodDateToString(period),
    })

  return (
    <div className={classes.root}>
      <PeriodSearch period={period} onSearch={handlePeriod} />
      <AnalysisSearch search={search} onSearch={handleSearch} />
    </div>
  )
}

interface RenderTableRowProps {
  item: SearchedHRVNorm
}

function RenderTableRow({item}: RenderTableRowProps) {
  const {t} = useTranslation()
  const {onOpen} = useFailureModal()

  const handleResultClick = () => onOpen(t('INotYetImplemented'))

  return (
    <TableRow>
      <TableCell align='center'>{item.id}</TableCell>
      <TableCell align='center'>
        <ClientName
          firstName={item.firstName}
          lastName={item.lastName}
          birth={item.birth ?? ''}
          gender={item.gender ?? ''}
        />
        {isStage() && (
          <TableCell align='center'>
            {item.firstName}
            {item.lastName}
          </TableCell>
        )}
        {isProd() && (
          <TableCell align='center'>
            {item.firstName}
            {item.lastName}
          </TableCell>
        )}
        {!isProd() && !isStage() && (
          <ClientName
            firstName={item.firstName}
            lastName={item.lastName}
            birth={item.birth ?? ''}
            gender={item.gender ?? ''}
          />
        )}
      </TableCell>
      <TableCell align='center'>{item.eceo}</TableCell>
      <TableCell align='center'>{item.fileName}</TableCell>
      <TableCell align='center'>
        {isoStringToDateAndTimeString(String(item.createdAt))}
      </TableCell>
      <TableCell align='center'>{item.normdbVersion || '-'}</TableCell>
      <TableCell align='center'>
        {t(statusToI18nString(item.progress || '-'))}
      </TableCell>
      <TableCell align='center'>
        {item.progress === 'READY' && t('IStatus_processing')}
        {item.progress === 'ERROR' && t('IStatus_failure')}
        {item.progress === 'SUCCESS' && (
          <TableButton onClick={handleResultClick}>{t('IRead')}</TableButton>
        )}
      </TableCell>
    </TableRow>
  )
}

function PageTable() {
  const {t} = useTranslation()
  const classes = useStyles()

  const {loading, query, paging, pagingInfo, items, onSearch} = useHrvNorm()

  const emptyRows = items === null ? 5 : 5 - items.length

  const setPageIndex = (page: number) =>
    onSearch({
      ...query,
      paging: {
        page,
        size: paging.size,
      },
    })

  const onSizeChanged = (
    event: React.ChangeEvent<{name?: string; value: unknown}>,
  ) => {
    const size = parseInt(event.target.value as string, 10)
    onSearch({
      ...query,
      paging: {
        page: 0,
        size,
      },
    })
  }

  useEffect(() => {
    onSearch(query)
  }, [])

  return (
    <TableContainer className={classes.tableContainer}>
      <TableToolbar />
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell align='center' padding='none'>
              {t('IIndex')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IPatientName')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IECEO')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IFileName')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IMeasureDate')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IVersion')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IProgress')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IResult')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <RenderTableRow key={item.id} item={item} />
          ))}
          {emptyRows > 0 && (
            <TableRow
              style={{height: 43 * emptyRows, backgroundColor: '#F9F9FB'}}
            >
              <TableCell colSpan={12} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Pagination
        totalPageCount={pagingInfo.totalPages}
        currentPageIndex={query.paging.page ?? 0}
        itemCountPerPage={query.paging.size ?? 10}
        setCurrentPageIndex={setPageIndex}
        loading={loading}
        onItemCountPerPageChanged={onSizeChanged}
      />
    </TableContainer>
  )
}

export default PageTable
