/* eslint-disable */
export const pad = (num: number, width = 2, padString = '0'): string => {
  const defaultNum = num.toString(10)
  return defaultNum.length >= width
    ? defaultNum
    : new Array(width - defaultNum.length + 1).join(padString) + defaultNum
}

export const zeroPad = (num: number, width = 2) => pad(num, width)

export const isFailed = <S, E>(
  object: Failable<S, E>,
): object is Failure<E> => {
  return object.isError
}

export const isSuccess = <S, E>(
  object: Failable<S, E>,
): object is Success<S> => {
  return !object.isError
}

export const isObjectEmpty = (param: any) =>
  param === null || param === undefined || Object.keys(param).length === 0

export const isObjectNotEmpty = (param: any) =>
  param !== null && param !== undefined && Object.keys(param).length !== 0

export const isObject = (param: any): param is Record<string, any> =>
  param !== null && typeof param === 'object'

export const isString = (param: any): param is string =>
  param !== null && typeof param === 'string'

export const isDate = (param: any): param is Date => param instanceof Date

/**
 * start, stop, step에 따라 숫자 배열을 생성.
 * for 루프를 최대한 안쓰기 위해 사용.
 *
 * @param start 시작값.
 * @param stop 종료값. 포함.
 * @param step 증가값. 기본값 1.
 */
export const range = (start: number, stop: number, step = 1) =>
  Array.from({length: (stop - start) / step + 1}, (_, i) => start + i * step)

export const isBlank = (str: Nullable<string>): str is NullValue =>
  !str || /^\s*$/.test(str)

export const isNotBlank = (param: string | undefined | null) => !isBlank(param)

export const stringFormat = (format: string, ...args: any[]): string =>
  format
    .split('{}')
    .reduce((aggregate, chunk, i) => aggregate + chunk + (args[i] || ''), '')

export const isPositive = (data: number | undefined) => data && data > 0

export const isNegative = (data: number | undefined) => data && data < 0

export function groupBy<T extends Record<string, any>, K extends keyof T>(
  array: T[],
  key: K | {(obj: T): string},
): Record<string, T[]> {
  const keyFn = key instanceof Function ? key : (obj: T) => obj[key]
  return array.reduce((objectsByKeyValue, obj) => {
    const value = keyFn(obj)
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {} as Record<string, T[]>)
}

export function isDefined<T>(x: T | undefined | null): x is T {
  return x !== undefined && x !== null
}

export function isNotDefined<T>(
  x: T | undefined | null,
): x is undefined | null {
  return x === undefined || x === null
}

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms))

export function makeRandomString(length: number) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length

  const arr = range(0, length - 1)
  return arr
    .map((_) => characters.charAt(Math.floor(Math.random() * charactersLength)))
    .join('')
}

export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/**
 * Function get search params from URL
 * @param searchQuery location.search
 * @returns search params store in URL
 */
export const getQueryFromUrl = (searchQuery: string) => {
  try {
    let params = new URLSearchParams(searchQuery)
    let paramsObject = Object.fromEntries(params.entries())
    let result: any = {}
    for (const key in paramsObject) {
      try {
        result[key] = JSON.parse(paramsObject[key])
      } catch (error) {
        result[key] = paramsObject[key]
      }
    }
    return result || {}
  } catch (error) {
    return {}
  }
}

export const apiArrayParamIds = (ids: string[], key?: string) => {
  if (ids.length > 1) {
    const indexMax = ids.length - 1

    const idsArray = ids.map((item, index) => {
      if (index < indexMax) {
        return key ? `${key}=${item}&` : `id=${item}&`
      }
      return key ? `${key}=${item}` : `id=${item}`
    })

    const array = idsArray.reduce((name, current) => name + current)

    return array
  }
  return key ? `${key}=${ids}` : `id=${ids}`
}

export const compareDeep = (obj1: any, obj2: any): boolean => {
  if (
    obj1 === null ||
    obj2 === null ||
    typeof obj1 !== 'object' ||
    typeof obj2 !== 'object'
  ) {
    return obj1 === obj2
  }

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) return false
    return obj1.every((item, index) => compareDeep(item, obj2[index]))
  }

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) return false

  return keys1.every((key) => compareDeep(obj1[key], obj2[key]))
}

export function getQueryParamFromURL(urlString: string, key: string): string {
  const url = new URL(urlString)
  const value = url.searchParams.get(key)
  return value ? decodeURIComponent(value) : ''
}
