import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from 'components/Table/TableCell'
import {getEyeStatus, getHandStatus} from 'helpers/analysisHelper'
import {
  isoStringToDateAndTimeString,
  parseAgeFromStrings,
} from 'helpers/dateHelper'
import React from 'react'
import {useTranslation} from 'react-i18next'

export interface ReportInfoProps {
  id: number
  clientName?: string | null
  gender?: string | null
  birth: string
  measureDate: string
  ecEo?: string | null
  hand?: string | null
  diseaseCode?: string | null
  resultType?: string | null
  // diseaseId?: number | null
  // diseaseType?: string | null
  isShowEyeStatus?: boolean
  eeg?: boolean
  clientFirstName?: string
  clientLastName?: string
}
export interface ReportInfoPropsPDF extends ReportInfoProps {
  t?: any
  screen: string
}
export default function ReportInfo({
  id,
  clientName,
  clientFirstName,
  clientLastName,
  gender,
  birth,
  measureDate,
  ecEo,
  hand,
  diseaseCode,
  resultType = null,
  isShowEyeStatus = true,
  eeg = false,
}: ReportInfoProps) {
  const {t} = useTranslation()
  const age = parseAgeFromStrings(birth, measureDate)

  return (
    <TableContainer style={{marginBottom: 10}}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            {resultType && resultType === 'NORM' && (
              <TableCell align='center'>{t('IEEGNormDbIndex')}</TableCell>
            )}
            {resultType && resultType === 'MCI' && (
              <TableCell align='center'>{t('IAMciIndex')}</TableCell>
            )}
            {!resultType && (
              <TableCell align='center'>{t('IEEGIndividualIndex')}</TableCell>
            )}
            <TableCell align='center'>{t('ICustomerName')}</TableCell>
            <TableCell align='center'>{t('IGender')}</TableCell>
            <TableCell align='center'>{t('IAgeOfAnalysisTime')}</TableCell>
            <TableCell align='center'>
              {eeg ? t('ISummaryTreatDate') : t('ITreatDate')}
            </TableCell>
            <TableCell align='center'>{t('IHandedness')}</TableCell>
            {isShowEyeStatus && (
              <TableCell align='center'>{t('IECEO')}</TableCell>
            )}

            <TableCell align='center'>{t('IDiseaseCode')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align='center'>{id}</TableCell>
            <TableCell align='center'>
              {clientFirstName || clientLastName
                ? t('IGetFullName', {
                    firstName: clientFirstName ?? '',
                    lastName: clientLastName ?? '',
                  })
                : clientName}
            </TableCell>
            <TableCell align='center'>{gender || '-'}</TableCell>
            <TableCell align='center'>
              {birth}({age === -1 ? '-' : age})
            </TableCell>
            <TableCell align='center'>
              {isoStringToDateAndTimeString(measureDate ?? '').split(' ')[0]}
            </TableCell>
            <TableCell align='center'>{getHandStatus(hand || '-')}</TableCell>
            {isShowEyeStatus && (
              <TableCell align='center'>{getEyeStatus(ecEo || '-')}</TableCell>
            )}
            <TableCell align='center'>{diseaseCode || '-'}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
