import {ElementPDF} from 'components/molcules/GenerateReportPDF/index'
import {REFERENCE_ANALYSIS} from 'constants/AnalysisConstant'

type References = {
  ref: string
  citation: string
  line?: number
}

const LIST_REFERENCE: References[][] = [
  [
    {
      ref: 'T163',
      citation: `Squire LR. Encyclopedia of neuroscience. Amsterdam: Academic Press; 2009.`,
    },
    {
      ref: 'T162',
      citation: `Ramachandran VS. Encyclopedia of human brain. San Diego: Academic Press; 2002.`,
    },
    {
      ref: 'T029',
      citation: `Kropotov JD. Quantitative EEG, event-related potentials and neurotherapy. Amsterdam: Academic Press; 2009.`,
    },
    {
      ref: 'T092',
      citation: `Rajeswaran J. Neuropsychological rehabilitation: principles and applications. London: Elsevier; 2013.`,
    },
    {
      ref: 'T161',
      citation: `Conn PM. Conn's translational neuroscience. London: Academic press; 2013.`,
    },
    {
      ref: 'T101',
      citation: `Nunez P, and Srinivasan R. Electric fields of the brain: the neurophysics of EEG. New York: Oxford University Press; 2006.`,
    },
    {
      ref: 'T087',
      citation: `Brodmann K. and Garey L. Brodmann's localisation in the cerebral cortex: the principles of comparative localisation in the\ncerebral cortex based on cytoarchitectonics. New York: Springer Science Business Media; 2010.`,
      line: 2,
    },
    {
      ref: 'T091',
      citation: `Demos JN. Getting started with neurofeedback. New York: W.W. Norton; 2005.`,
    },
    {
      ref: 'T178',
      citation: `Demos J. N. Getting started with neurofeedback (1th ed.). New York: W.W. Norton; 2005.`,
    },
    {
      ref: 'T164',
      citation: `Woolsey TA, Hanaway J, Mokhtar HG. The brain atlas: a visual guide to the human central nervous system. Chichester: John\nWiley &Sons, Inc.; 2017.`,
      line: 2,
    },
    {
      ref: 'T100',
      citation: `Niedermeyer E, and Silva FL. Electroencephalography (6th ed.). Philadelphia: Wolters Kluwer Health; 2011.`,
    },
    {
      ref: 'T135',
      citation: `Swingle PG. Biofeedback for the brain: how neurotherapy effectively treats depression, adhd, autism, and more. New Brunswick:\nRutgers University Press; 2010.`,
      line: 2,
    },
    {
      ref: 'T028',
      citation: `Thatcher RW. Handbook of quantitative electroencephalography and EEG biofeedback. St. Petersburg, FL: Anipublishing Co;\n2012.`,
      line: 2,
    },
    {
      ref: 'T017',
      citation: `Hales RE, and Yudofsky SC. The American Psychiatric Publishing textbook of neuropsychiatry and behavioral neurosciences\n(Fifth ed.). Washington, DC: American Psychiatric Publishing; 2008.`,
      line: 2,
    },
    {
      ref: 'T001',
      citation: `Thompson M and Thompson L. The neurofeedback-book: an introduction to basic concepts in applied psychophysiology.\nToronto: Association for Applied Psychophysiology and Biofeedback; 2015.`,
      line: 2,
    },
    {
      ref: 'T031',
      citation: `민성길. 최신정신의학. 일조각; 2015.`,
    },
    {
      ref: 'T098',
      citation: `Kim DW, Kim MS, Kim SP, Park YM, Park JY, Bae KY, et al. Understanding and Application of EEG. HAKJISA Publisher Inc.; 2017.`,
    },
    {
      ref: 'T005',
      citation: `대한뇌파연구회. 뇌파분석의 기법과 응용. 대한의학서적; 2017.`,
    },
    {
      ref: 'T179',
      citation: `Klimesch, W. EEG alpha and theta oscillations reflect cognitive and memory performance: a review and analysis. Brain Research.\nBrain Research Reviews 1999; 29, 169–195.`,
      line: 2,
    },
    {
      ref: 'T190',
      citation: `Delacourte A, David JP, Sergeant N, Buee L, Wattez A, ver- mersch P, et al. The biochemical pathway of neurofibrillary\ndegeneration in aging and Alzheimer’s disease. Neurology. 1999; 52(6):1158-65`,
      line: 2,
    },
    {
      ref: 'T219',
      citation: `Assessing attention Deficit Hyperactivity Disorder via Quantitative Electroencephalography: An Initial Validation Study(1999)`,
    },
    {
      ref: 'T041',
      citation: `Pascual-Marqui RD, Lehmann D, Koenig T, Kochi K, Merlo MC, Hell D, et al. Low resolution brain electromagnetic tomography\n(LORETA) functional imaging in acute, neuroleptic-naive, first-episode, productive schizophrenia. Psychiatry Research 1999;\n90:169-79.`,
      line: 3,
    },
    {
      ref: 'T155',
      citation: `Rodriguez G, Copello F, Nobili F, Vitali P, Perego G, and Nobili F. EEG spectral profile to stage Alzheimer’s disease. Clinical\nNeurophysiology 1999a; 110:1831–1837.`,
      line: 2,
    },
    {
      ref: 'T030',
      citation: `Bresnahan, S. M., Anderson, J. W., and Barry, R. J. Age-related changes in quantitative EEG in attention-deficit/hyperactivity\ndisorder. Biological Psychiatry 1999; 46(12), 1690-1697.`,
      line: 2,
    },
    {
      ref: 'T037',
      citation: `Lorig TS. The application of electroencephalographic techniques to the study of human olfaction: a review and tutorial.\nInternational Journal of Psychophysiology 2000; 36:91-104.`,
      line: 2,
    },
    {
      ref: 'T132',
      citation: `Sponheim SR, Brett A, Clementz WG, and Morton B. Clinical and biological concomitants of resting state EEG power\nabnormalities in schizophrenia. Biological Psychiatry 2000; 48(11), 1088-097.`,
      line: 2,
    },
    {
      ref: 'T169',
      citation: `Engel, A. K., and Singer, W. Temporal binding and the neural correlates of sensory awareness. Trends in Cognitive\nSciences 2001; 5, 16–25`,
      line: 2,
    },
    {
      ref: 'T120',
      citation: `Raichle ME, McLeod AM, Snyder AZ, Powers WJ, Gusnard DA, and Shulman GL. A default mode of brain function. Proceedings of`,
    },
  ],
  [
    {
      ref: '',
      citation: `the National Academy of Science 2001; 98, 676–682.`,
    },
    {
      ref: 'T070',
      citation: `Monastra VJ, Lubar JF, and Linden M. The development of a quantitative electroencephalographic scanning process for\nattention deficit-hyperactivity disorder: Reliability and validity studies. Neuropsychology 2001; 15(1), 136-144.`,
      line: 2,
    },
    {
      ref: 'T111',
      citation: `Clarke AR, Barry RJ, Mccarthy R, and Selikowitz M. Age and sex effects in the EEG: differences in two subtypes of attention-\ndeficit/hyperactivity disorder. Clinical Neurophysiology 2001; 112(5), 815-826.`,
      line: 2,
    },
    {
      ref: 'T016',
      citation:
        'Clarke AR, Barry RJ, Mccarthy R, and Selikowitz M. EEG-defined subtypes of children with attention-deficit/hyperactivity\ndisorder. Clinical Neurophysiology 2001; 112(11), 2098-2105.',
      line: 2,
    },
    {
      ref: 'T010',
      citation:
        'Kowalski JW, Gawel M, Pfeffer A, and Barcikowska M. The Diagnostic Value of EEG in Alzheimer Disease. Journal of Clinical\nNeurophysiology 2001;18(6), 570-575.',
      line: 2,
    },
    {
      ref: 'T110',
      citation:
        'Bresnahan SM and Barry RJ. Specificity of quantitative EEG analysis in adults with attention deficit hyperactivity disorder.\nPsychiatry Research 2002; 112(2), 133-144.',
      line: 2,
    },
    {
      ref: 'T019',
      citation:
        'Vernon, David, Tobias Egner, Nick Cooper, Theresa Compton, Claire Neilands, Amna Sheri, and John Gruzelier. The effect of\ntraining distinct neurofeedback protocols on aspects of cognitive performance. International Journal of Psychophysiology 2003;\n47(1): 75-85.',
      line: 3,
    },
    {
      ref: 'T011',
      citation:
        'Barry RJ, Clarke AR, and Johnstone SJ. A review of electrophysiology in attention-deficit/hyperactivity disorder: I. Qualitative\nand quantitative electroencephalography. Clinical Neurophysiology 2003;114(2), 171-183.',
      line: 2,
    },
    {
      ref: 'T218',
      citation:
        'R.D. Pascual-Marqui. Standardized low resolution brain electromagnetic tomography (sLORETA): technical details. Methods\n&Findings in Experimental &Clinical Pharmacology 2002, 24D:5-12.',
      line: 2,
    },
    {
      ref: 'T175',
      citation:
        'Caplan, J. B., Madsen, J. R., Schulze-Bonhage, A., Aschenbrenner-Scheibe, R., Newman, E. L., and Kahana, M. J. Human theta\noscillations related to sensorimotor integration and spatial learning. Journal of Neuroscience 23 2003; 4726-4736.',
      line: 2,
    },
    {
      ref: 'T053',
      citation:
        "M'Harzi M and Jarrard LE. Effects of medial and lateral septal lesions on acquisition of a place and cue radial maze task.\n Behavioural Brain Research 1992; 49:159-165.",
      line: 2,
    },
    {
      ref: 'T063',
      citation:
        'Hinrichs, H., and Machleidt, W. Basic emotions reflected in EEG-coherences, International Journal of Psychophysiology 1992;\n 13(3), 225-232.',
      line: 2,
    },
    {
      ref: 'T154',
      citation:
        'Moretti DV, Babiloni C, Binetti G, Cassetta E, Dal Forno G, Ferreri F, Ferri R, Bartolo Lanuzza, Miniussi C, Nobili F, Rodriguez G,\nSalinari S, and Rossini PM. Individual analysis of EEG frequency and band power in mild Alzheimer’s Disease. Clinical\nNeurophysiology 2004; 115:299–308.',
      line: 3,
    },
    {
      ref: 'T117',
      citation:
        'Angelakis E, Lubar JF, Stathopoulou S, and Kounios J. Peak alpha frequency: an electroencephalographic measure of cognitive\n preparedness. Clinical Neurophysiology 2004; 115(4), 887-897.',
      line: 2,
    },
    {
      ref: 'T116',
      citation:
        'Clark CR, Veltmeyer MD, Hamilton RJ, Simms E, Paul R, Hermens D, et al. Spontaneous alpha peak frequency predicts working\n memory performance across the age span. International Journal of Psychophysiology 2004; 53(1), 1-9.',
      line: 2,
    },
    {
      ref: 'T131',
      citation:
        'Jeong JS. EEG dynamics in patients with Alzheimers disease. Clinical Neurophysiology 2004; 115(7), 1490-1505.',
      line: 2,
    },
    {
      ref: 'T102',
      citation:
        'Gross J, Schmitz F, Schnitzler I, Kessler K, Shapiro K, Hommel B, et al. Modulation of long-range neural synchrony reflects\n temporal limitations of visual attention in humans. Proceedings of the National Academy of Sciences 2004; 101(35), 13050-\n13055.',
      line: 3,
    },
    {
      ref: 'T217',
      citation:
        'Antoine Lutz, Lawrence L. Greischar, Nancy B. Rawlings, Matthieu Ricard, and Richard J. Davidson. Long-term meditators self\n-induce high-amplitude gamma synchrony during mental practice. Proc Natl Acad Sci U S A. 2004; 16; 101(46): 16369-16373.',
      line: 3,
    },
    {
      ref: 'T153',
      citation:
        'Koenig T, Prichep L, Dierks T, Hubl D, Wahlund LO, John ER, and Jelic V. Decreased EEG synchronization in Alzheimer’s disease\nand mild cognitive impairment. Neurobiology of Aging 2005; 26(2):165-71.',
      line: 2,
    },
    {
      ref: 'T045',
      citation:
        'Schnitzler A and Gross J. Normal and pathological oscillatory communication in the brain. Nature Reviews Neuroscience 2005;\n6:285-296.',
      line: 2,
    },
    {
      ref: 'T078',
      citation:
        'Leonardo F. Fontenelle, Mauro V. Mendlowicz, Pedro Ribeiro, Roberto A. Piedade, and Marcio Versiani. Low-resolution\nelectromagnetic tomography and treatment response in obsessive-compulsive disorder. International Journal of\nNeuropsychopharmacology 2006; 9(1):89-94.',
      line: 3,
    },
    {
      ref: 'T034',
      citation:
        'Monastra VJ, Lynn S, Linden M, Lubar JF, Gruzelier J, and Lavaque TJ. Electroencephalographic Biofeedback in the Treatment of\nAttention-Deficit/Hyperactivity Disorder. Applied Psychophysiology and Biofeedback 2005; 30(2), 95-114.',
      line: 2,
    },
    {
      ref: 'T044',
      citation:
        'Ribary U. Dynamics of thalamo-cortical network oscillations and human perception. Progress in Brain Research 2005; 150:127-\n142.',
      line: 2,
    },
    {
      ref: 'T060',
      citation:
        'Sauseng P, Klimesch W, Stadler W, Schabus M, Doppelmayr M, et al. A shift of visual spatial attention is selectively associated\nwith human EEG alpha activity. European Journal of Neuroscience 2005; 22(11), 2917-2926.',
      line: 2,
    },
    {
      ref: 'T172',
      citation:
        'Steriade, M. Grouping of brain rhythms in corticothalamic systems. Neuroscience 137 2006, 1087-1106.',
    },
    {
      ref: 'T148',
      citation:
        'Babiloni C, Binetti G, Cassetta E, Dal Forno G, Del Percio C, Ferreri F, Ferri R, Frisoni G, Hirata K, Lanuzza B, Miniussi C, Moretti DV,\nNobili F, Rodriguez G, Romani GL, Salinari S, and Rossini PM. Sources of cortical rhythms change as a function of cognitive\nimpairment in pathological aging: A multi-centric study. Clinical Neurophysiology 2006;117(2):252-268.',
      line: 3,
    },
    {
      ref: 'T002',
      citation:
        'Pilon M, Zadra A, Joncas S, and Montplaisir J. Hypersynchronous Delta Waves and Somnambulism: Brain Topography and Effect\nof Sleep Deprivation. Sleep 2006; 29(1), 77-84.',
      line: 2,
    },
  ],
  [
    {
      ref: 'T020',
      citation:
        'Egner, Tobias, and M Barry Sterman. Neurofeedback treatment of epilepsy: from basic rationale to practical application. Expert\nReview of Neurotherapeutics. Future Drugs. 2006; 6(2): 247-257.',
      line: 2,
    },
    {
      ref: 'T055',
      citation:
        'Osipova D, Takashima A, Oostenveld R, Fernndez G, Maris E, and Jensen O. Theta and gamma oscillations predict encoding and\nretrieval of declarative memory. Journal of Neuroscience 2006; 26:7523-7531.',
      line: 2,
    },
    {
      ref: 'T061',
      citation:
        'Klimesch W, Sauseng P, and Hanslmayr S. EEG alpha oscillations: The inhibition-timing hypothesis. Brain Research Reviews\n2007; 53(1), 63-88.',
      line: 2,
    },
    {
      ref: 'T046',
      citation:
        'Uhlhaas PJ and Singer W. Neural Synchrony in Brain Disorders: Relevance for Cognitive Dysfunctions and Pathophysiology.\nNeuron 2006; 52(1), 155-168.',
      line: 2,
    },
    {
      ref: 'T129',
      citation:
        'Celone KA, Calhoun VD, Dickerson BC, Atri A, Chua EF, Miller SL, et al. Alterations in Memory Networks in Mild Cognitive\nImpairment and Alzheimers Disease: An Independent Component Analysis. Journal of Neuroscience 2006; 26(40), 10222-\n10231.',
      line: 3,
    },
    {
      ref: 'T166',
      citation:
        'Cheron, G., Leroy, A., De Saedeleer, C., Bengoetxea, A., Lipshits, M., Cebolla, A., et al. Effect of gravity on human spontaneous\n10-Hz electroencephalographic oscillations during the arrest reaction. Brain Research 2006; 1121, 104-116.',
      line: 2,
    },
    {
      ref: 'T124',
      citation:
        'Knyazev GG. Motivation, emotion, and their inhibitory control mirrored in brain oscillations. Neuroscience &Biobehavioral\nReviews 2007; 31(3), 377-395.',
      line: 2,
    },
    {
      ref: 'T012',
      citation:
        'Hobbs MJ, Clarke AR, Barry RJ, Mccarthy R, and Selikowitz M. EEG abnormalities in adolescent males with AD/HD. Clinical\nNeurophysiology 2007;118(2), 363-371.',
      line: 2,
    },
    {
      ref: 'T027',
      citation:
        'Byring, R. F., Salmi, T. K., Sainio, K. O., and rn, H. P. EEG in children with spelling disabilities. Electroencephalography and Clinical\nNeurophysiology 1991; 79(4), 247-255.',
      line: 2,
    },
    {
      ref: 'T088',
      citation:
        'Murias M, Webb SJ, Greenson J, and Dawson G. Resting State Cortical Connectivity Reflected in EEG Coherence in Individuals\nWith Autism. Biological Psychiatry 2007;62(3), 270-273.',
      line: 2,
    },
    {
      ref: 'T177',
      citation:
        'Sammler, D., Grigutsch, M., Fritz, T., and Koelsch, S. Music and emotion: electrophysiological correlates of the processing of\npleasant and unpleasant music. Psychophysiology 2007; 44, 293-304.',
      line: 2,
    },
    {
      ref: 'T118',
      citation:
        'Caviness J, Hentz J, Evidente V, Driver-Dunckley E, Samanta J, Mahant P, et al. Both early and late cognitive dysfunction affects\nthe electroencephalogram in Parkinsons disease. Parkinsonism &Related Disorders 2007; 13(6), 348-354.',
      line: 2,
    },
    {
      ref: 'T036',
      citation:
        'Quintana H, Snyder SM, Purnell W, Aponte C, and Sita J. Comparison of a standard psychiatric evaluation to rating scales and\nEEG in the differential diagnosis of attention-deficit/hyperactivity disorder. Psychiatry Research 2007; 152(2-3), 211-222.',
      line: 2,
    },
    {
      ref: 'T042',
      citation:
        'Jensen 0, Kaiser J, and Lachaux JP. Human gamma-frequency oscillations associated with attention and memory. Trends in\nNeurosciences 2007; 30:317-324.',
      line: 2,
    },
    {
      ref: 'T013',
      citation:
        'Clarke AR, Barry RJ, Mccarthy R, Selikowitz M and Johnstone SJ, et al. Coherence in children with Attention-Deficit/Hyperactivity\nDisorder and excess beta activity in their EEG. Clinical Neurophysiology 2007; 118(7), 1472-1479.',
      line: 2,
    },
    {
      ref: 'T149',
      citation:
        "Babiloni C, Cassetta E, Binetti G, Tombini M, Del Percio C, Ferreri F, Ferri R, Frisoni G, Lanuzza B, Nobili F, Parisi L, Rodriguez G,\nFrigerio L, Gurz M, Prestia A, Vernieri F, Eusebi F, and Rossini PM. Resting EEG sources correlate with attentional span in mild\ncognitive impairment and Alzheimer's disease. European Journal of Neuroscience 2007; 25(12):3742-57.",
      line: 3,
    },
    {
      ref: 'T121',
      citation:
        'Raichle ME and Snyder AZ. A default mode of brain function: A brief history of an evolving idea. Neurolmage 2007; 37(4), 1083-\n1090.',
      line: 2,
    },
    {
      ref: 'T195',
      citation:
        'Castellanos FX, Margulies DS, Kelly C, Uddin LQ, Ghaf- fari M, Kirsch A, et al. Cingulate-precuneus interactions: a new locus of\ndysfunction in adult attention-deficit/hyperactivity disorder. Biological Psychiatry 2008; 63(3):332-7.',
      line: 2,
    },
    {
      ref: 'T157',
      citation:
        "Bonanni L., Thomas A., Tiraboschi P., Perfetti B., Varanese S., and Onofrj M. EEG comparisons in early Alzheimer's disease,\ndementia with Lewy bodies and Parkinson's disease with dementia patients with a 2-year follow-up. Brain 2008; 131:690- 705.",
      line: 2,
    },
    {
      ref: 'T184',
      citation:
        'Dosenbach NU, et al. A dual-networks architecture of top-down control. Trends in Cognitive Sciences 2008; 12:99-105.',
    },
    {
      ref: 'T122',
      citation:
        'Buckner RL, Andrews-Hanna JR, and Schacter DL. The Brains Default Network. Annals of the New York Academy of Sciences\n2008; 1124(1), 1-38.',
      line: 2,
    },
    {
      ref: 'T072',
      citation:
        'Snyder SM, Quintana H, Sexson SB, Knott P, Haque A, and Reynolds DA. Blinded, multi-center validation of EEG and rating scales\nin identifying ADHD within a clinical sample. Psychiatry Research 2008; 159(3), 346-358.',
      line: 2,
    },
    {
      ref: 'T115',
      citation:
        'Greicius M. Resting-state functional connectivity in neuropsychiatric disorders. Current Opinion in Neurology 2008;24(4), 424-\n430.',
      line: 2,
    },
    {
      ref: 'T150',
      citation:
        'Babiloni C, Frisoni GB, Pievani M, Vecchio F, Lizio R, Buttiglione M, Geroldi C, Fracassi C, Eusebi F, Ferri R, and Rossini PM.\nHippocampal volume and cortical sources of EEG alpha rhythms in mild cognitive impairment and Alzheimer\ndisease. Neuroimage 2009a; 44(1):123-35.',
      line: 3,
    },
    {
      ref: 'T113',
      citation:
        'Koehler S, Lauer P, Schreppel T, Jacob C, Heine M, Boreatti-Hmmer A, et al. Increased EEG power density in alpha and theta\nbands in adult ADHD patients. Journal of Neural Transmission 2009; 116(1), 97-104.',
      line: 2,
    },
    {
      ref: 'T159',
      citation:
        'Cantero, J.L., Atienza, M., Cruz-Vadell, A., Suarez-Gonzalez, A., and Gil-Neciga, E. Increased synchronization and decreased\nneural complexity underlie thalamocortical oscillatory dynamics in mild cognitive impairment. Neurolmage 2009, 46, 938-948.',
      line: 2,
    },
    {
      ref: 'T067',
      citation:
        'Lubar JF. Discourse on the development of EEG diagnostics and biofeedback for attention-deficit/hyperactivity disorders.\nBiofeedback and Self-Regulation 1991;16:201-225.',
      line: 2,
    },
    {
      ref: 'T056',
      citation:
        'Robbe D and Buzski G. Alteration of theta timescale dynamics of hippocampal place cells by a cannabinoid is associated with',
    },
  ],
  [
    {
      ref: '',
      citation: `memory impairment. Journal of Neuroscience 2009; 29:12597-12605.`,
    },
    {
      ref: 'T183',
      citation:
        'Hedden T, van Dijk KR, Becker JA, Mehta A, Sperling RA, Johnson KA, et al. Disruption of functional connectivity in clinically\nnormal older adults harboring amyloid burden. Journal of Neuroscience 2009; 29(40):12686-94',
      line: 2,
    },
    {
      ref: 'T057',
      citation:
        'Rutishauser U, Ross IB, Mamelak AN, and Schuman EM. Human memory strength is predicted by theta-frequency phase-locking\nof single neurons. Nature 2010; 464:903-907.',
      line: 2,
    },
    {
      ref: 'T187',
      citation:
        'Liao W, et al. Selective aberrant functional connectivity of resting state networks in social anxiety disorder. Neurolmage 2010;\n52:1549-1558',
      line: 2,
    },
    {
      ref: 'T197',
      citation:
        'Sheline Yi, Price JL, Yan Z, Mintun MA. Resting-state functional MRi in depression unmasks increased connectivity between\nnetworks via the dorsal nexus. Proc Natl Acad Sci U S A. 2010; 107(24):11020-25.',
      line: 2,
    },
    {
      ref: 'T213',
      citation:
        'Congedo M, John RE, De Ridder D, Prichep L. Group independent component analysis of resting state EEG in large normative\nsamples. Int J Psychophysiol. 2010;78(2):89-99.',
      line: 2,
    },
    {
      ref: 'T047',
      citation:
        'Wang XJ. Neurophysiological and computational principles of cortical rhythms in cognition. Physiological Reviews 2010;\n90:1195-1268.',
      line: 2,
    },
    {
      ref: 'T114',
      citation:
        'Lansbergen MM, Arns M, Dongen-Boomsma MV, Spronk D, and Buitelaar JK. The increase in theta/beta ratio on resting-state\nEEG in boys with attention-deficit/hyperactivity disorder is mediated by slow alpha peak frequency. Progress in Neuro-\nPsychopharmacology and Biological Psychiatry 2011; 35(1), 47-52.',
      line: 3,
    },
    {
      ref: 'T198',
      citation:
        'Lansbergen MM, Arns M, van Dongen-Boomsma M, Spronk D, Buitelaar JK. The increase in theta/beta ratio on resting-state EEG\nin boys with attention-deficit/hyperactivity disorder is mediated by slow alpha peak frequency. Prog Neuropsychopharmacol Biol\nPsychiatry. 2011;35(1):47-52',
      line: 3,
    },
    {
      ref: 'T194',
      citation:
        'Liddle EB, Hollis C, Batty MJ, Groom MJ, Totman JJ, Liotti M. Task-related default mode network modula- tion and inhibitory\ncontrol in ADHD: effects of moti- vation and methylphenidate. Journal of Child Psychology and Psychiatry 2011; 52(7):761-71.',
      line: 2,
    },
    {
      ref: 'T192',
      citation:
        "Kwak Y, Peltier S, Bohnen Ni, Muller ML, Dayalu P, Seidler RD. Altered resting state cortico-striatal connectivity in mild to\nmoderate stage Parkinson's disease. Frontiers in Systems Neuroscience. 2010; 4:143",
      line: 2,
    },
    {
      ref: 'T112',
      citation:
        'Clarke AR, Barry RJ, Dupuy FE, Heckel LD, Mccarthy R, Selikowitz M, et al. Behavioural differences between EEG-defined\nsubgroups of children with Attention-Deficit/Hyperactivity Disorder. Clinical Neurophysiology 2011; 122(7), 1333-1341.',
      line: 2,
    },
    {
      ref: 'T141',
      citation:
        'Ptak R. The Frontoparietal Attention Network of the Human Brain: Action, Saliency, and a Priority Map of the Environment. The \n Neuroscientist 2012; 18(5), 502-515.',
      line: 2,
    },
    {
      ref: 'T074',
      citation:
        'Abibullaev, B., and An, J. Decision Support Algorithm for Diagnosis of ADHD Using Electroencephalograms. Journal of Medical \n Systems 2011; 36(4), 2675-2688.',
      line: 2,
    },
    {
      ref: 'T109',
      citation:
        'Nazari MA, Wallois F, Aarabi A, and Berquin P. Dynamic changes in quantitative electroencephalogram during continuous\n performance test in children with attention-deficit/hyperactivity disorder. International Journal of Psychophysiology 2011; \n81(3), 230-236.',
      line: 3,
    },
    {
      ref: 'T201',
      citation:
        "Fonseca LC, Tedrus GM, Fondello MA, Reis IN, Fontoura DS. EEG theta and alpha reactivity on opening the eyes in the diagnosis\n of Alzheimer's disease. Clin EEG Neurosci.2011; 42(3):185-189.",
      line: 2,
    },
    {
      ref: 'T173',
      citation:
        'Knyazev G. EEG delta oscillations as a correlate of basic homeostatic and motivational processes. Neuroscience &Biobehavioral\n Reviews 36 2012; 677-695.',
      line: 2,
    },
    {
      ref: 'T144',
      citation:
        'Ding J, Liao W, Zhang Z, Mantini D, Xu Q, Wu G, et al. Topological Fractionation of Resting-State Networks. PLoS ONE 2011;\n 6(10), 1-9.',
      line: 2,
    },
    {
      ref: 'T123',
      citation:
        'Canuet L, Ishii R, Pascual-Marqui RD, Iwase M, Kurimoto R, Aoki Y, et al. Resting-State EEG Source Localization and Functional\n Connectivity in Schizophrenia-Like Psychosis of Epilepsy. PLoS ONE 2011; 6(11).',

      line: 2,
    },
    {
      ref: 'T196',
      citation:
        'Zhu X, Wang X, Xiao J, Liao J, Zhong M, Wang W, et al. Evidence of a dissociation pattern in resting-state default mode network\n connectivity in first-episode, treatment-naive major depression patients. Biological Psychiatry. 2012; 71(7):611-7.',
      line: 2,
    },
    {
      ref: 'T130',
      citation:
        'Whitfield-Gabrieli S, and Ford JM. Default Mode Network Activity and Connectivity in Psychopathology. Annual Review of Clinical\n Psychology 2012; 8(1), 49-76.',
      line: 2,
    },
    {
      ref: 'T058',
      citation:
        'Liebe S, Hoerzer GM, Logothetis NK, and Rainer G. Theta coupling between V4 and prefrontal cortex predicts visual short-term\n memory performance. Nature Neuroscience, 2012; 15(3), 456-462.',
      line: 2,
    },
    {
      ref: 'T128',
      citation:
        "Xie T, and He Y. Mapping the Alzheimer's Brain with Connectomics. Frontiers in Psychiatry 2012; 2, 1-14.",
    },
    {
      ref: 'T151',
      citation:
        'Babiloni C, Carducci F, Lizio R, Vecchio F, Baglieri A, Bernardini S, Cavedo E, Bozzao A, Buttinelli C, Esposito F, Giubilei F, Guizzaro\nA, Marino S, Montella P, Quattrocchi CC, Redolfi A, Soricelli A, Tedeschi G, Ferri R, Rossi-Fedele G, Ursini F, Scrascia F, Vernieri F,\nPedersen TJ, Hardemark HG, Rossini PM, and Frisoni GB. Resting state cortical electroencephalographic rhythms are related to\ngray matter volume in subjects with mild cognitive impairment and Alzheimer’s disease. Human Brain Mapping 2013;\n34(6):1427-46.',
      line: 5,
    },
    {
      ref: 'T071',
      citation:
        'Ogrim, G., Kropotov, J., and Hestad, K. The quantitative EEG theta/beta ratio in attention deficit/hyperactivity disorder and\n normal controls: Sensitivity, specificity, and behavioral correlates. Psychiatry Research 2012; 198(3), 482-488.',
      line: 2,
    },
    {
      ref: 'T052',
      citation:
        'Mizumori SJ, Perez GM, Alvarado MC, Barnes CA, and McNaughton BL. Reversible inactivation of the medial septum differentially\n affects two forms of learning in rats. Brain Research 1990; 528:12-20.',
      line: 2,
    },
    {
      ref: 'T125',
      citation:
        'Calmels C, Foutren M, and Stam C. Beta functional connectivity modulation during the maintenance of motion information in.',
    },
  ],
  [
    {
      ref: '',
      citation:
        'working memory: Importance of the familiarity of the visual context. Neuroscience 2012; 212, 49-58.',
    },
    {
      ref: 'T180',
      citation:
        'Massar, S. A. A., Rossi, V., Schutter, D. J. L. G., and Kenemans, J. L. Baseline EEG theta/beta ratio and punishment sensitivity as \n biomarkers for feedback-related negativity (FRN) and risk-taking. Clinical Neurophysiology 2012; 123, 1958-1965.',
      line: 2,
    },
    {
      ref: 'T142',
      citation:
        'Sylvester C, Corbetta M, Raichle M, Rodebaugh T, Schlaggar B, Sheline Y, et al. Functional network dysfunction in anxiety and\n anxiety disorders. Trends in Neurosciences 2012; 35(9), 527-535.',
      line: 2,
    },
    {
      ref: 'T069',
      citation:
        'Loo, S. K., and Makeig, S. Clinical Utility of EEG in Attention-Deficit/Hyperactivity Disorder: A Research Update.\n Neurotherapeutics 2012; 9(3), 569-587.',
      line: 2,
    },
    {
      ref: 'T062',
      citation:
        'Bonnefond M and Jensen O. Alpha oscillations serve to protect working memory maintenance against anticipated distracters.\n Current Biology 2012; 22:1969-1974.',
      line: 2,
    },
    {
      ref: 'T099',
      citation:
        'Arns M, Conners CK, and Kraemer HC. A Decade of EEG Theta/Beta Ratio Research in ADHD: A Meta-Analysis. Journal of\n Attention Disorders 2013; 17(5), 374-383.',
      line: 2,
    },
    {
      ref: 'T119',
      citation:
        'Fiebelkorn I, Snyder A, Mercier M, Butler J, Molholm S, and Foxe J. Cortical cross-frequency coupling predicts perceptual\n outcomes. Neurolmage 2013; 69, 126-137.',
      line: 2,
    },
    {
      ref: 'T127',
      citation:
        'Fink A, and Benedek M. EEG alpha power and creative ideation. Neuroscience &Biobehavioral Reviews 2014; 44, 111-123.',
    },
    {
      ref: 'T171',
      citation:
        'Wang, Q., and Sourina, O. Real-time mental arithmetic task recognition from EEG signals. IEEE Transactions on Neural Systems\n and Rehabilitation Engineering 2013; 21(2), 225-232.',
      line: 2,
    },
    {
      ref: 'T004',
      citation:
        'Kryzhanovskii, G. N., Shandra, A. A., Godlevskii, L. S., and Mikhaleva, I. I. Appearance of Parkinsonian Syndrome after \n Administration of Delta Sleep-inducing Peptide into the Rat Substantia Nigra. Bulletin of Experimental Biology and Medicine \n 1990; 109(2), 119-121.',
      line: 3,
    },
    {
      ref: 'T143',
      citation:
        'Alfonso M, Miquel T, Xavier B, and Blanca A. Resting Parietal Electroencephalogram Asymmetries and Self-Reported Attentional\n Control. Clinical EEG and Neuroscience 2013; 44(3), 188-192.',
      line: 2,
    },
    {
      ref: 'T174',
      citation:
        'Hasselmo, M. E., and Stern, C. E. Theta rhythm and the encoding and retrieval of space and time. NeuroImage 85(Pt 2) 2014;\n 656–666.',
    },
    {
      ref: 'T107',
      citation:
        'Vossel S, Geng JJ, and Fink GR. Dorsal and Ventral Attention Systems. The Neuroscientist 2014; 20(2), 150-159.',
    },
    {
      ref: 'T210',
      citation:
        "Hsiao FJ, Wang YJ, Yan SH, Chen WT, Lin YY. Altered oscillation and synchronization of default-mode network activity in mild\n Alzheimer's disease compared to mild cognitive impairment: an electrophysiological study. PLoS One, 2013 Jul 11;8(7):e68792.",
      line: 2,
    },
    {
      ref: 'T185',
      citation:
        'Cole MW, et al. Multi-task connectivity reveals flexible hubs for adaptive task control. Nature Neuroscience 2013; 16:1348-1355.',
    },
    {
      ref: 'T134',
      citation:
        'Micanovic C and Suvankar P. The diagnostic utility of EEG in early-onset dementia: a systematic review of the literature with\n narrative analysis. Journal of Neural Transmission 2014; 121(1), 59-69.',
      line: 2,
    },
    {
      ref: 'T170',
      citation:
        'Plattner, K., Lambert, M. I., Tam, N., Lamberts, R. P., and Baumeister, J. Changes in cortical beta activity related to a biceps\n brachii movement task while experiencing exercise induced muscle damage. Physiology &Behavior 2014; 123, 1-10.',
      line: 2,
    },
    {
      ref: 'T203',
      citation:
        "Schmidt MT, Kanda PA, Basile LF, da Silva Lopes HF, Baratho R, Demario JL, Jorge MS, Nardi AE, Machado S, Ianof JN, Nitrini\nR, Anghinah R. Index of alpha/theta ratio of the electroencephalogram: a new marker for Alzheimer's disease. Front Aging\nNeurosci. 2013; 9;5:60. doi: 10.3389/fnagi.2013.00060. eCollection 2013",
      line: 3,
    },
    {
      ref: 'T008',
      citation:
        'Brenner, R. P., Ulrich, R. F., Spiker, D. G., Sclabassi, R. J., Reynolds, C. F., Marin, R. S., &Boller, F. (1986). Computerized EEG\n spectral analysis in elderly normal, demented and depressed subjects. Electroencephalography and Clinical Neurophysiology,\n 64(6), 483-492.',
      line: 3,
    },
    {
      ref: 'T160',
      citation:
        'Harmony T. The functional significance of delta oscillations in cognitive processing. Frontiers in Integrative\n Neuroscience 7 2013; p.83.',
      line: 2,
    },
    {
      ref: 'T136',
      citation:
        'Putman P, Verkuil B, Arias-Garcia E, Pantazi I, and Schie CV. EEG theta/beta ratio as a potential biomarker for attentional control\n and resilience against deleterious effects of stress on attention. Cognitive, Affective, &Behavioral Neuroscience 2014; 14(2),\n 782-791.',
      line: 3,
    },
    {
      ref: 'T200',
      citation:
        'Bian Z, Li Q, Wang L, Lu C, Yin S, Li X. Relative power and coherence of EEG series are related to amnestic mild cognitive\n impairment in diabetes. Front Aging Neurosci. 2014; 4;6:11. doi: 10.3389/fnagi.2014.00011. eCollection 2014.',
      line: 2,
    },
    {
      ref: 'T182',
      citation:
        'Dennis EL and Thompson PM. Functional brain connectivity using fMRi in aging and Alzheimer’s disease. Neuropsychology\n Review 2014; 24(1):49-62.',
      line: 2,
    },
    {
      ref: 'T126',
      citation:
        'Abundis-Gutirrez A, Checa P, Castellanos C, and Rueda MR. Electrophysiological correlates of attention networks in childhood\nand early adulthood. Neuropsychologia 2014; 57, 78-92.',
      line: 2,
    },
    {
      ref: 'T140',
      citation:
        'Cole MW, Repov G, and Anticevic A. The Frontoparietal Control System: a central role in mental health. The Neuroscientist 2014;\n20(6), 652-664.',
      line: 2,
    },
    {
      ref: 'T167',
      citation:
        'Arjona, A., Escudero, M., and Gmez, C. M. Updating of attentional and premotor allocation resources as function of previous trial\noutcome. Scientific Reports 2014; 4:4526.',
      line: 2,
    },
    {
      ref: 'T007',
      citation:
        'John, E. The role of quantitative EEG topographic mapping or ‘neurometrics’ in the diagnosis of psychiatric and neurological\ndisorders: the pros. Electroencephalography and Clinical Neurophysiology 1989; 73(1), 2-4.',
      line: 2,
    },
    {
      ref: 'T158',
      citation:
        'Prinz PN and Vitiello MV. Dominant occipital (alpha) rhythm frequency in early stage Alzheimer’s disease and depression.',
    },
  ],
  [
    {
      ref: '',
      citation: 'Electroencephalogr. Clinical Neurophysiology 1989; 73:427–32.',
    },
    {
      ref: 'T205',
      citation:
        'Schleiger E, Sheikh N, Rowland T, Wong A, Read S, Finnigan S. Frontal EEG delta/alpha ratio and screening for post-stroke\ncognitive deficits: the power of four electrodes. Int J Psychophysiol. 2014 ;94(1):19-24',
      line: 2,
    },
    {
      ref: 'T215',
      citation:
        'Imperatori C, Farina B, Quintiliani MI, Onofri A, Castelli Gattinara P, Lepore M, Gnoni V, Mazzucchi E, Contardi A, Della Marca G.\nAberrant EEG functional connectivity and EEG power spectra in resting state post-traumatic stress disorder: a sLORETA study.\nBiol Psychol. 2014;102:10-7.',
      line: 3,
    },
    {
      ref: 'T104',
      citation:
        'Thatcher RW, North DM, and Biver CJ. LORETA EEG phase reset of the default mode network. Frontiers in Human Neuroscience\n2014; 8, 1-14.',
      line: 2,
    },
    {
      ref: 'T208',
      citation:
        'Kim JS, Shin KS, Jung WH, Kim SN, Kwon JS, Chung CK. Power spectral aspects of the default mode network in schizophrenia:\nan MEG study. BMC Neurosci. 2014; 5;15:104.',
      line: 2,
    },
    {
      ref: 'T214',
      citation:
        'Garcs P, Angel Pineda-Pardo J, Canuet L, Aurtenetxe S, Lpez ME, Marcos A, Yus M, Llanero-Luque M, Del-Pozo F, Sancho M,\nMaest F. The Default Mode Network is functionally and structurally disrupted in amnestic mild cognitive impairment - a bimodal\nMEG-DTI study. Neuroimage Clin. 2014; 10;6:214-21.',
      line: 3,
    },
    {
      ref: 'T193',
      citation:
        "Putcha D, Ross RS, Cronin-Golomb A, Janes AC, Stern CE. Altered intrinsic functional coupling between core neurocognitive\nnetworks in Parkinson's disease. NeuroImage: Clinical 2015; 7:449-55.",
      line: 2,
    },
    {
      ref: 'T108',
      citation:
        'Farrant K, and Uddin LQ. Asymmetric development of dorsal and ventral attention networks in the human brain. Developmental\nCognitive Neuroscience 2015; 12, 165-174.',
      line: 2,
    },
    {
      ref: 'T035',
      citation:
        "Snyder SM, Rugino TA, Hornig M, and Stein MA. Integration of an EEG biomarker with a clinician's ADHD evaluation. Brain and\n Behavior 2015; 5(4).",
      line: 2,
    },
    {
      ref: 'T032',
      citation:
        'Morillas-Romero A, Tortella-Feliu M, Bornas X, and Putman P. Spontaneous EEG theta/beta ratio and delta–beta coupling in\nrelation to attentional network functioning and self-reported attentional control. Cognitive, Affective, &Behavioral Neuroscience\n2015;15(3), 598-606.',
      line: 3,
    },
    {
      ref: 'T165',
      citation:
        'Mierau, A., Felsch, M., Hlsdnker, T., Mierau, J., Bullermann, P., Weiß, B., et al. The interrelation between sensorimotor abilities,\n cognitive performance and individual EEG alpha peak frequency in young children. Clinical Neurophysiology 2016; 127, 270–276.',
      line: 2,
    },
    {
      ref: 'T176',
      citation:
        'Ofori, E., Coombes, S. A., and Vaillancourt, D. E. 3D Cortical electrophysiology of ballistic upper limb movement in\nhumans. NeuroImage 2015; 115, 30-41.',
      line: 2,
    },
    {
      ref: 'T204',
      citation:
        'Finnigan S, Wong A, Read S. Defining abnormal slow EEG activity in acute ischaemic stroke: Delta/alpha ratio as an optimal\nQEEG index. Clin Neurophysiol. 2016; 127(2): 1452-1459.',
      line: 2,
    },
    {
      ref: 'T139',
      citation:
        'Peeters S, Bronswijk SV, Ven VV, Gronenschild E, Goebel R, Os JV, et al. Cognitive correlates of frontoparietal network\nconnectivity ‘at rest’ in individuals with differential risk for psychotic disorder. European Neuropsychopharmacology 2015;\n25(11), 1922-1932.',
      line: 3,
    },
    {
      ref: 'T189',
      citation:
        'Wang, P., Zhou, B., Yao, H., Zhan, Y., Zhang, Z., Cui, Y., et al. Aberrant intra- and inter-network connectivity architectures in\nAlzheimer’s disease and mild cognitive impairment. Scientific Reports 2015; 5, 14824.',
      line: 2,
    },
    {
      ref: 'T106',
      citation:
        'Hata M, Kazui H, Tanaka T, Ishii R, Canuet L, Pascual-Marqui RD, et al. Functional connectivity assessed by resting state EEG\ncorrelates with cognitive decline of Alzheimer’s disease - An eLORETA study. Clinical Neurophysiology 2016; 127(2), 1269-\n1278.',
      line: 3,
    },
    {
      ref: 'T145',
      citation:
        'Oni-Orisan A, Kaushal M, Li W, Leschke J, Ward BD, Vedantam A, et al. Alterations in Cortical Sensorimotor Connectivity\nfollowing Complete Cervical Spinal Cord Injury: A Prospective Resting-State fMRI Study. PLoS One 2016; 11(3), 1-13.',
      line: 2,
    },
    {
      ref: 'T137',
      citation:
        'Schorr B, Schlee W, Arndt M, and Bender A. Coherence in resting-state EEG as a predictor for the recovery from unresponsive\nwakefulness syndrome. Journal of Neurology 2016; 263(5), 937-953.',
      line: 2,
    },
    {
      ref: 'T094',
      citation:
        'Robbie JC, Clarke AR, Barry RJ, Dupuy FE, Mccarthy R, and Selikowitz M. Coherence in children with AD/HD and excess alpha\npower in their EEG. Clinical Neurophysiology 2016; 127(5), 2161-2166.',
      line: 2,
    },
    {
      ref: 'T090',
      citation:
        'Saunders A, Kirk IJ, and Waldie KE. Hemispheric Coherence in ASD with and without Comorbid ADHD and Anxiety. BioMed\nResearch International 2016; 1-12.',
      line: 2,
    },
    {
      ref: 'T188',
      citation:
        'Tessitore, A., Giordano, A., De Micco, R., Caiazzo, G., Russo, A., Cirillo, M., Esposito, F., and Tedeschi, G. Functional connectivity\nunderpinnings of fatigue in "Drug-Naive" patients with Parkinson\'s disease. Movement Disorders 2016; 31(10), 1497-1505.',
      line: 2,
    },
    {
      ref: 'T186',
      citation:
        'Lin, H. Y., Tseng, W. Y., Lai, M. C., Matsuo, K. and Gau, S. S. Altered resting-state frontoparietal control network in children with\nattention-deficit/hyperactivity disorder. Journal of the International Neuropsychological Society 2015; 21, 271-284, doi:\n10.1017/S135561771500020X.',
      line: 3,
    },
    {
      ref: 'T168',
      citation:
        'Marzbani, H., Marateb, H. R., and Mansourian, M. Neurofeedback: a comprehensive review on system design, methodology and\nclinical applications. Basic and Clinical Neuroscience 2016; 7, 143-158.',
      line: 2,
    },
    {
      ref: 'T133',
      citation:
        'Malek N, Baker MR, Mann C, and Greene J. Electroencephalographic markers in dementia. Acta Neurologica Scandinavica 2016;\n135(4), 388-93.',
      line: 2,
    },
    {
      ref: 'T033',
      citation:
        'Gloss D, Varma JK, Pringsheim T, and Nuwer MR. Practice advisory: The utility of EEG theta/beta power ratio in ADHD diagnosis:\nTable. Neurology 2016; 87(22), 2375-2379.',
      line: 2,
    },
    {
      ref: 'T014',
      citation:
        'Lou, H. C., Henriksen, L., Bruhn, P., Borner, H., and Nielsen, J. B. Striatal Dysfunction in Attention Deficit and Hyperkinetic\nDisorder. Archives of Neurology 1989, 46(1), 48-52.',
      line: 2,
    },
  ],
  [
    {
      ref: 'T105',
      citation: `Imperatori C, Marca GD, Brunetti R, Carbone GA, Massullo C, Valenti EM, et al. Default Mode Network alterations in alexithymia:\nan EEG power spectra and connectivity study. Scientific Reports 2016; 6(1), 1-11.`,
      line: 2,
    },
    {
      ref: 'T209',
      citation: `Xing M, Tadayonnejad R, MacNamara A, Ajilore O, DiGangi J, Phan KL, Leow A, Klumpp H. Resting-state theta band connectivity\nand graph analysis in generalized social anxiety disorder. Neuroimage Clin. 2016; 12;13:24-32`,
      line: 2,
    },
    {
      ref: 'T103',
      citation: `Cozac VV, Chaturvedi M, Hatz F, Meyer A, Fuhr P, and Gschwandtner U. Increase of EEG Spectral Theta Power Indicates Higher\nRisk of the Development of Severe Cognitive Decline in Parkinson's Disease after 3 Years. Frontiers in Aging Neuroscience\n2016; 8, 1-7.`,
      line: 3,
    },
    {
      ref: 'T147',
      citation: `Adolph D. and Margraf J. The differential relationship between trait anxiety, depression, and resting frontal α-asymmetry.\nJournal of Neural Transmission 2017; 124(3), 379-386.`,
      line: 2,
    },
    {
      ref: 'T066',
      citation: `Park, J. H., Hong, J. S., Han, D. H., Min, K. J., Lee, Y. S., Kee, B. S., and Kim, S. M. Comparison of QEEG Findings between\nAdolescents with Attention Deficit Hyperactivity Disorder (ADHD) without Comorbidity and ADHD Comorbid with Internet\nGaming Disorder. Journal of Korean Medical Science 2017; 32(3), 514-521.`,
      line: 3,
    },
    {
      ref: 'T138',
      citation: `Berry AS, Sarter M, and Lustig C. Distinct Frontoparietal Networks Underlying Attentional Effort and Cognitive Control. Journal\nof Cognitive Neuroscience 2017; 29(7), 1212-1225.`,
      line: 2,
    },
    {
      ref: 'T211',
      citation: `Koelewijn L, Bompas A, Tales A, Brookes MJ, Muthukumaraswamy SD, Bayer A, Singh KD. Alzheimer's disease disrupts alpha and\nbeta-band resting-state oscillatory network connectivity. Clin Neurophysiol. 2017;128(11):2347-2357`,
      line: 2,
    },
    {
      ref: 'T199',
      citation: `Schutte I, Kenemans JL, Schutter DJLG. Resting-state theta/beta EEG ratio is associated with reward- and punishment-related\nreversal learning. Cogn Affect Behav Neurosci. 2017; 17(4): 754-763`,
      line: 2,
    },
    {
      ref: 'T216',
      citation: `Han Y, Wang K, Jia J, Wu W. Changes of EEG Spectra and Functional Connectivity during an Object-Location Memory Task in\nAlzheimer's Disease. Front Behav Neurosci. 2017; 31;11:107.`,
      line: 2,
    },
    {
      ref: 'T146',
      citation: `Park JE, Jung SC, Ryu KH, Oh JY, Kim H S, Choi C, .et al. Differences in dynamic and static functional connectivity between young\nand elderly healthy adults. Neuroradiology 2017; 59(8), 781-789.`,
      line: 2,
    },
    {
      ref: 'T156',
      citation: `Dickinson, A., DiStefano, C., Senturk, D., and Jeste, S. S. Peak alpha frequency is a neural marker of cognitive function across the\nautism spectrum. European Journal of Neuroscience 2017; 47(6):643-651.`,
      line: 2,
    },
    {
      ref: 'T206',
      citation: `Aminov A, Rogers JM, Johnstone SJ, Middleton S, Wilson PH. Acute single channel EEG predictors of cognitive function after\nstroke. PLoS One. 2017; 2;12(10):e0185841`,
      line: 2,
    },
    {
      ref: 'T202',
      citation: `Fahimi G, Tabatabaei SM, Fahimi E, Rajebi H. Index of Theta/Alpha Ratio of the Quantitative Electroencephalogram in Alzheimer's\nDisease: A Case-Control Study. Acta Med Iran. 2017; 55(8):502-506.`,
      line: 2,
    },
    {
      ref: 'T212',
      citation: `Whitton AE, Deccy S, Ironside ML, Kumar P, Beltzer M, Pizzagalli DA. Electroencephalography Source Functional Connectivity\nReveals Abnormal High-Frequency Communication Among Large-Scale Functional Networks in Depression. Biol Psychiatry Cogn\nNeurosci Neuroimaging. 2018;3(1):50-58`,
      line: 3,
    },
    {
      ref: 'T003',
      citation: `Feinberg I., Baker T., Leder R., and March J. Response of Delta (0-3 Hz) EEG and Eye Movement Density to a Night with 100\nMinutes of Sleep. Sleep 1988; 11(5), 473-487.`,
      line: 2,
    },
    {
      ref: 'T025',
      citation: `N, K. C. Computed Electroencephalographic Activity Mapping in Schizophrenia. Archives of General Psychiatry 1987; 44(6), 514.`,
    },
    {
      ref: 'T018',
      citation: `Tansey MA. EEG sensorimotor rhythm biofeedback training: some effects on the neurologic precursors of learning disabilities.\nInternational Journal of Psychophysiology 1984;1(2): 163-77.`,
      line: 2,
    },
    {
      ref: 'T048',
      citation: `Berry SD and Thompson RF. Prediction of learning rate from the hippocampal electroencephalogram. Science 1978; 200:1298-\n1300.`,
      line: 2,
    },
    {
      ref: 'T049',
      citation: `Winson J. Loss of hippocampal theta rhythm results in spatial memory deficit in the rat. Science 1978; 201:160-163.`,
    },
    {
      ref: 'T051',
      citation: `Mitchell SJ, Rawlins JN, Steward O, Olton DS. Medial septal area lesions disrupt theta rhythm and cholinergic staining in medial\nentorhinal cortex and produce impaired radial arm maze behavior in rats. Journal of Neuroscience 1982; 2:292-302.`,
      line: 2,
    },
    {
      ref: 'T009',
      citation: `Soininen, H., Partanen, V. J., Helkala, E., and Riekkinen, P. J. EEG findings in senile dementia and normal aging. Acta Neurologica\nScandinavica 1982; 65(1), 59-70.`,
      line: 2,
    },
    {
      ref: 'T050',
      citation: `Macrides F, Eichenbaum HB, and Forbes WB. Temporal relationship between sniffing and the limbic theta rhythm during odor\ndiscrimination reversal learning. Journal of Neuroscience 1982; 2:1705-1717.`,
      line: 2,
    },
    {
      ref: 'T039',
      citation: `Pascual-Marqui RD, Michel CM, Lehmann D. Low resolution electromagnetic tomography: a new method for localizing electrical\nactivity in the brain. International Journal of Psychophysiology 1994; 18:49-65.`,
      line: 2,
    },
    {
      ref: 'T152',
      citation: `Dierks T, Ihl R, Frolich L, and Maurer K. Dementia of the Alzheimer type: effects on the spontaneous EEG described by dipole\nsources. Psychiatry Research 1993; 50(3):51-162.`,
      line: 2,
    },
    {
      ref: 'T006',
      citation: `Chabot, R. J., Merkin, H., Wood, L. M., Davenport, T. L., and Serfontein, G. Sensitivity and Specificity of QEEG in Children with\nAttention Deficit or Specific Developmental Learning Disorders. Clinical EEG and Neuroscience 1996; 27(1), 26-34.`,
      line: 2,
    },
    {
      ref: 'T054',
      citation: `Klimesch W, Doppelmayr M, Russegger H, and Pachinger T. Theta band power in the human scalp EEG and the encoding of new\ninformation. Neuroreport 1996; 7:1235-1240.`,
      line: 2,
    },
    {
      ref: 'T207',
      citation: `Jelic V, Shigeta M, Julin P, Almkvist O, Winblad B, Wahlund LO. Quantitative electroencephalography power and coherence in\nAlzheimer's disease and mild cognitive impairment. Dementia. 1996;7(6):314-23.`,
      line: 2,
    },
    {
      ref: 'T059',
      citation: `Pfurtscheller G, Stanck A Jr, and Neuper C. Event-related synchronization (ERS) in the alpha band--an electrophysiological`,
    },
  ],
  [
    {
      ref: '',
      citation: `correlate of cortical idling: a review. International Journal of Psychophysiology 1996; 24:39-46.`,
    },
    {
      ref: 'T015',
      citation: `Tak Youn, and Jun Soo Kwon. Clinical Applications of Quantitative EEG. 수면-정신생리 1995; 2(1), 31-43.`,
    },
    {
      ref: 'T040',
      citation: `Kim MR, Kim KR, Ha CK, Choi SH, and Lee IK. Comparative Study between Visual Analysis and Low Resolution Electromagnetic\nTomography (LORETA) Method in the Localization of Epileptiform Discharges. Journal of the Korean Neurological Association\n2002; 20:164-168.`,
      line: 3,
    },
    {
      ref: 'T097',
      citation: `Hyeonjin Jeon and Seung-Hwan Lee. Electroencephalography of Learning and Memory. Korean Journal of Biological Psychiatry\n2016; 23(3), 102-107.`,
      line: 2,
    },
    {
      ref: 'T096',
      citation: `Eun-Kyoung Cho, Byung-Ok Choi, Yong-Jae Kim, Ki-Duck Park, Eung-Su Kim, and Kyoung-Gyu Choi. Quantitative EEG in de novo\nParkinson's Disease: Comparison with Normal Controls and Essential Tremor Patients with Nonlinear Analysis. Korean Society\nfor Clinical Neurophysiology 2006; 8(2), 135-145.`,
      line: 3,
    },
    {
      ref: 'T024',
      citation: `Sung Hoon Lee, Hyun Sook Kang, Eun Sun Han, and Ho Young Lee. The Hypofrontalism of Schizophrenics on the Topographic\nMapping od EEG. J Korean Neuropsychiatr Assoc 1991; 30(6), 982-989.`,
      line: 2,
    },
    {
      ref: 'T021',
      citation: `Pineda J, Brang D, Hecht E, Edwards L, Carey S, et al. Positive behavioral and electrophysiological changes following\nneurofeedback training in children with autism. Research in Autism Spectrum Disorders 2008; 2(3), 557-581.`,
      line: 2,
    },
    {
      ref: 'T022',
      citation: `Hoagland, H., Cameron, D. E., &Rubin, M. A. The Electrencephalogram Of Schizophrenics During Insulin Treatments. American\nJournal of Psychiatry 1937; 94(1), 183-208.`,
      line: 2,
    },
    {
      ref: 'T023',
      citation: `Small, J. G. EEG in Schizophrenia and affective disorder. EEG and Evoked Potentials in Psychiatry and Behavioral Neurology\n1983; 25-40.`,
      line: 2,
    },
    {
      ref: 'T026',
      citation: `Schatzberg, A. F., Elliott, G. R., Lerbinger, J. E., Marcel, B., and Duffy, F. H. Topographic Mapping in Depressed Patients.\nTopographic Mapping of Brain Electrical Activity 1986; 389-391.`,
      line: 2,
    },
    {
      ref: 'T038',
      citation: `Jung KY. Dipole source localization in EEG. Journal of Korean Epilepsy Society 2002; 119-122.`,
    },
    {
      ref: 'T043',
      citation: `Llins R, Urbano FJ, Leznik E, Ramrez RR, and van Marle HJ. Rhythmic and dysrhythmic thalamocortical dynamics: GABA systems\nand the edge effect. Trends in Neurosciences 2005; 28:325-333.`,
      line: 2,
    },
    {
      ref: 'T064',
      citation: `Demos JN. Getting started with neurofeedback. New York: W.W. Norton; 2005.`,
    },
    {
      ref: 'T065',
      citation: `Kim BN, Shin SU, Kwon JS, Shin MS, Cho SC, and Hong KE. QEEG findings in attention deficit/hyperactivity disorder. Journal of\nKorean Neuropsychiatric Association 2000; 39, 208-218.`,
      line: 2,
    },
    {
      ref: 'T068',
      citation: `Lubar JF, Swartwood MO, Swartwood JN, and Timmermann DL. Quantitative EEG and auditory event-related potentials in the\nevaluation of attention-deficit/hyperactivity disorder: effects of methylphenidate and implications for neurofeedback training.\nJournal of Psychoeducational Assessment 1995;143-160.`,
      line: 3,
    },
    {
      ref: 'T075',
      citation: `Yoo J. The effectiveness of neurofeedback training on the inattention &impulsivity of ADHD children [dissertation]. Busan:\nPusan National University; 2009.`,
      line: 2,
    },
    {
      ref: 'T076',
      citation: `Roh O, Park T, Park S, and Son C. The Effects of Neurofeedback Training on Inattention and Hyperactivity/Impulsivity in Children\nwith ADHD. Korean Journal of Clinical Psychology 2011; 30(2), 397-418.`,
      line: 2,
    },
    {
      ref: 'T077',
      citation: `Kwon MS. Effects of neuro-feedback training including behaviour contract with ADHD (Attention Deficit Hyperactivity Disorder)\n[dissertation]. Seoul: Kyung Hee University; 2012.`,
      line: 2,
    },
    {
      ref: 'T079',
      citation: `윤중수. 뇌파학 개론. 고려의학; 1999.`,
    },
    {
      ref: 'T080',
      citation: `정사준 옮김. 뇌파판독 STEP BY STEP(입문편) 제4판. 군자출판사; 2007.`,
    },
    {
      ref: 'T081',
      citation: `정사준 옮김. 뇌파판독 STEP BY STEP(증례편) 제4판. 군자출판사; 2007.`,
    },
    {
      ref: 'T082',
      citation: `양영철, 이양희. 뇌 맵핑 마인드. 말글빛냄; 2005.`,
    },
    {
      ref: 'T083',
      citation: `Caton, R. Electrical currents of the brain. Chicago Journal of Nervous &Mental Disease 1875; 2(4), 610.`,
    },
    {
      ref: 'T084',
      citation: `Berger, H. ber das Elektroenkephalogramm des Menschen. Archiv fr Psychiatrie und Nervenkrankheiten 1929; 87, 527-570.`,
    },
    {
      ref: 'T085',
      citation: `Birbaumer, N. et al. Clinical application of an EEG-based brain-computer interface: a case study in a patient with severe motor\nimpairment. Clinical Neurophysiology 2003; 114(3), 399-409.`,
      line: 2,
    },
    {
      ref: 'T086',
      citation: `Walker JE, Kozlowski GP, and Lawson R. A Modular Activation/Coherence Approach to Evaluating Clinical/QEEG Correlations and\nfor Guiding Neurofeedback Training: Modular Insufficiencies, Modular Excesses, Disconnections, and Hyperconnections. Journal\nof Neurotherapy 2007; 11(1), 25-44.`,
      line: 3,
    },
    {
      ref: 'T089',
      citation: `D. T. Stuss, M. P. Alexander, and D. F. Benson. “Frontal lobe functions,” in Contemporary Behavioral Neurology. Blue Books of\nPractical Neurology 1997; 169-187.`,
      line: 2,
    },
    {
      ref: 'T095',
      citation: `Benham, G., Rasey, H. W., Lubar, J. F., Frederick, J. A., and Zoffuto, A. C. EEG Power-Spectral and Coherence Differences\nBetween Attentional States during a Complex Auditory Task. Journal of Neurotherapy 1997; 2(3), 1-9.`,
      line: 2,
    },
  ],
]

const LIST_DISTENCES: number[] = [3.6, 3.2, 3.2, 3.3, 3.3, 3.2, 3.2, 3.6]
const Y_START = 23
const LINE_TEXT = 3

const TITLE: ElementPDF[] = [
  {
    type: 'text',
    content: 'IV. Reference',
    fontStyle: 'bold',
    fontSize: 11,
    x: 10,
    y: 23,
  },
  {
    type: 'fill',
    content: '',
    color: '#f4f4f4',
    x: 10,
    y: 28,
    x1: 190,
    y1: 12,
  },
  {
    type: 'line',
    content: '',
    color: '#d9d9d9',
    x: 10,
    y: 28,
  },
  {
    type: 'text',
    content: 'REFERENCE_NO',
    fontStyle: 'normal',
    color: '#595959',
    fontSize: 7.5,
    x: 11,
    y: 35,
  },
  {
    type: 'text',
    content: 'CITATION',
    fontStyle: 'normal',
    color: '#595959',
    fontSize: 7.5,
    x: 33,
    y: 35,
  },
]

const LINE_HEADER: ElementPDF[] = [
  {
    type: 'line',
    content: '',
    color: '#d9d9d9',
    x: 10,
    y: 17,
    lineHeight: 0.25,
  },
]

const LINE_BOTTOM: ElementPDF[] = [
  {
    type: 'line',
    content: '',
    color: '#d9d9d9',
    x: 10,
    y: 279,
    lineHeight: 0.25,
  },
]

const generateReference = (item: References, y: number) => {
  const yRef = (((item.line || 1) - 1) * LINE_TEXT) / 2 + y
  const result: ElementPDF[] = [
    {
      type: 'text',
      content: item.ref,
      fontStyle: 'normal',
      fontSize: 9,
      color: '#595959',
      uppercase: false,
      x: 10,
      y: yRef,
    },
    {
      type: 'text',
      content: item.citation,
      fontStyle: 'normal',
      fontSize: 8,
      color: '#595959',
      uppercase: false,
      x: 33,
      y,
    },
  ]
  return result
}

const pageReferencePdf = (item: References[], index: number): ElementPDF[] => {
  let data: ElementPDF[] =
    index === 0 ? [...TITLE, ...LINE_BOTTOM] : [...LINE_HEADER, ...LINE_BOTTOM]
  let y = index === 0 ? Y_START + 25 : Y_START
  for (let i = 0; i < item.length; i += 1) {
    data = [...data, ...generateReference(item[i], y)]
    y += LINE_TEXT * (item[i].line || 1) + LIST_DISTENCES[index]
  }
  return data
}

const generateReferencePdf = (listChecked: string[]): ElementPDF[][] => {
  if (!listChecked.includes(REFERENCE_ANALYSIS)) {
    return []
  }
  return LIST_REFERENCE.map((item, index) => pageReferencePdf(item, index))
}
export default generateReferencePdf
