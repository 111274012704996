import useTableToolbarStyles from 'components/Table/useTableToolbarStyles'
import {useLocation} from 'react-router-dom'
import {getQueryFromUrl} from 'helpers/commonHelper'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {convertTypeSearch} from 'helpers/treatmentHelper'
import RouteConstant from 'constants/RouteConstant'

interface SearchBarProps {
  onChangeValue: (value: string) => void
  onSearchEvent: () => void
  disabledValue: boolean
}

export default function SearchBar(props: SearchBarProps) {
  const classes = useTableToolbarStyles()
  const {t} = useTranslation()
  const location = useLocation()

  const {onChangeValue, onSearchEvent, disabledValue} = props

  const [eventChangeValue, setEventChangeValue] = React.useState('')
  const [searchKeyword, setSearchKeyword] = React.useState('')

  const onValueChange = (value: string) => {
    if (value != null) {
      onChangeValue(value)
    }
  }

  /** Set default value for search keyword if it is stored in url */
  useEffect(() => {
    const searchParams = getQueryFromUrl(location.search)
    if (searchParams?.search && searchParams.search.searchValue) {
      setSearchKeyword(searchParams.search.searchValue)
      onValueChange(searchParams.search.searchValue)
    }
    // Special For Client History Page
    if (searchParams?.searchValue) {
      setSearchKeyword(searchParams?.searchValue)
      onValueChange(searchParams?.searchValue)
    }
    // Special For customer Page
    if (searchParams?.search?.orgName) {
      setSearchKeyword(searchParams?.search?.orgName)
      onValueChange(searchParams?.search?.orgName)
    }
    // Special For Health center Page
    if (searchParams?.patientName) {
      setSearchKeyword(searchParams?.patientName)
      onValueChange(searchParams?.patientName)
    } else if (searchParams?.hcId) {
      setSearchKeyword(searchParams?.hcId)
      onValueChange(searchParams?.hcId)
    }
    // Special For Health center white list Page
    if (searchParams?.patientChartNo) {
      setSearchKeyword(searchParams?.patientChartNo)
      onValueChange(searchParams?.patientChartNo)
    } else if (searchParams?.patientVisitNo) {
      setSearchKeyword(searchParams?.patientVisitNo)
      onValueChange(searchParams?.patientVisitNo)
    } else if (searchParams?.birth) {
      setSearchKeyword(searchParams?.birth)
      onValueChange(searchParams?.birth)
    }
    // Special For PBM
    if (searchParams?.clientNamePBM) {
      setSearchKeyword(searchParams?.clientNamePBM)
      onValueChange(searchParams?.clientNamePBM)
    }
  }, [])

  /** Watch the search value change on the URL */
  useEffect(() => {
    const searchParams = getQueryFromUrl(location.search)
    // Special for dashboard treatment
    const searchData = convertTypeSearch(searchParams)
    if (
      searchData.searchName &&
      Object.prototype.hasOwnProperty.call(searchData, 'patientName') &&
      location.pathname === RouteConstant.HOME.path
    ) {
      setSearchKeyword(searchData.searchValue as string)
    }
  }, [location.search])

  return (
    <div className={classes.searchWrap}>
      <input
        placeholder={t('ISearch')}
        className={classes.searchInput}
        value={searchKeyword}
        onChange={(event) => {
          const value = event?.currentTarget?.value
          setSearchKeyword(value)
          onChangeValue(value)
          setEventChangeValue(value)
        }}
      />
      <span className={classes.searchDivider} />
      <button
        type='submit'
        disabled={disabledValue}
        onClick={() => {
          onValueChange(eventChangeValue)
          onSearchEvent()
        }}
        className={classes.searchButton}
      >
        {t('ISearch')}
      </button>
    </div>
  )
}
