import Checkbox from '@material-ui/core/Checkbox'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {downloadEdfApi, SearchedEDF} from 'api/analysisApi'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import Pagination from 'components/molcules/Pagination'
import PeriodSearch from 'components/molcules/PeriodSearch'
import TableCell from 'components/Table/TableCell'
import useStyles from 'components/Table/useTableStyles'
import useToolbarStyles from 'components/Table/useTableToolbarStyles'
import useEdf from 'features/analysis/useEdf'
import useFailureModal from 'features/modal/useFailureModal'
import {saveAs} from 'file-saver'
import {getQueryFromUrl, isDefined, zeroPad} from 'helpers/commonHelper'
import {
  dateToFileFormat,
  dateToPeriodString,
  fromIsoDateOrStringToLocalDate,
  getDefaultPeriodDate,
  isoStringToDateAndTimeString,
  toParsedDate,
} from 'helpers/dateHelper'
import JSZip from 'jszip'
// @ts-ignore
import JSZipUtils from 'jszip-utils'
import EdfSearchComponent from 'pages/Analysis/EDFDownload/EdfSearchComponent'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'

type DownloadSource = {baseDir: string; url: string; idx?: number}

type DownloadOutput = {
  baseDir: string
  fileName: string
  data: string | ArrayBuffer
}

const dateToDateString = (date: Date) => {
  const {years, months, days} = toParsedDate(date)
  return `${years}${zeroPad(months)}${zeroPad(days)}`
}

const dateToTimeString = (date: Date) => {
  const {hours, minutes, seconds} = toParsedDate(date)
  return `${zeroPad(hours)}${zeroPad(minutes)}${zeroPad(seconds)}`
}

const downloadMultiFiles = async (sources: DownloadSource[]) => {
  const promises = sources.map((source) =>
    // @ts-ignore
    JSZipUtils.getBinaryContent(source.url).then((data) => {
      const {url, idx, baseDir} = source

      let fileName = url.slice((url.lastIndexOf('/') ?? 0) + 1) ?? 'UNKNOWN.edf'
      fileName = fileName.replaceAll(' ', '__')

      if (idx !== undefined) {
        const [prefix, suffix] = fileName.split('.')
        fileName = `${prefix}(${idx}).${suffix}`
      }
      return {
        fileName,
        data,
        baseDir,
      }
    }),
  )

  const downloadResultList = await Promise.allSettled(promises)
  return downloadResultList
    .map((result) => {
      if (result.status === 'rejected') {
        return null
      }

      return result.value
    })
    .filter(isDefined)
}

const zipFiles = async (outputs: DownloadOutput[]) => {
  const zip = new JSZip()
  outputs.forEach((output) =>
    zip.file(`${output.baseDir}/${output.fileName}`, output.data, {
      binary: true,
    }),
  )
  const zipFile = await zip.generateAsync({type: 'blob'})
  saveAs(zipFile, dateToFileFormat(new Date()))
}

interface TableToolBarProps {
  selectedItems: SearchedEDF[]
}

function TableToolbar({selectedItems = []}: TableToolBarProps) {
  const location = useLocation()
  const {t} = useTranslation()

  const [loading, setLoading] = useState(false)

  const classes = useToolbarStyles()

  const {query, onSearch} = useEdf()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const selection = getDefaultPeriodDate()

  const handleSearch = (search: EdfSearchKind) =>
    onSearch({...query, search, paging: {page: 0, size: 10}})

  const handlePeriod = (period: PeriodDate) => {
    // ignore dumplicated call
    const formatedPeriod = dateToPeriodString(period)
    if (
      formatedPeriod.startDate === query.period.startDate &&
      formatedPeriod.endDate === query.period.endDate
    )
      return
    onSearch({
      ...query,
      paging: {page: 0, size: 10},
      period: dateToPeriodString(period),
    })
  }

  const handleDownloadClick = async () => {
    setLoading(true)
    try {
      const downloadTarget = selectedItems.map((d) => ({
        careId: d.careId,
        type: d.type,
      }))

      const response = await downloadEdfApi({careIds: downloadTarget})
      const fileUrls = response.list
        .map((file) => {
          const item = selectedItems.find((item) => item.careId === file.careId)
          if (item === undefined) {
            return undefined
          }

          const {chartNo, careDate} = item
          const localDate = fromIsoDateOrStringToLocalDate(careDate)
          const careDateString = dateToDateString(localDate)
          const careTimeString = dateToTimeString(localDate)
          const baseDir = `${careDateString}_${careTimeString}_${chartNo.replaceAll(
            ' ',
            '__',
          )}`

          const files: DownloadSource[] = []
          if (file.ecEdf !== null) {
            const hasOneMore = file.ecEdf.length > 1
            files.push(
              ...file.ecEdf.map((ec, idx) => ({
                url: ec,
                baseDir,
                idx: hasOneMore ? idx : undefined,
              })),
            )
          }
          if (file.eoEdf !== null) {
            const hasOneMore = file.eoEdf.length > 1
            files.push(
              ...file.eoEdf.map((eo, idx) => ({
                url: eo,
                baseDir,
                idx: hasOneMore ? idx : undefined,
              })),
            )
          }
          if (file.ppgEdf !== null) {
            files.push({url: file.ppgEdf, baseDir})
          }
          if (files.length === 0) return undefined

          return files
        })
        .filter(isDefined)
        .reduce((accr, curr) => [...accr, ...curr], [])

      const files = await downloadMultiFiles(fileUrls)
      await zipFiles(files)
    } catch (err) {
      onFailureModalOpen(err.message)
    } finally {
      setLoading(false)
    }
  }

  /** Set default value for search query if it is stored in url */
  useEffect(() => {
    const initQuery = getQueryFromUrl(location.search)
    onSearch({
      ...query,
      period: dateToPeriodString(selection),
      paging: {page: 0, size: 10},
      ...initQuery,
    })
  }, [])

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.container}>
          <PeriodSearch period={selection} onSearch={handlePeriod} />
          <EdfSearchComponent onSearch={handleSearch} />
        </div>
        <div className={classes.containerOptional}>
          <ActionButton
            onClick={handleDownloadClick}
            className={classes.flexRight}
            disabled={selectedItems.length === 0 || loading}
          >
            {t('IEdfDownload')}
          </ActionButton>
        </div>
      </div>
    </div>
  )
}

// type NameAccessor = (d: SearchedEDF) => string
// const nameGetter =
//   (lang: string) =>
//   (krAccessor: NameAccessor, enAccessor: NameAccessor) =>
//   (d: SearchedEDF) =>
//     lang === 'ko' ? krAccessor(d) : enAccessor(d)

interface RenderTableRowProps {
  item: SearchedEDF
  onClick: (item: SearchedEDF) => void
  isSelected: (item: SearchedEDF) => boolean
}

function RenderTableRow({item, onClick, isSelected}: RenderTableRowProps) {
  const {i18n} = useTranslation()

  const labelId = `enhanced-table-checkbox-${item.careId}`

  const checked = isSelected(item)

  const lang = i18n.language

  const handleClick = () => onClick(item)

  // const getDoctorName = useCallback(
  //   nameGetter(lang)(
  //     (d) => `${d.doctorLastName}${d.doctorFirstName}`,
  //     (d) => `${d.doctorFirstName} ${d.doctorLastName}`,
  //   ),
  //   [lang],
  // )

  return (
    <TableRow hover onClick={handleClick}>
      <TableCell padding='checkbox'>
        <Checkbox checked={checked} inputProps={{'aria-labelledby': labelId}} />
      </TableCell>
      {lang === 'ko' && (
        <TableCell align='center'>{`${item.doctorLastName} ${item.doctorFirstName}`}</TableCell>
      )}
      {lang === 'en' && (
        <TableCell align='center'>{`${item.doctorFirstName} ${item.doctorLastName}`}</TableCell>
      )}
      <TableCell align='center'>{item.chartNo}</TableCell>
      <TableCell align='center'>{item.birth}</TableCell>
      <TableCell align='center'>
        {isoStringToDateAndTimeString(item.careDate)}
      </TableCell>
    </TableRow>
  )
}

function PageTable() {
  const {t} = useTranslation()
  const classes = useStyles()

  const {loading, query, paging, pagingInfo, items, onSearch} = useEdf()

  const [selected, setSelected] = useState<SearchedEDF[]>([])

  const handlePageChanged = async (page: number) => {
    onSearch({
      ...query,
      paging: {
        page,
        size: paging.size,
      },
    })
    setSelected([])
  }

  const handlePageSizeChanged = async (
    event: React.ChangeEvent<{name?: string; value: unknown}>,
  ) => {
    onSearch({
      ...query,
      paging: {
        page: 0,
        size: parseInt(event.target.value as string, 10),
      },
    })
    setSelected([])
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(items)
      return
    }
    setSelected([])
  }

  const handleRowClick = (item: SearchedEDF) => {
    const selectedIndex = selected.indexOf(item)
    let newSelected: SearchedEDF[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  const isRowSelected = (item: SearchedEDF) => selected.indexOf(item) !== -1

  const emptyRows = items === null ? 5 : 5 - items.length

  return (
    <div>
      <TableContainer className={classes.tableContainer}>
        <TableToolbar selectedItems={selected} />
        <Table className={classes.table} size='small'>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell align='center' padding='none'>
                <Checkbox
                  indeterminate={
                    selected.length > 0 && selected.length < items.length
                  }
                  checked={
                    selected.length > 0 && selected.length === items.length
                  }
                  onChange={handleSelectAllClick}
                  inputProps={{'aria-label': 'select all desserts'}}
                />
              </TableCell>
              <TableCell align='center' padding='none'>
                {t('ICareDoctor')}
              </TableCell>
              <TableCell align='center' padding='none'>
                {t('IChartNo_no')}
              </TableCell>
              <TableCell align='center' padding='none'>
                {t('IBirthday')}
              </TableCell>
              {/* <TableCell align='center' padding='none'>
                {t('IPatientName')}
              </TableCell> */}
              <TableCell align='center' padding='none'>
                {t('ICareDate')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <RenderTableRow
                key={`${item.careId}_${item.type}_${item.status}`}
                item={item}
                onClick={handleRowClick}
                isSelected={isRowSelected}
              />
            ))}
            {emptyRows > 0 && (
              <TableRow
                style={{height: 43 * emptyRows, backgroundColor: '#F9F9FB'}}
              >
                <TableCell colSpan={12} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Pagination
          totalPageCount={pagingInfo.totalPages}
          currentPageIndex={query.paging.page ?? 0}
          itemCountPerPage={query.paging.size ?? 10}
          setCurrentPageIndex={handlePageChanged}
          onItemCountPerPageChanged={handlePageSizeChanged}
          loading={loading}
        />
      </TableContainer>
    </div>
  )
}

export default PageTable
