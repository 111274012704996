import personalData from 'components/molcules/GenerateReportPDF/PersonalData'
import {ReportInfoPropsPDF} from 'components/molcules/Report/ReportInfo'
import {
  isoStringToDateAndTimeString,
  parseAgeFromStrings,
} from 'helpers/dateHelper'

import {AnalysisResultItem} from 'api/analysisApi'
import {ElementPDF} from 'components/molcules/GenerateReportPDF/index'
import {
  getEyeStatus,
  getGenderStatus,
  getHandStatus,
} from 'helpers/analysisHelper'

export const getPersonalData = ({
  id,
  clientName,
  clientFirstName,
  clientLastName,
  gender,
  birth,
  measureDate,
  hand,
  diseaseCode,
  ecEo,
  t,
  screen,
}: ReportInfoPropsPDF) => {
  const age = parseAgeFromStrings(birth, measureDate)
  let titleIndividual = ''
  if (screen === 'EEG') {
    titleIndividual = t('IEEGIndividualIndex')
  } else if (screen === 'NORM') {
    titleIndividual = t('INORMIndividualIndex')
  }
  return [
    ...personalData(
      [
        {
          title: titleIndividual,
          value: id.toString(),
        },
        {
          title: t('ICustomerName'),
          value:
            clientFirstName || clientLastName
              ? `${clientFirstName} ${clientLastName}`
              : `${clientName}`,
        },
        {
          title: t('IGender'),
          value: gender ? getGenderStatus(gender) : '-',
        },
        {
          title: t('IAgeOfAnalysisTime'),
          value: `${birth}(${age === -1 ? '-' : age})`,
        },
        {
          title: t('IMeasurementDate'),
          value: `${
            isoStringToDateAndTimeString(measureDate ?? '').split(' ')[0]
          }`,
        },
        {
          title: 'EC/EO',
          value: getEyeStatus(ecEo || '-'),
        },
        {
          title: t('IHandedness'),
          value: getHandStatus(hand || '-'),
        },
        {
          title: t('IDiseaseCode'),
          value: diseaseCode || '-',
        },
      ],
      'EEG',
    ),
  ]
}

export interface RoiType {
  title: string
  img?: string
  description?: string
}

export interface IcaType {
  title: string
  title1?: string
  img?: string
  img1?: string
  description?: string
  titlePage?: string
  componentList?: string
}
export const getDataRaw = (
  data: AnalysisResultItem,
  index: number,
): ElementPDF[][] => [
  [
    {
      type: 'text',
      content: ` ${index}.Raw Data`,
      fontStyle: 'bold',
      fontSize: 13,
      uppercase: false,
      x: 10,
      y: 27,
      maxWidth: 190,
    },
    {
      type: 'text',
      content: data.titleDescription
        ? data.titleDescription.replace(/<[^>]*>/g, '')
        : '',
      fontStyle: 'normal',
      fontSize: 8,
      uppercase: false,
      x: 10,
      y: 32,
      maxWidth: 190,
      color: '#646464',
    },
    {
      type: 'image',
      content: data.imgPath ?? '',
      x: 10,
      y: 50,
      imageWidth: 190,
      imageHeight: 150,
    },
  ],
]
const generateImgIca = (y: number, url?: string) => {
  return {
    type: 'image',
    content: url ?? '',
    x: 10,
    y,
    imageWidth: 190,
    imageHeight: 80,
  }
}
const generateTitleIca = (
  fontStyle: string,
  fontSize: number,
  y: number,
  content?: string,
  color?: string,
  x?: number,
) => {
  return {
    type: 'text',
    content: content ?? '',
    fontStyle,
    fontSize,
    uppercase: false,
    color: color ?? '#000000',
    x: x ?? 10,
    y,
  }
}
const generateIca = (data: IcaType, index: number) => {
  if (index === 0) {
    return [
      generateTitleIca('bold', 13, 25, data.titlePage),
      {
        type: 'fill',
        color: '#f4f4f6',
        content: '',
        x: 10,
        y: 44,
        x1: 50,
        y1: 8,
      },
      generateTitleIca('normal', 8, 30, data.description, '#646464'),
      generateTitleIca('normal', 11, 49, 'Artifact Component No.', '#414042'),
      generateTitleIca('normal', 11, 49, data.componentList, '#414042', 60),
      {
        type: 'line',
        color: '#eeeeee',
        content: '',
        x: 10,
        y: 44,
        x1: 200,
        y1: 44,
      },
      {
        type: 'line',
        color: '#eeeeee',
        content: '',
        x: 10,
        y: 52,
        x1: 200,
        y1: 52,
      },
      generateTitleIca('bold', 11, 60, data.title, '#414042'),
      generateImgIca(62, data.img),
      generateTitleIca('bold', 11, 160, data.title),

      generateImgIca(162, data.img1),
    ] as ElementPDF[]
  }
  return [
    generateTitleIca('bold', 11, 28, data.title),
    generateImgIca(33, data.img),
    generateTitleIca('bold', 11, 132, data.title1),
    generateImgIca(137, data.img1),
  ] as ElementPDF[]
}
export const getICAComponent = (data: IcaType[]) => {
  let list: ElementPDF[][] = []
  for (let i = 0; i < data.length; i += 1) {
    list = [...list, generateIca(data[i], i)]
  }
  return list
}

export const generateBandPower = (data: RoiType, y: number) => {
  return [
    {
      type: 'image',
      content: data.img,
      x: 10,
      y,
      imageHeight: 220,
    },
    {
      content: data.title,
      type: 'text',
      fontStyle: 'bold',
      fontSize: 11,
      x: 10,
      y,
      maxWidth: 190,
      uppercase: false,
    },
  ] as ElementPDF[]
}

export const getBandPower = (
  items: RoiType[],
  index: number,
  description: string,
) => {
  let list: ElementPDF[][] = []
  for (let i = 0; i < items.length; i += 1) {
    const y = i === 0 ? 50 : 30
    list = [...list, generateBandPower(items[i], y)]
  }
  const header: ElementPDF[] = [
    {
      type: 'text',
      content: `${index}. Band power – Topomap`,
      fontStyle: 'bold',
      fontSize: 13,
      uppercase: false,
      x: 10,
      y: 20,
    },
    {
      content: description,
      type: 'text',
      x: 10,
      y: 24,
      fontStyle: 'normal',
      fontSize: 8,
      uppercase: false,
      maxWidth: 190,
      color: '#646464',
    },
  ]
  list[0] = [...list[0], ...header]
  return list
}

export const getSpectrum = (
  items: AnalysisResultItem[],
  index: number,
): ElementPDF[][] => [
  [
    {
      content: `${index}.Power spectrum`,
      type: 'text',
      fontStyle: 'bold',
      fontSize: 14,
      x: 10,
      y: 25,
      maxWidth: 190,
      uppercase: false,
    },
    {
      content: items[0].titleDescription ? items[0].titleDescription : '',
      type: 'text',
      x: 10,
      y: 30,
      fontStyle: 'normal',
      fontSize: 8,
      uppercase: false,
      maxWidth: 190,
      color: '#646464',
    },
    {
      content: `${index}-1 Absolute (μV2/Hz)`,
      type: 'text',
      fontStyle: 'bold',
      fontSize: 11,
      x: 10,
      y: 46,
      maxWidth: 190,
      uppercase: false,
    },
    {
      type: 'image',
      content: items[0].imgPath ?? '',
      x: 10,
      y: 54,
      imageHeight: 200,
    },
  ],

  [
    {
      content: `${index}-2 Absolute (dB/Hz)`,
      type: 'text',
      fontStyle: 'bold',
      fontSize: 11,
      x: 10,
      y: 25,
      maxWidth: 190,
      uppercase: false,
    },
    {
      type: 'image',
      content: items[1].imgPath ?? '',
      x: 10,
      y: 28,
      imageHeight: 200,
    },
  ],
  [
    {
      content: `${index}-3 Relative`,
      type: 'text',
      fontStyle: 'bold',
      fontSize: 11,
      x: 10,
      y: 25,
      maxWidth: 190,
      uppercase: false,
    },
    {
      type: 'image',
      content: items[2].imgPath ?? '',
      x: 10,
      y: 28,
      imageHeight: 200,
    },
  ],
]

export const getOccipital = (
  title: string | undefined,
  item: AnalysisResultItem[] | undefined,
  index: number,
): ElementPDF[][] => [
  [
    {
      content: `${index}. Occipital alpha peak`,
      type: 'text',
      fontStyle: 'bold',
      fontSize: 13,
      x: 10,
      y: 23,
      maxWidth: 190,
    },
    {
      content: title
        ? title
            .split('///')[0]
            .replace(/<br\s*\/?>/gi, '\n')
            .replace(/<[^>]*>/g, '')
        : '',
      type: 'text',
      fontStyle: 'normal',
      color: '#646464',
      fontSize: 8,
      x: 10,
      y: 27,
      maxWidth: 190,
    },
    {
      type: 'image',
      content: item ? item[0].imgPath : '',
      x: 10,
      y: 70,
      imageHeight: 130,
    },
  ],
]

export const getRatio = (
  title: string | undefined,
  item: AnalysisResultItem[] | undefined,
  index: number,
): ElementPDF[][] => [
  [
    {
      content: `${index}. Power ratio`,
      type: 'text',
      fontStyle: 'bold',
      fontSize: 13,
      x: 10,
      y: 23,
      maxWidth: 190,
    },
    {
      content: title
        ? title.replace(/<br\s*\/?>/gi, '\n').replace(/<[^>]*>/g, '')
        : '',
      type: 'text',
      fontStyle: 'normal',
      color: '#646464',
      fontSize: 8,
      x: 10,
      y: 28,
      maxWidth: 190,
    },
    {
      type: 'image',
      content: item ? item[0].imgPath : '',
      x: 10,
      y: 130,
      imageHeight: 70,
    },
  ],
]
const generateRoi = (item: RoiType, i: number, index: number) => {
  if (i === 0) {
    return [
      {
        content: `${index}. Source ROI power (sLORETA) &connectivity (iCoh)`,
        type: 'text',
        fontStyle: 'bold',
        fontSize: 13,
        x: 10,
        y: 23,
        maxWidth: 190,
      },
      {
        content: item.title,
        type: 'text',
        fontStyle: 'bold',
        fontSize: 13,
        x: 10,
        y: 28,
        maxWidth: 190,
      },
      {
        content: item.description
          ? item.description
              .split('///')[0]
              .replace(/<br\s*\/?>/gi, '\n')
              .replace(/<[^>]*>/g, '')
          : '',
        type: 'text',
        fontStyle: 'normal',
        color: '#595959',
        fontSize: 8,
        x: 10,
        y: 32,
        maxWidth: 190,
      },
    ] as ElementPDF[]
  }
  if (item.description) {
    return [
      {
        content: item.title,
        type: 'text',
        fontStyle: 'bold',
        fontSize: 13,
        x: 10,
        y: 23,
        maxWidth: 190,
      },
      {
        content: item.description,
        type: 'text',
        fontStyle: 'normal',
        color: '#595959',
        fontSize: 8,
        x: 10,
        y: 27,
        maxWidth: 190,
      },
    ] as ElementPDF[]
  }
  return [
    {
      content: item.title,
      type: 'text',
      fontStyle: 'bold',
      fontSize: 13,
      x: 10,
      y: 23,
      maxWidth: 190,
    },
    {
      type: 'image',
      content: item.img,
      x: 10,
      y: 27,
      imageHeight: 160,
    },
  ] as ElementPDF[]
}
export const getRoi = (data: RoiType[], index: number) => {
  let list: ElementPDF[][] = []
  for (let i = 0; i < data.length; i += 1) {
    list = [...list, generateRoi(data[i], i, index)]
  }
  return list
}
