import React, {useEffect, useState} from 'react'
import {useAsync} from 'react-async-hook'
import {useTranslation} from 'react-i18next'
import {CircularProgress} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import ReplayIcon from '@material-ui/icons/Replay'
import CheckBox from 'components/Checkbox'
import PdfDownload, {ElementPDF} from 'components/molcules/GenerateReportPDF'
import {getEyeStatus, getGenderStatus} from 'helpers/analysisHelper'
import {
  AnalysisUserInfo,
  AnalysisResultInfo,
  fetchIndividualEEGResult,
  FetchIndividualEEGResultRequest,
  TypeScreenGraph,
  AnalysisResultItem,
} from 'api/analysisApi'
import {
  AnalysisDivision,
  CodeType,
  CodeTypeMap,
  ListDownloadSelectPdf,
  ReportAnalysisTypeMap,
} from 'constants/AnalysisConstant'
import CardWithTitle from 'components/atoms/CardWithTitle'
import ReportInfo, {
  ReportInfoProps,
} from 'components/molcules/Report/ReportInfo'
import ReportItem, {
  RenderingItem,
} from 'pages/Analysis/EEGIndividual/Report/ReportItem'
import generateTableOfContents from 'components/molcules/GenerateReportPDF/TableOfContents'
import getContentEEGIndividualPDF, {
  listCommonDownloadPdf,
  listAdditionalDownloadPdf,
  listFooterDownloadIndividualPdf,
} from 'components/molcules/GenerateReportPDF/EEGReport/helper'
import {
  isoStringToDateAndTimeString,
  parseAgeFromStrings,
} from 'helpers/dateHelper'
import barChart from 'assets/img/PDF/bar-chart.svg'
import {
  SelectContainer,
  ListContainer,
  Header,
} from 'components/molcules/GenerateReportPDF/styles'

interface ReportProps {
  readonly id: number
  readonly version: string
  readonly clientName?: string | null
  readonly screen: TypeScreenGraph
}

/**
 * ICA IcaReport
 *
 * - progress가 SUCCESS -> 결과지
 * - progress가 ICA -> Ica 페이지
 * - progress가 TSR -> Timeseries-Rejection 페이지
 */
function ReportPage({id, version, clientName, screen}: ReportProps) {
  const {t, i18n} = useTranslation()

  const codeTypeDivision: AnalysisDivision = 'EEG'
  const codeType: CodeType = CodeTypeMap.Individual
  const locale = i18n.language

  const params: FetchIndividualEEGResultRequest = {
    individualRequestIds: [id],
    codeTypeDivision,
    codeType,
    codeVersion: version ?? '3.0',
    locale,
  }
  const asyncResult = useAsync(fetchIndividualEEGResult, [params], {
    executeOnMount: true,
    executeOnUpdate: false,
  })

  const [renderingItems, setRenderingItems] = useState<RenderingItem[]>()
  const [resultInfo, setResultInfo] = useState<AnalysisResultInfo>()
  const [userInfo, setUserInfo] = useState<AnalysisUserInfo>({
    name: '',
    chartNo: '',
    birth: '',
    measureDate: '',
    gender: '',
    ecEo: '',
    id: '',
  })
  const [listChecked, setListChecked] = useState<string[]>([])
  const [contentPage, setContentPage] = useState<any>()
  const [componentList, setComponentList] = useState<number[]>([])
  const [listSelectSpectrum, setListSelectSpectrum] = useState<
    AnalysisResultItem[]
  >([])
  const [checkListDownloadIndividualPdf, setCheckListDownloadIndividualPdf] =
    useState<ListDownloadSelectPdf[]>([])

  const handleCheckAll = () => {
    const listId = checkListDownloadIndividualPdf
      .filter((item) => !item.disable)
      .map((item) => {
        return item.id
      })
    if (listChecked.length === listId.length) {
      setListChecked([])
    } else {
      setListChecked([...listId])
    }
  }
  const handleCheckItem = (id: string) => {
    let listId = []
    if (listChecked.includes(id)) {
      listId = listChecked.filter((item) => item !== id)
    } else {
      listId = [...listChecked, id]
    }
    setListChecked([...listId])
  }

  useEffect(() => {
    if (asyncResult.result?.list) {
      const [result] = asyncResult.result.list
      const {codeList, resultDetailList} = result
      const sortedCodeList = codeList.sort((code: any) => code.codeSort)
      const renderingItems: RenderingItem[] = sortedCodeList.map(
        (code: any) => {
          const items = resultDetailList.filter(
            (item: any) => item.analysisType === code.codeId,
          )
          return [code, items]
        },
      )

      const dataUser: ReportInfoProps | undefined = resultInfo
      if (dataUser) dataUser.clientName = clientName
      const contentPDF: ElementPDF[][] = getContentEEGIndividualPDF(
        listChecked,
        renderingItems,
        dataUser,
        componentList,
        'EEG',
        listSelectSpectrum,
        t,
      )

      setContentPage([
        generateTableOfContents(listChecked, 'EEG'),
        ...contentPDF,
      ])
    }
  }, [listChecked, listSelectSpectrum, resultInfo])
  const getListSelectSpectrum = (item: AnalysisResultItem[]) => {
    setListSelectSpectrum([...item])
  }
  useEffect(() => {
    if (asyncResult.result?.list) {
      const [result] = asyncResult.result.list
      const {codeList, resultDetailList, resultInfo, componentList} = result
      const sortedCodeList = codeList.sort((code: any) => code.codeSort)
      const renderingItems: RenderingItem[] = sortedCodeList.map(
        (code: any) => {
          const items = resultDetailList.filter(
            (item: any) => item.analysisType === code.codeId,
          )
          return [code, items]
        },
      )
      const sourceRoi = renderingItems.find(
        (item: any) => item[0].codeId === ReportAnalysisTypeMap.SourceROIPower,
      )

      if (sourceRoi?.[1]?.length === 0) {
        setCheckListDownloadIndividualPdf([
          ...listCommonDownloadPdf,
          ...listFooterDownloadIndividualPdf,
        ])
      } else {
        setCheckListDownloadIndividualPdf([
          ...listCommonDownloadPdf,
          ...listAdditionalDownloadPdf,
          ...listFooterDownloadIndividualPdf,
        ])
      }
      // get data for backdrop of pdf
      const age = parseAgeFromStrings(resultInfo.birth, resultInfo.measureDate)
      const userInfo: AnalysisUserInfo = {
        name: clientName ?? '',
        chartNo: resultInfo.chartNo ?? '',
        birth: `${resultInfo.birth}(${age === -1 ? '-' : age})`,
        measureDate: isoStringToDateAndTimeString(
          resultInfo.measureDate ?? '',
        ).split(' ')[0],
        gender: resultInfo.gender ? getGenderStatus(resultInfo.gender) : '',
        ecEo: resultInfo.ecEo ? getEyeStatus(resultInfo.ecEo || '-') : '',
        id: resultInfo.id,
      }
      setUserInfo(userInfo)
      setRenderingItems(renderingItems)
      setResultInfo(resultInfo)
      setComponentList(componentList)
    }
  }, [asyncResult.result])

  useEffect(() => {
    asyncResult.execute(params)
  }, [locale])

  return (
    <CardWithTitle title={t('IEEGIndividualReportVersion', {version})}>
      {/* height - header - footer - CardHeader & border - margin */}
      <div
        style={{
          minHeight: 'calc(100vh - 91px - 77px - 77px - 15px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {asyncResult.loading && (
          <CircularProgress size='3rem' color='primary' />
        )}
        {asyncResult.error && (
          <div>
            {asyncResult.error.message}
            <IconButton
              onClick={() => asyncResult.execute(params)}
              size='small'
            >
              <ReplayIcon />
            </IconButton>
          </div>
        )}
        <div style={{width: '100%'}}>
          <div id='download-pdf'>
            <PdfDownload
              screen='EEG'
              userInfo={userInfo}
              contentPage={contentPage}
              listChecked={listChecked}
            >
              <Header>
                <img src={barChart} alt='' />
                <span>EEG</span>
              </Header>
              <SelectContainer>
                <CheckBox
                  onChange={handleCheckAll}
                  checked={
                    checkListDownloadIndividualPdf.filter(
                      (item) => !item.disable,
                    ).length === listChecked.length
                  }
                >
                  {t('ISelectAll')}
                </CheckBox>
                <ListContainer>
                  {checkListDownloadIndividualPdf.map((item) => (
                    <CheckBox
                      checked={listChecked.includes(item.id) && !item.disable}
                      onChange={() => handleCheckItem(item.id)}
                      disabled={item.disable}
                    >
                      {item.label}
                    </CheckBox>
                  ))}
                </ListContainer>
              </SelectContainer>
            </PdfDownload>
          </div>
          {resultInfo !== undefined && (
            <ReportInfo {...resultInfo} clientName={clientName} eeg />
          )}
          {renderingItems !== undefined &&
            renderingItems.map((item, index) => (
              <ReportItem
                key={index}
                renderingItem={item}
                requestId={id}
                codeType={codeType}
                codeTypeDivision={codeTypeDivision}
                componentList={componentList}
                screen={screen}
                selectedBand={getListSelectSpectrum}
              />
            ))}
        </div>
      </div>
    </CardWithTitle>
  )
}

export default ReportPage
