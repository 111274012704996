import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
  IndividualSummarySearchRequest,
  SearchedIndividualSummary,
  searchIndividualSummaryApi,
} from 'api/analysisApi'
import {openFailureModalAction} from 'features/modal/alertSlice'
import {getDefaultPeriodString, periodStringToDate} from 'helpers/dateHelper'
import {RootState} from 'store'

export const searchEegSummary = createAsyncThunk(
  'api/eeg/summary/search',
  async (
    payload: IndividualSummarySearchRequest,
    {rejectWithValue, dispatch},
  ) => {
    try {
      const response = await searchIndividualSummaryApi(payload)
      return response.data
    } catch (err) {
      await dispatch(openFailureModalAction(err.message))
      return rejectWithValue(err.message)
    }
  },
)

interface EegSummaryState {
  loading: boolean
  error: any
  items: SearchedIndividualSummary[]
  period: PeriodString
  search: AnalysisSearchKind
  paging: Paging
  pagingInfo: {
    totalPages: number
    totalElements: number
  }
}

const initialState: EegSummaryState = {
  loading: false,
  error: null,
  items: [],
  period: getDefaultPeriodString(),
  search: {searchName: 'NAME', searchValue: ''},
  paging: {page: 0, size: 10},
  pagingInfo: {
    totalPages: 1,
    totalElements: 0,
  },
}

const slice = createSlice({
  name: 'eegSummarySlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(searchEegSummary.pending, (state, action) => {
      state.loading = true
      state.error = null

      const {period, search, paging} = action.meta.arg
      state.period = period
      state.search = search
      state.paging = paging
    })
    builder.addCase(searchEegSummary.fulfilled, (state, action) => {
      state.loading = false

      const {
        content: items,
        pageable: {pageNumber: page},
        size,
        totalPages,
        totalElements,
      } = action.payload
      state.items = items
      state.loading = false
      state.error = null
      state.paging.page = page
      state.paging.size = size
      state.pagingInfo.totalPages = totalPages
      state.pagingInfo.totalElements = totalElements
    })
    builder.addCase(searchEegSummary.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export default slice.reducer

export const selectLoading = (state: RootState) =>
  state.individualSummary.loading
export const selectError = (state: RootState) => state.individualSummary.error
export const selectItems = (state: RootState) => state.individualSummary.items
export const selectPeriod = (state: RootState) =>
  periodStringToDate(state.individualSummary.period)
export const selectSearch = (state: RootState) => state.individualSummary.search
export const selectPaging = (state: RootState) => state.individualSummary.paging
export const selectPagingInfo = (state: RootState) =>
  state.individualSummary.pagingInfo

export const selectQuery = (state: RootState) => ({
  period: state.individualSummary.period,
  search: state.individualSummary.search,
  paging: state.individualSummary.paging,
})
