import {jsPDF} from 'jspdf'
import fontRegular from 'components/molcules/GenerateReportPDF/NotoSansKRRegular'
import fontBold from 'components/molcules/GenerateReportPDF/NotoSansKRBold'
import fontMedium from 'components/molcules/GenerateReportPDF/NotoSansKRMedium'

const geJsPDF = () => {
  // eslint-disable-next-line new-cap
  const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'mm',
    format: 'a4',
    compress: true,
  })
  // Font Regular (weight 400)
  pdf.addFileToVFS('NotoSansKR-Regular.ttf', fontRegular)
  pdf.addFont('NotoSansKR-Regular.ttf', 'NotoSansKR', 'normal')

  // Font Medium (weight 500)
  pdf.addFileToVFS('NotoSansKR-Medium.ttf', fontMedium)
  pdf.addFont('NotoSansKR-Medium.ttf', 'NotoSansKR', 'medium')

  // Font Bold (weight 700)
  pdf.addFileToVFS('NotoSansKR-Bold.ttf', fontBold)
  pdf.addFont('NotoSansKR-Bold.ttf', 'NotoSansKR', 'bold')
  return pdf
}

export default geJsPDF
