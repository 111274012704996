import {ElementPDF} from 'components/molcules/GenerateReportPDF/index'
import {
  ReportAnalysisTypeMap,
  OVERVIEW_ANALYSIS,
} from 'constants/AnalysisConstant'
import channelLocations from 'assets/img/PDF/channel_locations.webp'
import timeSeries from 'assets/img/PDF/time-series.webp'
import componentData from 'assets/img/PDF/component_data.webp'
import topomapEEG from 'assets/img/PDF/topomap_eeg.webp'
import topomapNorm from 'assets/img/PDF/topomap_norm.webp'
import topomapAbsoluteEeg from 'assets/img/PDF/topomap_absolute_eeg.webp'
import topomapAbsoluteNorm from 'assets/img/PDF/topomap_absolute_norm.webp'
import topomapRelativeEeg from 'assets/img/PDF/topomap_relative_eeg.webp'
import topomapRelativeNorm from 'assets/img/PDF/topomap_relative_norm.webp'
import powerSpectrumDynamicEEG from 'assets/img/PDF/power_spectrum_dynamic_eeg.webp'
import powerSpectrumDynamicNorm from 'assets/img/PDF/power_spectrum_dynamic_norm.webp'
import powerSpectrumEeg from 'assets/img/PDF/power_spectrum_eeg.webp'
import powerSpectrumNorm from 'assets/img/PDF/power_spectrum_norm.webp'
import occipitalEeg from 'assets/img/PDF/occipital_eeg.webp'
import occipitalNorm from 'assets/img/PDF/occipital_norm.webp'
import powerRatioEeg from 'assets/img/PDF/power_ratio_eeg.webp'
import powerRatioNorm from 'assets/img/PDF/power_ratio_norm.webp'
import circularMapAll from 'assets/img/PDF/circular_map_all.webp'
import circularMapAllEEG from 'assets/img/PDF/circular_map_all_eeg.webp'

type ListDefaultModeType = {
  title: string
  line?: number
  space?: boolean
}

const listDefaultModeNetwork: ListDefaultModeType[] = [
  {title: 'Table 1. Summary of clinical significance of the DMN'},
  {title: 'Alzheimer’s disease (AD)', line: 2},
  {
    title:
      'Decreased functional connectivity between posterior and anterior regions (T190).',
  },
  {
    title:
      'Correlations of degeneration of neural pathway between the DMN and amyloid deposits (T182).',
  },
  {
    title: 'Attention deficit hyperactivity disorder (ADHD)',
    line: 2,
  },
  {
    title: 'Coordinated activity of striatum and the DMN (T192).',
  },
  {
    title:
      'Network disruptions in the DMN – dysfunctional connectivity (T193).',
  },
  {
    title: 'Mood disorders',
    line: 2,
  },
  {
    title:
      'Correlation between DMN activation and impaired task performance (T194).',
  },
  {
    title:
      'Negatively correlated between the cognitive control network and the DMN (T195).',
  },
  {
    title: 'Parkinson’s disease (PD)',
    line: 2,
  },
  {
    title: 'Dysfunction connectivity in the DMN and rumination (T196).',
  },
  {
    title:
      'Correlation between the duration and number of the depressive episodes and disrupted DMN connectivity (T197).',
  },
]
const listTitleRatio: ListDefaultModeType[] = [
  {
    title: '(1)Theta/beta ratio',
  },
  {
    title:
      'The theta/beta ratio (TBR) is the ratio of theta (4 - 8 Hz) to beta (13 - 21 Hz) power during the resting conditions. It tends to reflect attention-related functions (T091)(T219).',
    line: 2,
    space: true,
  },
  {
    title: 'Clinical significance',
  },
  {
    title:
      '-Increased TBR is associated with increased reward responsiveness, risk taking and impulsiveness and it tends to reflect attentional control functioning and behavioral inhibition process (T180).',
    line: 2,
  },
  {
    title:
      '-The majority of children with ADHD is characterized by increased theta activity and decreased beta activity coupled, and thus a higher TBR(T011).',
    line: 2,
    space: true,
  },
  {
    title: '(2)Theta/alpha ratio',
  },
  {
    title:
      'The theta/alpha ratio (TAR) is the ratio of theta (4 - 8 Hz) to alpha (8 - 12 Hz) relative power during the resting conditions and reflects cognitive ability, especially learning and memory-related functions.',
    line: 2,
    space: true,
  },
  {
    title: 'Clinical significance',
  },
  {
    title:
      '-Increased TAR is associated with decreased cognitive ability (T207).',
  },
  {
    title:
      '-The majority of older adults with amnestic mild cognitive impairment (aMCI) and probable Alzheimer`s disease (AD) is characterized by increased theta activity and decreased alpha activity coupled (T200)(T201)(T202).',
    line: 2,
    space: true,
  },
  {
    title: '(3)Delta/alpha ratio',
  },
  {
    title:
      'The delta/alpha ratio (DAR) is the ratio of delta (1 - 4 Hz) to alpha (8 - 12 Hz) relative power during the resting conditions and associated with cognitive deficit and functional outcome after stroke.',
    line: 2,
    space: true,
  },
  {
    title: 'Clinical significance',
  },
  {
    title:
      '-Increased DAR is associated with cognitive impairment for post stroke in frontal lobe (T204).',
  },
  {
    title:
      '-The majority of patients with stroke is characterized by increased delta activity and decreased alpha activity coupled (T204)(T205)(T206).',
  },
]
const listTitleOccipital: ListDefaultModeType[] = [
  {
    title:
      'The occipital alpha frequency is the frequency at which oscillations in the alpha range (8 - 12 Hz) in the occipital regions present their maximum power. This feature shows the percentiles of peak amplitude and frequency compared with the normative EEG database in occipital regions. Alpha frequency may vary markedly to a large extent as a function of age, neurological disease status, memory performance, cognitive processing, and other factors (T179).',
    line: 4,
    space: true,
  },
  {
    title: 'Clinical significance',
  },
  {
    title:
      '-Resting state alpha rhythms show decreased amplitude in both Alzheimer`s disease and mild cognitive disorder, compared with normal elderly subjects (T148)(T149)(T150)(T151)(T152)(T153)(T154)(T155).',
    line: 2,
  },
  {
    title:
      '-Decreased alpha frequency is known to reflect neurological diseases such as dementia, Alzheimer`s disease, brain injuries, and age-related cognitive performances (T157)(T158)(T159)',
    line: 2,
  },
  {
    title:
      '-Occipital alpha peak frequency is decreased in autism spectrum disorder (ASD) and furthermore, it is strongly correlated with non-verbal cognition within ASD group (T156).',
    line: 2,
  },
]
const listTitleElectroencephalography: ListDefaultModeType[] = [
  {
    title:
      '- EEG data is a record of the oscillations of electrical brain potential recorded from electrodes on the human scalp.',
  },
  {
    title:
      '- The electrodes to record EEG signals are located per the standardized measuring point of the international 10-20 system. In the case of using 19 electrodes (Fp1, Fp2, F7, F3, Fz, F4, F8, T3, C3, Cz, C4, T4, T5, P3, Pz, P4, T6, O1, O2), the electrodes are attached as the figure shown below.',
    line: 2,
  },
  {
    title:
      '- EEG signals consist of various frequency components. In order to investigate EEG characteristics, frequency domain analysis, more specifically frequency band analysis, is widely used.',
    line: 2,
  },
  {
    title:
      '- The frequency bands used in the analysis are delta (δ, 1 – 4 Hz), theta (θ, 4 – 8 Hz), alpha1 (α1, 8 – 10 Hz) , alpha2 (α2, 10 – 12 Hz), beta1 (β1, 12–15 Hz), beta2 (β2, 15 – 20 Hz), beta3 (β3, 20 – 30 Hz) and gamma (γ, 30 – 45 Hz).',
    line: 2,
  },
]
const electroencephalography = (): ElementPDF[] => [
  {
    type: 'text',
    content: 'III. Overview',
    fontStyle: 'bold',
    fontSize: 12,
    uppercase: false,
    x: 10,
    y: 23,
  },
  {
    type: 'text',
    content: 'I Electroencephalography (EEG)',
    fontStyle: 'bold',
    fontSize: 12,
    uppercase: false,
    x: 10,
    y: 33,
  },

  {
    type: 'image',
    content: channelLocations,
    x: 10,
    y: 70,
    imageHeight: 170,
  },
]

const rawData = (): ElementPDF[][] => [
  [
    {
      type: 'text',
      content: 'Raw Data',
      fontStyle: 'bold',
      fontSize: 12,
      uppercase: false,
      x: 10,
      y: 23,
    },

    {
      type: 'text',
      content:
        'EEG data is a record of the oscillations of electrical brain potentials recorded from electrodes on the human scalp (T100)(T101) (T005). The raw data in the figure below have been cleaned by the application of high-pass and low-pass filers. This markedly reduces distortions (artifacts) from common physiological sources such as face or eye muscle movements, as well as extrinsic non-physiological sources such as electrical devices, power lines, poor electrode contact.',
      fontStyle: 'normal',
      fontSize: 8,
      uppercase: false,
      color: '#595959',
      x: 10,
      y: 30,
    },
    {
      type: 'image',
      content: timeSeries,
      x: 10,
      y: 45,
      imageHeight: 180,
    },
  ],
]

const icaComponent = (): ElementPDF[][] => [
  [
    {
      type: 'text',
      content: 'ICA components',
      fontStyle: 'bold',
      fontSize: 12,
      uppercase: false,
      x: 10,
      y: 23,
    },

    {
      type: 'text',
      content:
        'Independent component analysis (ICA) is a statistical method to separate independent sources from superimposed signals. It is the most common method that has been used in EEG data decomposition, and can be used to identify and remove the artifacts from raw EEG data. Features including time series, power spectrum density (PSD), component scalp map (Topomap), dipole source location (Source location) extracted from ICA are shown for each component.',
      fontStyle: 'normal',
      fontSize: 8,
      uppercase: false,
      color: '#595959',
      x: 10,
      y: 30,
    },
    {
      type: 'image',
      content: componentData,
      x: 10,
      y: 45,
      imageHeight: 130,
    },
  ],
]
const topomap = (screen: string): ElementPDF[][] => [
  [
    {
      type: 'text',
      content: 'Band power – Topomap',
      fontStyle: 'bold',
      fontSize: 12,
      uppercase: false,
      x: 10,
      y: 23,
    },

    {
      type: 'text',
      content:
        'In power spectral density(PSD) 2D map, topomaps of absolute and relative power in 1 Hz bins (1 - 45 Hz) as well as each frequency band are presented. Absolute power is the sum of the component powers for each frequency band. Relative power is the absolute power in a specific frequency band divided by the total power. It is advisable to compare relative power with absolute power, since absolute power reflects the individual differences due to variations in brain tissue. This feature provides absolute and relative power based on six brain regions (prefrontal, frontal, left temporal, right temporal, central, parietal, and occipital). The power spectra for each of the 19 channels are shown in the following feature, PSD spectrum (below).',
      fontStyle: 'normal',
      fontSize: 8,
      uppercase: false,
      color: '#595959',
      x: 10,
      y: 30,
    },
    {
      type: 'image',
      content: screen === 'EEG' ? topomapEEG : topomapNorm,
      x: 10,
      y: 50,
      imageHeight: screen === 'EEG' ? 200 : 220,
    },
  ],
  [
    {
      type: 'image',
      content: screen === 'EEG' ? topomapAbsoluteEeg : topomapAbsoluteNorm,
      x: 10,
      y: 23,
      imageHeight: screen === 'EEG' ? 230 : 170,
    },
  ],
  [
    {
      type: 'image',
      content: screen === 'EEG' ? topomapRelativeEeg : topomapRelativeNorm,
      x: 10,
      y: 23,
      imageHeight: screen === 'EEG' ? 230 : 170,
    },
  ],
]

const spectrum = (screen: string): ElementPDF[][] => [
  [
    {
      type: 'text',
      content: 'Power spectrum',
      fontStyle: 'bold',
      fontSize: 12,
      uppercase: false,
      x: 10,
      y: 23,
    },
    {
      type: 'text',
      content:
        'It shows the EEG power measured from 19 channels. It presents the amplitude of power in units of μV2 / Hz or dB/Hz to obtain the frequency characteristics of the alpha band or beta band, respectively.',
      fontStyle: 'normal',
      fontSize: 8,
      uppercase: false,
      color: '#595959',
      x: 10,
      y: 31,
    },
    {
      type: 'image',
      content: screen === 'EEG' ? powerSpectrumEeg : powerSpectrumNorm,
      x: 10,
      y: 41,
      imageHeight: 190,
    },
  ],
  [
    {
      type: 'text',
      content: 'Power spectrum',
      fontStyle: 'bold',
      fontSize: 12,
      uppercase: false,
      x: 10,
      y: 23,
    },
    {
      type: 'text',
      content:
        ' In power spectrum, it also shows the difference between a pair of channels. When you click the power spectrum of a channel, the power spectrum of the channel appears in a separate window as shown in the lower figure. Click ‘Compare’ to display difference between power spectral densities of the contralateral channels and difference in power per frequency band.',
      fontStyle: 'normal',
      fontSize: 8,
      uppercase: false,
      color: '#595959',
      x: 10,
      y: 31,
    },
    {
      type: 'image',
      content:
        screen === 'EEG' ? powerSpectrumDynamicEEG : powerSpectrumDynamicNorm,
      x: 10,
      y: 43,
      imageHeight: 170,
    },
  ],
]

const occipital = (screen: string): ElementPDF[] => [
  {
    type: 'text',
    content: 'Occipital alpha peak',
    fontStyle: 'bold',
    fontSize: 12,
    uppercase: false,
    x: 10,
    y: 23,
  },

  {
    type: 'image',
    content: screen === 'EEG' ? occipitalEeg : occipitalNorm,
    x: 10,
    y: 75,
    imageHeight: 120,
  },
]
const ratio = (screen: string): ElementPDF[] => [
  {
    type: 'text',
    content: 'Power ratio',
    fontStyle: 'bold',
    fontSize: 12,
    uppercase: false,
    x: 10,
    y: 23,
  },

  {
    type: 'image',
    content: screen === 'EEG' ? powerRatioEeg : powerRatioNorm,
    x: 10,
    y: 130,
    imageWidth: screen === 'EEG' ? 190 : 150,
    imageHeight: screen === 'EEG' ? 75 : 150,
  },
]
const roi = (screen: string): ElementPDF[][] => [
  [
    {
      type: 'text',
      content: 'Source ROI power (sLORETA) &connectivity (iCoh)',
      fontStyle: 'bold',
      fontSize: 12,
      uppercase: false,
      x: 10,
      y: 23,
    },
    {
      type: 'text',
      content:
        'Source power derives from the EEG channel data using sLORETA (T218).',
      fontStyle: 'normal',
      fontSize: 8,
      uppercase: false,
      color: '#595959',
      x: 10,
      y: 31,
    },
    {
      type: 'text',
      content:
        'The line represents the connectivity (iCoh) of ROIs. Imaginary coherence (iCoh) means the spectral coherence that ignores volume conduction (only using imaginary part).',
      fontStyle: 'normal',
      fontSize: 8,
      uppercase: false,
      color: '#595959',
      x: 10,
      y: 35,
    },
    {
      type: 'image',
      content: screen === 'EEG' ? circularMapAllEEG : circularMapAll,
      x: 10,
      y: 45,
      imageHeight: screen === 'EEG' ? 180 : 150,
    },
  ],
]
const defaultModeNetwork = (): ElementPDF[] => [
  {
    content: 'Default Mode Network',
    type: 'text',
    fontStyle: 'bold',
    fontSize: 12,
    x: 10,
    y: 23,
    maxWidth: 190,
  },

  {
    type: 'line',
    content: '',
    color: '#d9d9d9',
    x: 10,
    y: 35,
  },
]
const Y_START = 30
const X_START = 10
const DISTANCE_LINE = 3.2
const LINE_TEXT = 3
const generateTitleOverview = (
  listTitle: ListDefaultModeType[],
  startY?: number,
) => {
  const DISTANCE_LINE_RATIO = 1
  let y = startY ?? Y_START
  const data: ElementPDF[] = []
  for (let i = 0; i < listTitle.length; i += 1) {
    data.push({
      type: 'text',
      content: listTitle[i].title,
      fontStyle: 'normal',
      fontSize: 8,
      color: '#595959',
      uppercase: false,
      x: 10,
      y,
    })
    y +=
      LINE_TEXT * (listTitle[i].line || 1) +
      DISTANCE_LINE_RATIO +
      (listTitle[i].space ? 5 : 0)
  }
  return data
}
const generateDefaultModeNetwork = (
  item: ListDefaultModeType,
  y: number,
  x: number,
) => {
  const result: ElementPDF[] = [
    {
      type: 'text',
      content: item.title,
      fontStyle: 'normal',
      fontSize: 8,
      color: '#595959',
      uppercase: false,
      x,
      y,
    },
  ]
  return result
}
const generateFillDefault = (y: number) => {
  const result: ElementPDF[] = [
    {
      type: 'fill',
      content: '',
      color: '#f4f4f4',
      x: 10,
      y: y - 6,
      x1: 190,
      y1: 11,
    },
  ]
  return result
}
const overviewEEGPdf = (listChecked: string[], screen: 'EEG' | 'NORM') => {
  let data: ElementPDF[][] = [
    [
      ...electroencephalography(),
      ...generateTitleOverview(listTitleElectroencephalography, 40),
    ],
  ]

  if (!listChecked.includes(OVERVIEW_ANALYSIS)) {
    return []
  }

  let dataDefaultModeNetwork: ElementPDF[] = []
  let y = Y_START
  const x = X_START
  for (let i = 0; i < listDefaultModeNetwork.length; i += 1) {
    y += listDefaultModeNetwork[i].line ? 5 : 0
    if (listDefaultModeNetwork[i].line) {
      dataDefaultModeNetwork = [
        ...dataDefaultModeNetwork,
        ...generateFillDefault(y),
      ]
    }
    dataDefaultModeNetwork = [
      ...dataDefaultModeNetwork,
      ...generateDefaultModeNetwork(
        listDefaultModeNetwork[i],
        y,
        x + (listDefaultModeNetwork[i].line || 0),
      ),
    ]
    y +=
      LINE_TEXT * (listDefaultModeNetwork[i].line || 1) +
      DISTANCE_LINE +
      (listDefaultModeNetwork[i].line || 0)
  }

  if (listChecked.includes(ReportAnalysisTypeMap.RawData)) {
    data = [...data, ...rawData()]
  }
  if (listChecked.includes(ReportAnalysisTypeMap.ICAComponents)) {
    data = [...data, ...icaComponent()]
  }
  if (listChecked.includes(ReportAnalysisTypeMap.BandPower)) {
    data = [...data, ...topomap(screen)]
  }
  if (listChecked.includes(ReportAnalysisTypeMap.PowerSpectrum)) {
    data = [...data, ...spectrum(screen)]
  }
  if (listChecked.includes(ReportAnalysisTypeMap.OccipitalAlphaPeak)) {
    data = [
      ...data,
      [...occipital(screen), ...generateTitleOverview(listTitleOccipital)],
    ]
  }
  if (listChecked.includes(ReportAnalysisTypeMap.PowerRatio)) {
    data = [
      ...data,
      [...ratio(screen), ...generateTitleOverview(listTitleRatio)],
    ]
  }
  if (listChecked.includes(ReportAnalysisTypeMap.SourceROIPower)) {
    data = [...data, ...roi(screen)]
  }

  dataDefaultModeNetwork = [...defaultModeNetwork(), ...dataDefaultModeNetwork]
  data = [...data, dataDefaultModeNetwork]

  return data
}

export default overviewEEGPdf
