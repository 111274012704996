import {AnalysisResultItem} from 'api/analysisApi'
import {ElementPDF} from 'components/molcules/GenerateReportPDF'
import personalData from 'components/molcules/GenerateReportPDF/PersonalData'
import {ReportInfoPropsPDF} from 'components/molcules/Report/ReportInfo'
import {getGenderStatus, getHandStatus} from 'helpers/analysisHelper'
import {
  isoStringToDateAndTimeString,
  parseAgeFromStrings,
} from 'helpers/dateHelper'

export const getPersonalData = ({
  id,
  clientName,
  clientFirstName,
  clientLastName,
  gender,
  birth,
  measureDate,
  hand,
  diseaseCode,
  t,
}: ReportInfoPropsPDF) => {
  const age = parseAgeFromStrings(birth, measureDate)
  return [
    ...personalData([
      {
        title: t('IIndividualIndex'),
        value: id.toString(),
      },
      {
        title: t('ICustomerName'),
        value:
          clientFirstName || clientLastName
            ? `${clientFirstName} ${clientLastName}`
            : `${clientName}`,
      },
      {
        title: t('IGender'),
        value: gender ? getGenderStatus(gender) : '-',
      },
      {
        title: t('IAgeOfAnalysisTime'),
        value: `${birth}(${age === -1 ? '-' : age})`,
      },
      {
        title: t('IMeasurementDate'),
        value: `${
          isoStringToDateAndTimeString(measureDate ?? '').split(' ')[0]
        }`,
      },
      {
        title: t('IHandedness'),
        value: getHandStatus(hand || '-'),
      },
      {
        title: t('IDiseaseCode'),
        value: diseaseCode || '-',
      },
    ]),
  ]
}

export const getHRVTimeSeries = (
  item: AnalysisResultItem[],
  index: number,
): ElementPDF[][] => [
  [
    {
      type: 'text',
      content: ` ${index}. RR Time Series Data`,
      fontStyle: 'normal',
      fontSize: 13,
      uppercase: false,
      x: 10,
      y: 27,
      maxWidth: 190,
    },
    {
      type: 'image',
      content: item[0].imgPath ? item[0].imgPath : '',
      x: 10,
      y: 50,
      imageWidth: 190,
      imageHeight: 31.67,
    },
    {
      type: 'image',
      content: item[1].imgPath ? item[1].imgPath : '',
      x: 10,
      y: 90,
      imageWidth: 190,
      imageHeight: 31.67,
    },
  ],
]

export const getHRVTimeDomain = (
  item: AnalysisResultItem[],
  index: number,
): ElementPDF[][] => [
  [
    {
      type: 'text',
      content: ` ${index}. Time-Domain Results`,
      fontStyle: 'normal',
      fontSize: 13,
      uppercase: false,
      x: 10,
      y: 27,
      maxWidth: 190,
    },
    {
      type: 'image',
      content: item[0].imgPath ? item[0].imgPath : '',
      x: 10,
      y: 33,
      imageWidth: 190,
      imageHeight: 142,
    },
  ],
  [
    {
      type: 'image',
      content: item[1].imgPath ? item[1].imgPath : '',
      x: 10,
      y: 30,
      imageWidth: 190,
      imageHeight: 142,
    },
  ],
]

export const getHRVFrequencyDomain = (
  item: AnalysisResultItem[],
  index: number,
): ElementPDF[][] => [
  [
    {
      type: 'text',
      content: ` ${index}. Frequency-Domain Results`,
      fontStyle: 'normal',
      fontSize: 13,
      uppercase: false,
      x: 10,
      y: 27,
      maxWidth: 190,
    },
    {
      type: 'image',
      content: item[0].imgPath ? item[0].imgPath : '',
      x: 10,
      y: 33,
      imageWidth: 190,
      imageHeight: 111,
    },

    {
      type: 'image',
      content: item[1].imgPath ? item[1].imgPath : '',
      x: 10,
      y: 154,
      imageWidth: 190,
      imageHeight: 111,
    },
  ],
]

export const getHRVNonlinear = (
  item: AnalysisResultItem[],
  index: number,
): ElementPDF[][] => [
  [
    {
      type: 'text',
      content: ` ${index}. Nonlinear Results`,
      fontStyle: 'normal',
      fontSize: 13,
      uppercase: false,
      x: 10,
      y: 27,
      maxWidth: 190,
    },

    {
      type: 'image',
      content: item[0].imgPath ? item[0].imgPath : '',
      x: 10,
      y: 33,
      imageWidth: 190,
      imageHeight: 85,
    },
    {
      type: 'image',
      content: item[1].imgPath ? item[1].imgPath : '',
      x: 10,
      y: 130,
      imageWidth: 190,
      imageHeight: 85,
    },
  ],
]
