import React from 'react'
import styled from 'styled-components'
import ChartLeft from 'assets/img/LeftChannel.jpeg'

const StyledHorizontalImageGraph = styled.div<{yAxisImgSize: number}>`
  position: relative;
  width: 100%;
  overflow-x: scroll;

  & .Left {
    position: sticky;
    width: auto;
    height: 572px;
    z-index: 1;
    top: 0;
    left: 0;

    & img {
      max-width: ${(props) => props.yAxisImgSize}px;
      height: 100%;
    }
  }

  & .Right {
    position: absolute;
    height: 572px;
    top: 0;
    left: 0;

    & img {
      width: auto;
      height: 100%;
      vertical-align: middle;
    }
  }
`

export interface HorizontalImageGraphProps {
  xAxisImg: string
  yAxisImg?: string
  yAxisImgSize?: number
  width?: number
}

export function HorizontalImageGraphV1({
  xAxisImg,
  yAxisImg = ChartLeft,
  yAxisImgSize = 72,
  width,
}: HorizontalImageGraphProps) {
  return (
    <StyledHorizontalImageGraph
      yAxisImgSize={yAxisImgSize}
      style={{width: width ?? '100%'}}
    >
      <div className='Left'>
        <img style={{width: 'auto'}} src={yAxisImg} alt='Graph Left' />
      </div>
      <div className='Right'>
        <img src={xAxisImg} alt='Graph Content' />
      </div>
    </StyledHorizontalImageGraph>
  )
}
