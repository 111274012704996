// @ts-ignore
import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import Checkbox from '@material-ui/core/Checkbox'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {saveAs} from 'file-saver'
import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'
import Pagination from 'components/molcules/Pagination'
import TableCell from 'components/Table/TableCell'
import useStyles from 'components/Table/useTableStyles'
import useHealthCareWhiteList from 'features/healthCare/useHealthCareWhiteList'
import useFailureModal from 'features/modal/useFailureModal'
import {
  choiceHealthCareApi,
  SearchedHealthCare,
  downloadStatusHealthCareApi,
  downloadExcelHealthCareApi,
  getHealthProductsApi,
} from 'api/healthCareApi'

import {
  isoStringToDateAndTimeString,
  dateToFileFormat,
} from 'helpers/dateHelper'
import RouteConstant from 'constants/RouteConstant'
import useAuth from 'features/auth/useAuth'
import {statusToI18nString} from 'helpers/analysisHelper'
import openInNewTab from 'helpers/openInNewTab'
import RenderTableRow from 'pages/HealthCenter/PageTable/TableRow'
import TableToolbar from 'pages/HealthCenter/PageTableWhiteList/TableToolbar'
import {isDefined} from 'helpers/commonHelper'

export const RenderComponent = {
  checkbox: 'checkbox',
  mainReport: 'mainReport',
  downloadButton: `downloadButton`,
  hcAnalysisTypes: 'hcAnalysisTypes',
  analysisNumber: 'analysisNumber',
}

type DownloadOutput = {
  baseDir: string
  fileName: string
  data: string | ArrayBuffer
}

type DownloadSource = {baseDir: string; url: string; idx?: number}

interface PageTableProps {
  readonly selectedOld: SearchedHealthCare[]
  readonly setSelectedOld: (
    data: SearchedHealthCare[],
    items: SearchedHealthCare[],
  ) => void
}

function PageTableWhiteList(props: PageTableProps) {
  const {selectedOld, setSelectedOld} = props
  const {t} = useTranslation()
  const classes = useStyles()
  const {user: currentUser} = useAuth()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const [loadingDownload, setLoadingDownload] = useState(false)
  const [loadingExcel, setLoadingExcel] = useState(false)

  const {
    loading,
    query,
    paging,
    pagingInfo,
    items,
    hdAnalysisTypes,
    onSearch,
    getHdAnalysisTypes,
    setListProduct,
    getRequiredListProduct,
  } = useHealthCareWhiteList()

  const [selected, setSelected] = useState<SearchedHealthCare[]>([])
  const [keyUpdate, setKeyUpdate] = useState<number>(0)

  const handlePageChanged = async (page: number) => {
    setSelectedOld(selected, items)
    onSearch({
      ...query,
      page,
    } as any)
  }

  const handlePageSizeChanged = async (
    event: React.ChangeEvent<{name?: string; value: unknown}>,
  ) => {
    setSelectedOld(selected, items)
    onSearch({
      ...query,
      page: 0,
      size: parseInt(event.target.value as string, 10),
    } as any)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(items)
      setSelectedOld(items, items)
      return
    }
    setSelected([])
    setSelectedOld([], items)
  }

  const selectedId = useMemo(
    () => selected.map((item) => item.hcId),
    [selected],
  )

  const handleRowClick = (item: SearchedHealthCare) => {
    const selectedIndex = selectedId.indexOf(item.hcId)
    let newSelected: SearchedHealthCare[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
    setSelectedOld(newSelected, items)
  }

  const handleViewReportPage = (item: SearchedHealthCare) => {
    const path = RouteConstant.ANALYSIS_HEALTH_CENTER_REPORT.path.replace(
      ':id',
      item.hcId.toString(),
    )
    openInNewTab(path)
  }

  const handleMainReportClick = async (item: SearchedHealthCare) => {
    try {
      const result = await choiceHealthCareApi({
        id: item.hcId,
        uid: item.uid,
      })
      if (result) onSearch(query as any)
    } catch (error) {
      const err = JSON.parse(JSON.stringify(error))
      onFailureModalOpen(err.err.message)
    }
  }

  const zipFiles = async (outputs: DownloadOutput[]) => {
    const zip = new JSZip()

    const parentFolderName = outputs[0].baseDir
    const parentFolder = zip.folder(parentFolderName)

    outputs.forEach((output) => {
      parentFolder?.file(output.fileName, output.data, {binary: true})
    })

    const zipFile = await zip.generateAsync({type: 'blob'})
    saveAs(zipFile, dateToFileFormat(new Date()))
  }
  const downloadSingleFile = async (outputs: DownloadOutput[]) => {
    const fileData: any = outputs[0]
    const blob = new Blob([fileData.data], {type: 'application/pdf'})
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileData.fileName)

    document.body.appendChild(link)
    link.click()

    URL.revokeObjectURL(url)
    document.body.removeChild(link)
  }
  const downloadMultiFiles = async (sources: DownloadSource[]) => {
    const promises = sources.map((source) =>
      JSZipUtils.getBinaryContent(source.url).then((data) => {
        const {url, idx, baseDir} = source

        let fileName =
          url.slice((url.lastIndexOf('/') ?? 0) + 1) ?? 'UNKNOWN.edf'
        fileName = fileName.replaceAll(' ', '__')

        if (idx !== undefined) {
          const [prefix, suffix] = fileName.split('.')
          fileName = `${prefix}.${suffix}`
        }
        return {
          fileName,
          data,
          baseDir,
        }
      }),
    )

    const downloadResultList = await Promise.allSettled(promises)
    return downloadResultList
      .map((result) => {
        if (result.status === 'rejected') {
          return null
        }

        return result.value
      })
      .filter(isDefined)
  }

  const handleDownload = async (hcIds: number[]) => {
    setLoadingDownload(true)
    try {
      const listFileResponse = await downloadStatusHealthCareApi({
        id: hcIds.join(','),
        uid: currentUser?.uid ?? '',
      })
      const fileUrls = listFileResponse.list
        .map((file) => {
          const baseDir = `${file.id}`

          const files: DownloadSource[] = []
          if (file.filePath !== null) {
            const hasOneMore = hcIds.length
            files.push({
              url: file.filePath,
              baseDir,
              idx: hasOneMore || undefined,
            })
          }
          if (files.length === 0) return undefined

          return files
        })
        .filter(isDefined)
        .reduce((accr, curr) => [...accr, ...curr], [])

      const filesDownload = await downloadMultiFiles(fileUrls)
      if (hcIds.length === 1) {
        await downloadSingleFile(filesDownload)
      } else {
        await zipFiles(filesDownload)
      }
    } catch (error) {
      console.log(error)
    }
    setLoadingDownload(false)
  }

  const handleDownloadExcel = async (hcIds: number[]) => {
    setLoadingExcel(true)
    try {
      await downloadExcelHealthCareApi({
        id: hcIds.join(','),
        uid: currentUser?.uid ?? '',
      })
    } catch (error) {
      console.log(error)
    }
    setLoadingExcel(false)
  }

  const isRowSelected = (item: SearchedHealthCare) => {
    return selectedId.indexOf(item.hcId) !== -1
  }

  const emptyRows = items === null ? 5 : 5 - items.length

  const renderProgressStatus = (item: SearchedHealthCare) => {
    if (item.hcStatus !== 'SUCCESS') return item.hcStatus
    const listRequired = getRequiredListProduct(item.hcAnalysisTypes)
    if (!listRequired || !listRequired.length) return item.hcStatus
    const listStatus: string[] = []
    listRequired.forEach((ele) => {
      const statusKey = `${ele}Status` as keyof SearchedHealthCare
      if (item[statusKey] !== 'SUCCESS') {
        listStatus.push('ERROR')
      } else listStatus.push('SUCCESS')
    })
    if (listStatus.includes('ERROR') && listStatus.includes('SUCCESS')) {
      return 'PARTIALLY_COMPLETED'
    }
    if (listStatus.includes('ERROR')) {
      return 'ERROR'
    }
    return 'SUCCESS'
  }

  const TableHeader = [
    {
      label: null,
      render: RenderComponent.checkbox,
    },
    {label: 'IExamNumber', value: 'hcId'},
    {
      label: 'IAnalysisNumber',
      value: null,
      render: RenderComponent.analysisNumber,
    },
    {label: 'IChartNo', value: 'patientChartNo'},
    {
      label: 'IRegistrationNumber',
      value: 'patientVisitNo',
    },
    {
      label: 'ICustomerName',
      value: null,
      render: (item: SearchedHealthCare) =>
        t('IGetFullName', {
          firstName: item.patientFirstName,
          lastName: item.patientLastName,
          interpolation: {escapeValue: false},
        }),
      style: {minWidth: '120px'},
    },
    {
      label: 'IExaminationDate',
      render: (item: SearchedHealthCare) =>
        isoStringToDateAndTimeString(item.treatmentAt),
      style: {minWidth: '150px'},
    },
    {
      label: 'IMeasureDate',
      render: (item: SearchedHealthCare) =>
        isoStringToDateAndTimeString(item.createdAt),
      style: {minWidth: '150px'},
    },
    {label: 'IBirthday', value: 'patientBirth'},
    {
      label: 'IExaminationProduct',
      render: RenderComponent.hcAnalysisTypes,
      style: {minWidth: '237px'},
    },
    {
      label: 'IProgress',
      render: (item: SearchedHealthCare) => {
        return t(
          statusToI18nString(renderProgressStatus(item) as AnalysisStatus),
        )
      },
      style: {minWidth: '60px'},
    },
    {
      label: 'IAnalysisReport',
      render: RenderComponent.downloadButton,
      style: {minWidth: '60px'},
    },
    {
      label: 'IDownload',
      render: (item: SearchedHealthCare) =>
        item.reportDownloaded === 'Y' ? t('IStatus_success') : '-',
      style: {minWidth: '60px'},
    },
  ]
  useEffect(() => {
    if (items) {
      const callApiForAllIds = async () => {
        const listProduct = await getHealthProductsApi()
        setListProduct(listProduct.list)
      }

      callApiForAllIds()
    }
  }, [items])

  useEffect(() => {
    setKeyUpdate(keyUpdate + 1)
  }, [hdAnalysisTypes])

  useEffect(() => {
    const itemIds = items.map((item) => item.hcId)
    const selectedOldNew = []
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < selectedOld.length; i++) {
      if (itemIds.indexOf(selectedOld[i].hcId) !== -1) {
        selectedOldNew.push(selectedOld[i])
      }
    }
    setSelected(selectedOldNew)
  }, [selectedOld, items])

  return (
    <div>
      <TableToolbar
        selectedItems={Array.from(
          new Map(
            [...selected, ...selectedOld].map((item) => [item.hcId, item]),
          ).values(),
        )}
        onDownload={handleDownload}
        onDownloadExcel={handleDownloadExcel}
        statusDownload={loadingDownload}
        loadingExcel={loadingExcel}
      />
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} size='small'>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              {TableHeader.map((col) => {
                if (!col.label)
                  return (
                    <TableCell align='center' padding='checkbox'>
                      <Checkbox
                        indeterminate={
                          selected.length > 0 && selected.length < items.length
                        }
                        checked={
                          selected.length > 0 &&
                          selected.length === items.length
                        }
                        onChange={handleSelectAllClick}
                        inputProps={{'aria-label': 'select all records'}}
                      />
                    </TableCell>
                  )
                return (
                  <TableCell
                    align='center'
                    padding='none'
                    style={col.style ? col.style : {}}
                  >
                    {t(col.label)}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody key={keyUpdate}>
            {items.map((item) => (
              <RenderTableRow
                key={`${item.patientFirstName}_${item.patientLastName}_${item.hcId}`}
                item={item}
                onClick={handleRowClick}
                onClickView={handleViewReportPage}
                onClickMainReport={handleMainReportClick}
                isSelected={isRowSelected}
                tableHeader={TableHeader}
                getHdAnalysisTypes={() =>
                  getHdAnalysisTypes(item.hcAnalysisTypes)
                }
                status={renderProgressStatus(item)}
              />
            ))}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height:
                    43 *
                    (items.length > 10
                      ? 0
                      : (query.size <= 10 ? query.size : 10) - items.length),
                  maxHeight:
                    43 *
                    (items.length > 10
                      ? 0
                      : (query.size <= 10 ? query.size : 10) - items.length),
                  backgroundColor: '#F9F9FB',
                }}
              >
                <TableCell colSpan={15} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        totalPageCount={pagingInfo.totalPages}
        currentPageIndex={query.page ?? 0}
        itemCountPerPage={query.size ?? 10}
        setCurrentPageIndex={handlePageChanged}
        onItemCountPerPageChanged={handlePageSizeChanged}
        loading={loading}
      />
    </div>
  )
}

export default PageTableWhiteList
