import {
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import {
  executeEegNormAnalysisApi,
  ExecuteNormAnalysisRequest,
  IndividualEEGSearchRequest,
  SearchedIndividualEEG,
  searchIndividualEEGApi,
} from 'api/analysisApi'
import {getDefaultPeriodString, periodStringToDate} from 'helpers/dateHelper'
import i18n from 'i18n'
import {RootState, ThunkExtraArguments} from 'store'
import {
  openSuccessModalAction,
  openFailureModalAction,
} from 'features/modal/alertSlice'

export const searchIndividualEeg = createAsyncThunk(
  'api/eeg/individual/search',
  async (payload: IndividualEEGSearchRequest, {rejectWithValue, dispatch}) => {
    try {
      const response = await searchIndividualEEGApi(payload)
      return response.data
    } catch (err) {
      await dispatch(openFailureModalAction(err.message))
      return rejectWithValue(err.message)
    }
  },
)

export const openNormDialog = createAction<SearchedIndividualEEG[]>(
  'individualEeg/norm/dialog/open',
)

export const closeNormDialog = createAction('individualEeg/norm/dialog/close')

export const confirmNormDialog = createAsyncThunk<
  any,
  ExecuteNormAnalysisRequest,
  {extra: ThunkExtraArguments}
>(
  'individualEeg/norm/dialog/confirm',
  async (payload: ExecuteNormAnalysisRequest, {rejectWithValue, dispatch}) => {
    try {
      await executeEegNormAnalysisApi(payload)
      await dispatch(openSuccessModalAction(i18n.t('ISuccess')))
      dispatch(closeNormDialog())
      return true
    } catch (err) {
      await dispatch(openFailureModalAction(err.message))
      return rejectWithValue(err.message)
    }
  },
)

interface IndividualEEGState {
  loading: boolean
  error: any
  items: SearchedIndividualEEG[]
  period: PeriodString
  search: AnalysisSearchKind
  paging: Paging
  oid?: string | null
  pagingInfo: {
    totalPages: number
    totalElements: number
  }
  normDialogOpen: boolean
  selected: SearchedIndividualEEG[]
  reSelectYn: YN | undefined
  reAnalysisYn: YN | undefined
  isIcaSelectOpen: boolean
  isIcaConfirmOpen: boolean
  isIcaResultOpen: boolean
  sraYn?: YN | undefined
}

const initialState: IndividualEEGState = {
  loading: false,
  error: null,
  items: [],
  period: getDefaultPeriodString(),
  search: {searchName: 'NAME', searchValue: ''},
  paging: {page: 0, size: 10},
  pagingInfo: {
    totalPages: 1,
    totalElements: 0,
  },
  normDialogOpen: false,
  selected: [],
  reSelectYn: undefined,
  reAnalysisYn: undefined,
  isIcaSelectOpen: false,
  isIcaConfirmOpen: false,
  isIcaResultOpen: false,
  oid: '',
  sraYn: undefined,
}

const slice = createSlice({
  name: 'eegIndividualSlice',
  initialState,
  reducers: {
    openIcaSelectDialog(state, action: PayloadAction<SearchedIndividualEEG[]>) {
      state.isIcaSelectOpen = true
      state.selected = action.payload
    },
    confirmIcaSelectDialog(
      state,
      action: PayloadAction<{
        reSelectYn: YN
        reAnalysisYn: YN
        sraYn?: YN
      }>,
    ) {
      state.isIcaSelectOpen = false
      state.isIcaConfirmOpen = true
      state.reSelectYn = action.payload.reSelectYn
      state.reAnalysisYn = action.payload.reAnalysisYn
      state.sraYn = action.payload.sraYn
    },
    closeIcaSelectDialog(state) {
      state.isIcaSelectOpen = false
      state.selected = []
      state.reAnalysisYn = undefined
      state.reSelectYn = undefined
      state.sraYn = undefined
    },
    confirmIcaConfirmDialog(state) {
      state.isIcaConfirmOpen = false
      state.isIcaResultOpen = true
    },
    closeIcaConfirmDialog(state) {
      state.isIcaConfirmOpen = false
      state.selected = []
      state.reAnalysisYn = undefined
      state.reSelectYn = undefined
      state.sraYn = undefined
    },
    confirmIcaResultDialog(state) {
      state.isIcaResultOpen = false
      state.selected = []
    },
    closeIcaResultDialog(state) {
      state.isIcaResultOpen = false
      state.selected = []
      state.reAnalysisYn = undefined
      state.reSelectYn = undefined
      state.sraYn = undefined
    },
  },
  extraReducers: (builder) => {
    builder.addCase(openNormDialog, (state, action) => {
      state.normDialogOpen = true
      state.selected = action.payload
    })
    builder.addCase(closeNormDialog, (state) => {
      state.normDialogOpen = false
    })
    builder.addCase(searchIndividualEeg.pending, (state, action) => {
      state.loading = true
      state.error = null

      const {period, search, paging, oid} = action.meta.arg
      state.period = period
      state.search = search
      state.paging = paging
      state.oid = oid
    })
    builder.addCase(searchIndividualEeg.fulfilled, (state, action) => {
      state.loading = false

      const {
        content: items,
        pageable: {pageNumber: page},
        size,
        totalPages,
        totalElements,
      } = action.payload
      state.items = items
      state.loading = false
      state.error = null
      state.paging.page = page
      state.paging.size = size
      state.pagingInfo.totalPages = totalPages
      state.pagingInfo.totalElements = totalElements
    })
    builder.addCase(searchIndividualEeg.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export default slice.reducer

export const {
  openIcaSelectDialog,
  confirmIcaSelectDialog,
  closeIcaSelectDialog,
  confirmIcaConfirmDialog,
  closeIcaConfirmDialog,
  confirmIcaResultDialog,
  closeIcaResultDialog,
} = slice.actions

export const selectLoading = (state: RootState) => state.eegIndividual.loading
export const selectError = (state: RootState) => state.eegIndividual.error
export const selectItems = (state: RootState) => state.eegIndividual.items
export const selectPeriod = (state: RootState) =>
  periodStringToDate(state.eegIndividual.period)
export const selectSearch = (state: RootState) => state.eegIndividual.search
export const selectPaging = (state: RootState) => state.eegIndividual.paging
export const selectPagingInfo = (state: RootState) =>
  state.eegIndividual.pagingInfo
export const selectQuery = (state: RootState) => ({
  period: state.eegIndividual.period,
  search: state.eegIndividual.search,
  paging: state.eegIndividual.paging,
  oid: state.eegIndividual.oid,
})
export const selectNormDialogOpen = (state: RootState) =>
  state.eegIndividual.normDialogOpen
export const selectSelectedItems = (state: RootState) =>
  state.eegIndividual.selected.map((item) => item.id)
export const selectIcaSelectOpen = (state: RootState) =>
  state.eegIndividual.isIcaSelectOpen
export const selectIcaConfirmOpen = (state: RootState) =>
  state.eegIndividual.isIcaConfirmOpen
export const selectIcaResultOpen = (state: RootState) =>
  state.eegIndividual.isIcaResultOpen
export const selectReAnalysisYn = (state: RootState) => ({
  reSelectYn: state.eegIndividual.reSelectYn,
  reAnalysisYn: state.eegIndividual.reAnalysisYn,
  sraYn: state.eegIndividual.sraYn,
})
