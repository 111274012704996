import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {HRVSearchRequest, SearchedHRV, searchHrvApi} from 'api/analysisApi'
import {openFailureModalAction} from 'features/modal/alertSlice'
import {getDefaultPeriodString, periodStringToDate} from 'helpers/dateHelper'
import {RootState} from 'store'

export const searchHrv = createAsyncThunk(
  'api/hrv/search',
  async (payload: HRVSearchRequest, {rejectWithValue, dispatch}) => {
    try {
      const response = await searchHrvApi(payload)
      return response.data
    } catch (err) {
      await dispatch(openFailureModalAction(err.message))
      return rejectWithValue(err)
    }
  },
)

export interface HRVState {
  loading: boolean
  error: any
  items: SearchedHRV[]
  period: PeriodString
  search: AnalysisSearchKind
  paging: Paging
  pagingInfo: {
    totalPages: number
    totalElements: number
  }
}

const initialState: HRVState = {
  loading: false,
  error: null,
  items: [],
  period: getDefaultPeriodString(),
  search: {searchName: 'NAME', searchValue: ''},
  paging: {page: 0, size: 10},
  pagingInfo: {
    totalPages: 1,
    totalElements: 0,
  },
}

const slice = createSlice({
  name: 'hrvSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(searchHrv.pending, (state, action) => {
      state.loading = true
      state.error = null

      const {period, search} = action.meta.arg
      state.search = search
      state.period = period
    })
    builder.addCase(searchHrv.fulfilled, (state, action) => {
      state.loading = false
      const {
        content: items,
        pageable: {pageNumber: page},
        size,
        totalPages,
        totalElements,
      } = action.payload
      state.items = items
      state.paging.page = page
      state.paging.size = size
      state.pagingInfo.totalElements = totalElements
      state.pagingInfo.totalPages = totalPages
    })
    builder.addCase(searchHrv.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export default slice.reducer

export const selectLoading = (state: RootState) => state.hrv.loading
export const selectError = (state: RootState) => state.hrv.error
export const selectItems = (state: RootState) => state.hrv.items
export const selectPeriod = (state: RootState) =>
  periodStringToDate(state.hrv.period)
export const selectSearch = (state: RootState) => state.hrv.search
export const selectPaging = (state: RootState) => state.hrv.paging
export const selectPagingInfo = (state: RootState) => state.hrv.pagingInfo
export const selectQuery = (state: RootState) => ({
  period: state.hrv.period,
  search: state.hrv.search,
  paging: state.hrv.paging,
})
