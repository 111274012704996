/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, {useState} from 'react'
import SettingsIcon from '@material-ui/icons/Settings'
import {useTranslation} from 'react-i18next'
import axios from 'axios'
import {AnalysisUserInfo} from 'api/analysisApi'
import CheckBox from 'components/Checkbox'
import {PrimaryLoadableButton} from 'components/CustomButtons/PrimaryLoadableButton'
import background from 'assets/img/PDF/layout.webp'
import fileExcel from 'assets/img/PDF/file_excel.svg'
import {makeStyles} from '@material-ui/core'
import getBackdrop, {
  getHeaderPDF,
  getBottom,
} from 'components/molcules/GenerateReportPDF/LayoutPdf'
import getJsPDF from 'components/molcules/GenerateReportPDF/ConfigFontPDF'
import Container, {
  SettingContainer,
  ContentContainer,
  SelectContainer,
  ListContainer,
  ActionContainer,
  SelectOption,
  DownloadPdfStyle,
  BtnDownload,
} from 'components/molcules/GenerateReportPDF/styles'
import {
  HRVAnalysisTypeMap,
  ReportAnalysisTypeMap,
} from 'constants/AnalysisConstant'
import useFailureModal from 'features/modal/useFailureModal'

const useStyles = makeStyles(DownloadPdfStyle)

const CONTENT_WIDTH = 300
interface IPdfDownloadProps {
  isMultipleDownload?: boolean
  userInfo: AnalysisUserInfo
  contentPage: ElementPDF
  children: React.ReactNode
  screen: 'NORM' | 'HRV' | 'EEG'
  listChecked: string[]
}

export interface ElementPDF {
  type: 'text' | 'image' | 'line' | 'fill' | 'link' | 'center'
  content: string
  x: number
  y: number
  x1?: number
  y1?: number
  fontSize?: number
  fontStyle?: 'normal' | 'bold' | 'medium'
  uppercase?: boolean
  imageWidth?: number
  imageHeight?: number
  color?: string
  maxWidth?: number
  lineDash?: boolean
  url?: string
  lineHeight?: number
  t?: any
}

async function convertImageToBase64(imageUrl: string): Promise<string | null> {
  try {
    const response = await axios.get(imageUrl, {
      headers: {
        'Cache-Control': 'no-cache',
      },
      responseType: 'arraybuffer',
    })
    const imageBuffer = Buffer.from(response.data, 'binary')
    const base64Image = imageBuffer.toString('base64')
    const imgData = `data:${response.headers['content-type']};base64,${base64Image}`
    return imgData
  } catch (error) {
    console.error('Error fetching image:', error)
    return null
  }
}

function* generatePDFGenerator(elements: ElementPDF[]) {
  for (const element of elements) {
    yield element
  }
}

type TextSplit = {text: string; type?: string}
const REGEX_SEARCH = /\(T\d+\)/g

const splitText = (inputString: string): TextSplit[] => {
  const result = []
  let lastIndex = 0
  const matches = inputString.match(REGEX_SEARCH)
  if (matches) {
    for (let i = 0; i < matches.length; i += 1) {
      const match = matches[i]
      const startIndex = inputString.indexOf(match, lastIndex)
      if (startIndex > lastIndex) {
        result.push({
          text: inputString.slice(lastIndex, startIndex),
        })
      }
      result.push({
        text: match,
        type: 'link',
      })
      lastIndex = startIndex + match.length
    }
    if (lastIndex < inputString.length) {
      result.push({
        text: inputString.slice(lastIndex),
      })
    }
  } else {
    result.push({
      text: inputString,
    })
  }

  return result
}

const renderTextWithRegex = (
  pdf: any,
  lines: [],
  x: number,
  y: number,
  fontSize: number,
  lineHeight: number,
  color: string,
) => {
  let currentY = y
  const lineHeightPx = (fontSize || 12) * lineHeight
  pdf.setFontSize(fontSize)
  lines.forEach((line: string) => {
    const textSplitData = splitText(line)

    let currentX = x
    textSplitData.forEach((part: TextSplit) => {
      if (!part.type) {
        pdf.setTextColor(color ?? '#000000')
        pdf.text(part.text, currentX, currentY)
        currentX += pdf.getTextWidth(`${part.text} `)
      } else {
        pdf.setTextColor('#4682B4')
        pdf.text(part.text, currentX, currentY)
        currentX += pdf.getTextWidth(`${part.text} `)
      }
    })
    currentY += lineHeightPx
  })
  return pdf
}

const renderImagePDF = async (
  pdf: any,
  content: string,
  x: number,
  y: number,
  imageWidth: number,
  imageHeight: number,
) => {
  try {
    const imgElement = await convertImageToBase64(content)

    if (imgElement) {
      const img = new Image()
      img.src = imgElement

      await new Promise<void>((resolve, reject) => {
        img.onload = () => {
          const aspectRatio = img.width / img.height
          let newWidth = imageWidth || 190
          let newHeight = (imageWidth || 190) / aspectRatio

          if (newHeight > (imageHeight || 79)) {
            newHeight = imageHeight || 79
            newWidth = newHeight * aspectRatio
          }

          pdf.addImage(img.src, 'PNG', x, y, newWidth, newHeight)
          resolve()
        }

        img.onerror = (error) => {
          console.error('Error loading image:', error)
          reject(error)
        }
      })
    } else {
      console.error('Element is not an image or does not exist')
    }
  } catch (error) {
    console.error('Error processing image:', error)
  }
  return pdf
}

const renderTextCenter = (
  pdf: any,
  text: string,
  maxWidth: number,
  x_center: number,
  y: number,
) => {
  pdf.setFont('NotoSansKR', 'normal')
  pdf.setTextColor('#333333')
  pdf.setFontSize(8)
  let yStart = y
  const lines = pdf.splitTextToSize(text, maxWidth)
  lines.forEach((line: any) => {
    const textWidth = pdf.getTextWidth(line)
    const adjustedX = x_center - textWidth / 2
    pdf.text(line, adjustedX, yStart)
    yStart += 4
  })
  return pdf
}

const generatePDF = async (pdf: any, elements: ElementPDF[]) => {
  const generator = generatePDFGenerator(elements)
  for (const element of generator) {
    const {
      type,
      content,
      x,
      y,
      x1,
      y1,
      fontSize,
      fontStyle,
      uppercase,
      imageWidth,
      imageHeight,
      color,
      maxWidth,
      lineDash = false,
      url,
      lineHeight = 0.5,
    } = element
    const margin = 10
    const pageWidth = pdf.internal.pageSize.width - margin * 2

    if (type === 'line') {
      if (lineDash) {
        pdf.setLineDashPattern([0.5, 0.5], 0)
      }
      pdf.setDrawColor(color ?? '#000000')
      pdf.setLineWidth(lineHeight)
      pdf.line(x, y, x1 ?? pdf.internal.pageSize.width - margin, y1 ?? y)
    } else if (type === 'text') {
      pdf.setFont('NotoSansKR', fontStyle)
      pdf.setTextColor(color ?? '#000000')
      pdf.setFontSize(fontSize || 12)
      const textContent = uppercase ? content.toUpperCase() : content
      const lines = pdf.splitTextToSize(textContent, pageWidth)
      pdf = renderTextWithRegex(
        pdf,
        lines,
        x,
        y,
        fontSize || 12,
        0.45,
        color || '#000000',
      )
    } else if (type === 'link') {
      pdf.setFont('NotoSansKR', fontStyle)
      pdf.setTextColor('#3366ff')
      pdf.setFontSize(fontSize || 12)
      pdf.textWithLink(content, x, y, {url})
    } else if (type === 'image') {
      pdf = await renderImagePDF(
        pdf,
        content,
        x,
        y,
        imageWidth || 0,
        imageHeight || 0,
      )
    } else if (type === 'center') {
      renderTextCenter(pdf, content, maxWidth || 0, x || 0, y)
    } else if (type === 'fill') {
      pdf.setFillColor(color ?? '#000000')
      pdf.rect(x, y, x1, y1, 'F')
    }
  }

  return pdf
}
function* pdfGenerator(elements: ElementPDF[][]) {
  for (const element of elements) {
    yield element
  }
}

const validateDownloadIndividualPDF = [
  ReportAnalysisTypeMap.RawData,
  ReportAnalysisTypeMap.ICAComponents,
  ReportAnalysisTypeMap.BandPower,
  ReportAnalysisTypeMap.PowerSpectrum,
  ReportAnalysisTypeMap.OccipitalAlphaPeak,
  ReportAnalysisTypeMap.PowerRatio,
  ReportAnalysisTypeMap.SourceROIPower,
]

const validateDownloadHRVPDF = [
  HRVAnalysisTypeMap.TimeSeries,
  HRVAnalysisTypeMap.TimeDomain,
  HRVAnalysisTypeMap.FrequencyDomain,
  HRVAnalysisTypeMap.Nonlinear,
]
const handleGeneratePDF = async (
  t: any,
  userInfo: AnalysisUserInfo,
  contentPage: any,
  screen: 'NORM' | 'HRV' | 'EEG',
  listChecked: string[],
  onOpen: any,
) => {
  let isValidateDownloadPDF = false
  if (screen !== 'HRV') {
    isValidateDownloadPDF = validateDownloadIndividualPDF.some((value) =>
      listChecked.includes(value),
    )
  } else {
    isValidateDownloadPDF = validateDownloadHRVPDF.some((value) =>
      listChecked.includes(value),
    )
  }
  if (!isValidateDownloadPDF) {
    onOpen('Please select the index')
    return
  }
  const header: ElementPDF[] = getHeaderPDF(userInfo.name)
  const bottom: ElementPDF[] = getBottom
  const backdrop: ElementPDF[] = getBackdrop(userInfo, t, screen)

  const pdf = getJsPDF()
  pdf.addImage(background, 'PNG', 0, 0, 210, 297)
  await generatePDF(pdf, [...backdrop])
  const gen = pdfGenerator(contentPage)
  for (const pageElements of gen) {
    pdf.addPage()
    await generatePDF(pdf, [...header, ...bottom, ...pageElements])
  }
  const PDF_WIDTH = pdf.internal.pageSize.getWidth()
  const PDF_HEIGHT = pdf.internal.pageSize.getHeight()
  // render per_page
  const totalPages = pdf.getNumberOfPages()
  pdf.setFont('NotoSansKR', 'normal')
  pdf.setFontSize(8.5)
  for (let i = 3; i <= totalPages; i += 1) {
    pdf.setPage(i)
    pdf.text(`${i}/${totalPages}`, PDF_WIDTH - 15, PDF_HEIGHT - 7)
  }
  if (screen === 'NORM') {
    pdf.save(`${userInfo.name}_NormDB(EEG)_${userInfo.id}.pdf`)
  } else {
    pdf.save(`${userInfo.name}_Individual(${screen})_${userInfo.id}.pdf`)
  }
}

const PdfDownload = (props: IPdfDownloadProps) => {
  const {
    isMultipleDownload,
    screen,
    userInfo,
    contentPage,
    children,
    listChecked,
  } = props
  const classes = useStyles()
  const {onOpen} = useFailureModal()
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [checkList, setCheckList] = useState([
    {
      type: 'eeg',
      label: 'EEG',
      checked: false,
    },
    {
      type: 'hrv',
      label: 'HRV',
      checked: false,
    },
    {
      type: 'mci',
      label: 'aMCI',
      checked: false,
    },
  ])
  const {t} = useTranslation()

  const handleOpenChange = () => {
    setIsOpen((prev) => !prev)
  }

  const handleCheckAll = () => {
    if (checkList.every((item) => item.checked === true)) {
      setCheckList((prev) => prev.map((item) => ({...item, checked: false})))
    } else {
      setCheckList((prev) => prev.map((item) => ({...item, checked: true})))
    }
  }

  const handleCheckItem = (type: string) => {
    setCheckList((prev) =>
      prev.map((item) =>
        item.type === type ? {...item, checked: !item.checked} : {...item},
      ),
    )
  }

  return (
    <Container open={isOpen} width={CONTENT_WIDTH}>
      <SettingContainer onClick={handleOpenChange}>
        <SettingsIcon />
      </SettingContainer>
      <ContentContainer width={CONTENT_WIDTH}>
        <ActionContainer>
          <SelectOption className={classes.select}>
            <option value='1'>PDF</option>
          </SelectOption>
          <BtnDownload>
            <PrimaryLoadableButton
              onClick={async () => {
                setIsLoading(true)
                await handleGeneratePDF(
                  t,
                  userInfo,
                  contentPage,
                  screen,
                  listChecked,
                  onOpen,
                )
                setIsLoading(false)
              }}
              fullWidth
              loading={isLoading}
              disabled={isLoading}
            >
              <div className={classes.header}>
                <img
                  src={fileExcel}
                  alt='icon file excel'
                  className={classes.iconFileExcel}
                />
                <div className={classes.btnDownloadPdf}>{t('IDownload')}</div>
              </div>
            </PrimaryLoadableButton>
          </BtnDownload>
        </ActionContainer>
        {isMultipleDownload && (
          <SelectContainer>
            <CheckBox
              onChange={handleCheckAll}
              checked={checkList.every((item) => item.checked === true)}
            >
              {t('ISelectAll')}
            </CheckBox>
            <ListContainer>
              {checkList.map((item) => (
                <CheckBox
                  checked={item.checked}
                  onChange={() => handleCheckItem(item.type)}
                >
                  {item.label}
                </CheckBox>
              ))}
            </ListContainer>
          </SelectContainer>
        )}
        {children}
      </ContentContainer>
    </Container>
  )
}

export default PdfDownload
