import React, {Fragment, useEffect, useState} from 'react'
import {useAsync} from 'react-async-hook'
import {useTranslation} from 'react-i18next'
import {useRouteMatch} from 'react-router-dom'
import styled from 'styled-components'
import {Colors} from 'components/common/useBaseStyle'
import useLanguageReport from 'hooks/useLanguageReport'
import {
  AnalysisResultItem,
  AnalysisUserInfo,
  EEGResult,
  fetchIndividualHRVResult,
} from 'api/analysisApi'
import CheckBox from 'components/Checkbox'
import CardWithTitle from 'components/atoms/CardWithTitle'
import ReportInfo from 'components/molcules/Report/ReportInfo'
import ReportSubtitle from 'components/Text/ReportSubtitle'
import {getEyeStatus, getGenderStatus} from 'helpers/analysisHelper'
import generateTableOfContents from 'components/molcules/GenerateReportPDF/TableOfContents'
import PdfDownload, {ElementPDF} from 'components/molcules/GenerateReportPDF'
import {
  ReportCustomPadding,
  ReportFetcherWrap,
  ReportTranslationSelectWrap,
  ReportWrap,
} from 'pages/Analysis/EEGIndividual/Report'
import TranslationSelect from 'components/atoms/TranslationSelect'
import useQuery from 'hooks/useQuery'
import getContentHRVPDF, {
  getAnalysisTitle,
  checkListDownloadPdf,
} from 'components/molcules/GenerateReportPDF/HRVIndividual/helper'
import {
  isoStringToDateAndTimeString,
  parseAgeFromStrings,
} from 'helpers/dateHelper'
import {
  SelectContainer,
  ListContainer,
  Header,
} from 'components/molcules/GenerateReportPDF/styles'

const StyledReport = styled.div`
  & .report {
    text-align: center;
    & .item {
      text-align: left;
      overflow: auto;
      &.flex {
        display: flex;
      }

      & .title {
        color: ${Colors.primary};
      }
    }
    & .item.flex .graph {
      flex: 1;
    }
  }
`

const RRTimeSeriesReport = ({index, analysisType, items}: ReportItemProps) => (
  <StyledReport>
    <ReportSubtitle number={String(index + 1)}>
      {getAnalysisTitle(analysisType)}
    </ReportSubtitle>
    <div className='report'>
      <div className='item'>
        <h3 className='title'>* RR Time Series</h3>
        <img className='graph' src={items[0].imgPath} alt='' />
      </div>
      <div className='item'>
        <h3 className='title'>* Selected Detrended RR Series</h3>
        <img className='graph' src={items[1].imgPath} alt='' />
      </div>
    </div>
  </StyledReport>
)

const BaseHrvReport = ({index, analysisType, items}: ReportItemProps) => (
  <StyledReport>
    <ReportSubtitle number={String(index + 1)}>
      {getAnalysisTitle(analysisType)}
    </ReportSubtitle>
    <div className='report'>
      <div className='item flex'>
        {items.map((item) => (
          <img className='graph' src={item.imgPath} alt='' />
        ))}
      </div>
    </div>
  </StyledReport>
)

interface ReportItemProps {
  index: number
  analysisType: string
  items: AnalysisResultItem[]
}

function ReportItem(props: ReportItemProps) {
  // eslint-disable-next-line react/destructuring-assignment
  if (props.analysisType === '41') return <RRTimeSeriesReport {...props} />
  return <BaseHrvReport {...props} />
}

function HRVIndividualReport() {
  const {t, i18n} = useTranslation()
  const {
    params: {id},
  } = useRouteMatch<{id: string}>()
  const [report, setReport] = useState<EEGResult>()
  const [listChecked, setListChecked] = useState<string[]>([])
  const [contentPage, setContentPage] = useState<any>()
  const [renderingItems, setRenderingItems] = useState<{
    [key: string]: AnalysisResultItem[]
  }>()
  const [userInfo, setUserInfo] = useState<AnalysisUserInfo>({
    name: '',
    chartNo: '',
    birth: '',
    measureDate: '',
    gender: '',
    ecEo: '',
    id: '',
  })
  const clientName = useQuery().get('clientName')
  const asyncResult = useAsync(
    fetchIndividualHRVResult,
    [
      {
        individualRequestIds: [Number.parseInt(id, 10)],
        codeTypeDivision: 'HRV',
        codeType: 'I',
        codeVersion: '3.0',
        // imgType: 'WEB',
        locale: i18n.language as LanguageCode,
      },
    ],
    {
      executeOnMount: true,
      executeOnUpdate: false,
    },
  )

  const handleCheckAll = () => {
    const listId = checkListDownloadPdf.map((item) => {
      return item.id
    })
    if (listChecked.length === checkListDownloadPdf.length) {
      setListChecked([])
    } else {
      setListChecked([...listId])
    }
  }

  const handleCheckItem = (id: string) => {
    let listId = []
    if (listChecked.includes(id)) {
      listId = listChecked.filter((item) => item !== id)
    } else {
      listId = [...listChecked, id]
    }
    setListChecked([...listId])
  }

  const getRenderingItems = (
    resultDetailList: any,
  ): {[key: string]: AnalysisResultItem[]} => {
    return resultDetailList
      .sort((a: any, b: any) => {
        const parsedA = Number.parseInt(a.analysisType, 10)
        const parsedB = Number.parseInt(b.analysisType, 10)

        if (parsedA > parsedB) return 1
        if (parsedA < parsedB) return -1

        if (parsedA === parsedB) {
          if (a.psdScaleOrder > b.psdScaleOrder) return 1
          if (a.psdScaleOrder < b.psdScaleOrder) return -1
        }
        return 0
      })
      .reduce((accr: any, curr: any) => {
        // @ts-ignore
        accr[curr.analysisType] = accr[curr.analysisType] ?? []
        // @ts-ignore
        accr[curr.analysisType] = [...accr[curr.analysisType], curr]
        // @ts-ignore
        return accr
      }, {})
  }

  useEffect(() => {
    if (asyncResult.result?.list) {
      const [result] = asyncResult.result.list
      const {resultDetailList} = result
      const renderingItems = getRenderingItems(resultDetailList)

      const contentPDF: ElementPDF[][] = getContentHRVPDF(
        listChecked,
        renderingItems,
        report?.resultInfo,
        t,
      )
      setContentPage([
        generateTableOfContents(listChecked, 'HRV'),
        ...contentPDF,
      ])
    }
  }, [listChecked, report?.resultInfo])

  useEffect(() => {
    if (asyncResult.result?.list) {
      const [result] = asyncResult.result.list
      const {resultInfo} = result
      // get data for backdrop of pdf
      const age = parseAgeFromStrings(resultInfo.birth, resultInfo.measureDate)
      const userInfo: AnalysisUserInfo = {
        name: clientName ?? '',
        chartNo: resultInfo.chartNo ?? '',
        birth: `${resultInfo.birth}(${age === -1 ? '-' : age})`,
        measureDate: isoStringToDateAndTimeString(
          resultInfo.measureDate ?? '',
        ).split(' ')[0],
        gender: resultInfo.gender ? getGenderStatus(resultInfo.gender) : '',
        ecEo: resultInfo.ecEo ? getEyeStatus(resultInfo.ecEo || '-') : '',
        id: resultInfo.id,
      }
      setUserInfo(userInfo)
    }
  }, [asyncResult.result])

  useEffect(() => {
    if (asyncResult.result !== undefined) {
      const [response] = asyncResult.result.list
      if (response !== undefined) setReport(response)

      const sorted = getRenderingItems(response.resultDetailList)

      setRenderingItems(sorted)
    }
  }, [asyncResult.result])

  useEffect(() => {
    asyncResult.execute({
      individualRequestIds: [Number.parseInt(id, 10)],
      codeTypeDivision: 'HRV',
      codeType: 'I',
      codeVersion: '3.0',
      // imgType: 'WEB',
      locale: i18n.language as LanguageCode,
    })
  }, [i18n.language])

  useLanguageReport()

  return (
    <ReportWrap style={{width: '100%'}}>
      <ReportTranslationSelectWrap>
        <TranslationSelect />
      </ReportTranslationSelectWrap>
      <ReportFetcherWrap style={{width: '100%'}}>
        <div id='download-pdf'>
          <PdfDownload
            screen='HRV'
            userInfo={userInfo}
            contentPage={contentPage}
            listChecked={listChecked}
          >
            <Header>
              <img src='assets/img/PDF/bar-chart.svg' alt='' />
              <span>HRV</span>
            </Header>
            <SelectContainer>
              <ListContainer>
                <CheckBox
                  onChange={handleCheckAll}
                  checked={checkListDownloadPdf.length === listChecked.length}
                >
                  {t('ISelectAll')}
                </CheckBox>
                {checkListDownloadPdf.map((item) => (
                  <CheckBox
                    checked={listChecked.includes(item.id)}
                    onChange={() => handleCheckItem(item.id)}
                  >
                    {item.label}
                  </CheckBox>
                ))}
              </ListContainer>
            </SelectContainer>
          </PdfDownload>
        </div>
        <ReportCustomPadding />
        <CardWithTitle
          title={String(t('IHRVIndividualTitle'))}
          style={{width: '100%'}}
        >
          {asyncResult.loading && <div>{t('ILoading')}</div>}
          {!asyncResult.loading && asyncResult.error !== undefined && (
            <div>{asyncResult.error.message}</div>
          )}
          {!asyncResult.loading && report !== undefined && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 40,
                marginBottom: 50,
              }}
            >
              <ReportInfo
                {...report.resultInfo}
                clientName={clientName}
                isShowEyeStatus={false}
              />
              {renderingItems !== undefined && (
                <Fragment>
                  {Object.entries(renderingItems).map((entry, index) => {
                    const [key, value] = entry
                    return (
                      <ReportItem
                        analysisType={key}
                        index={index}
                        items={value}
                      />
                    )
                  })}
                </Fragment>
              )}
            </div>
          )}
          {!asyncResult.loading &&
            asyncResult.error === undefined &&
            report === undefined && (
              <div style={{minHeight: 400}}>출력할 결과지가 없습니다.</div>
            )}
        </CardWithTitle>
        <ReportCustomPadding />
      </ReportFetcherWrap>
      <ReportCustomPadding />
    </ReportWrap>
  )
}

export default HRVIndividualReport
