import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
  IndividualMCISearchRequest,
  SearchedIndividualMCI,
  searchIndividualMCIApi,
} from 'api/analysisApi'
import {openFailureModalAction} from 'features/modal/alertSlice'
import {getDefaultPeriodString, periodStringToDate} from 'helpers/dateHelper'
import {RootState} from 'store'

export const searchEegMci = createAsyncThunk(
  'api/eeg/mci/search',
  async (payload: IndividualMCISearchRequest, {rejectWithValue, dispatch}) => {
    try {
      const response = await searchIndividualMCIApi(payload)
      return response.data
    } catch (err) {
      await dispatch(openFailureModalAction(err.message))
      return rejectWithValue(err.message)
    }
  },
)

interface EegMciState {
  loading: boolean
  error: any
  items: SearchedIndividualMCI[]
  period: PeriodString
  search: AnalysisSearchKind
  paging: Paging
  pagingInfo: {
    totalPages: number
    totalElements: number
  }
}

const initialState: EegMciState = {
  loading: false,
  error: null,
  items: [],
  period: getDefaultPeriodString(),
  search: {searchName: 'NAME', searchValue: ''},
  paging: {page: 0, size: 10},
  pagingInfo: {
    totalPages: 1,
    totalElements: 0,
  },
}

const slice = createSlice({
  name: 'eegMciSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(searchEegMci.pending, (state, action) => {
      state.loading = true
      state.error = null

      const {period, search, paging} = action.meta.arg
      state.period = period
      state.search = search
      state.paging = paging
    })
    builder.addCase(searchEegMci.fulfilled, (state, action) => {
      state.loading = false

      const {
        content: items,
        pageable: {pageNumber: page},
        size,
        totalPages,
        totalElements,
      } = action.payload
      state.items = items
      state.loading = false
      state.error = null
      state.paging.page = page
      state.paging.size = size
      state.pagingInfo.totalPages = totalPages
      state.pagingInfo.totalElements = totalElements
    })
    builder.addCase(searchEegMci.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export default slice.reducer

export const selectLoading = (state: RootState) => state.eegMci.loading
export const selectError = (state: RootState) => state.eegMci.error
export const selectItems = (state: RootState) => state.eegMci.items
export const selectPeriod = (state: RootState) =>
  periodStringToDate(state.eegMci.period)
export const selectSearch = (state: RootState) => state.eegMci.search
export const selectPaging = (state: RootState) => state.eegMci.paging
export const selectPagingInfo = (state: RootState) => state.eegMci.pagingInfo
export const selectQuery = (state: RootState) => ({
  period: state.eegMci.period,
  search: state.eegMci.search,
  paging: state.eegMci.paging,
})
