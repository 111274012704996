import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation} from 'react-router-dom'
import {OutlinedInput} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import {ClientName} from 'components/atoms/ClientName'
import SearchBar from 'components/Forms/SearchBar'
import Pagination from 'components/molcules/Pagination'
import TableCell from 'components/Table/TableCell'
import useStyles from 'components/Table/useTableStyles'
import {useWhiteListCreateDialog} from 'features/patient/usePatientWhiteListDialog'
import useToolbarStyles, {
  useOutlinedInputStyles,
} from 'components/Table/useTableToolbarStyles'
import useFailureModal from 'features/modal/useFailureModal'
import {useCreateDialog} from 'features/patient/usePatientDialog'
import usePatientSearch from 'features/patient/usePatientSearch'
import {PatientSearchRequest} from 'api/patientApi'
import {getQueryFromUrl} from 'helpers/commonHelper'
import {isoStringToDateString} from 'helpers/dateHelper'

const SEARCH_SELECT_ITEMS: SelectItem<PatientSearchKind>[] = [
  {label: 'IChartNo', value: 'CHART_NO'},
  {label: 'ICustomerName', value: 'NAME'},
  {label: 'IBirthday', value: 'BIRTH'},
]

const SEARCH_SELECT_ITEMS_WHITE_LIST: SelectItem<PatientSearchKind>[] = [
  {label: 'IChartNo', value: 'CHART_NO'},
  {label: 'IRegistrationNumber', value: 'VISIT_NO'},
  {label: 'ICustomerName', value: 'NAME'},
  {label: 'IBirthday', value: 'BIRTH'},
]
interface HeadCell {
  id: keyof Patient | 'description' | 'treatment' | 'action'
  label: string
}

const HEAD_CELLS: HeadCell[] = [
  {id: 'chartNo', label: 'IChartNo_no'},
  {id: 'name', label: 'ICustomerName'},
  {id: 'birth', label: 'IBirthday'},
  {id: 'gender', label: 'IGender'},
  {id: 'description', label: 'IDetailInfo'},
  {id: 'latestDate', label: 'ILastScanDate'},
  {id: 'primaryName', label: 'IManager'},
  {id: 'action', label: 'IComment'},
]
const HEAD_CELLS_WHITE_LIST: HeadCell[] = [
  {id: 'chartNo', label: 'IChartNo_no'},
  {id: 'latestVisitNo', label: 'IRegistrationNumber'},
  {id: 'name', label: 'ICustomerName'},
  {id: 'birth', label: 'IBirthday'},
  {id: 'gender', label: 'IGender'},
  {id: 'description', label: 'IDetailInfo'},
  {id: 'latestDate', label: 'ILastScanDate'},
  {id: 'primaryName', label: 'IManager'},
  {id: 'action', label: 'IComment'},
]
interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>
  numSelected: number
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  rowCount: number
  isWhiteList: boolean
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {t} = useTranslation()
  const {classes, onSelectAllClick, numSelected, rowCount, isWhiteList} = props
  const [headCells, setHeadCells] = React.useState<HeadCell[]>([])

  useEffect(() => {
    if (isWhiteList) {
      setHeadCells([...HEAD_CELLS_WHITE_LIST])
    } else {
      setHeadCells([...HEAD_CELLS])
    }
  }, [isWhiteList])
  return (
    <TableHead>
      <TableRow className={classes.tableHeader}>
        <TableCell padding='checkbox'>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{'aria-label': 'select all desserts'}}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align='center' padding='none'>
            {t(headCell.label)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface EnhancedTableToolbarProps {
  onDelete: () => void
  isSelected: boolean
  isWhiteList: boolean
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const location = useLocation()
  const history = useHistory()
  const {t} = useTranslation()
  const classes = useToolbarStyles()
  const outlinedInputClasses = useOutlinedInputStyles()
  const {onDelete, isSelected, isWhiteList} = props

  const {query: queryState, onSearch} = usePatientSearch()
  // Refactor onSearch function to save query params to URL
  const newOnSearch = (queryOnSearch: PatientSearchRequest) => {
    try {
      const params = new URLSearchParams(
        Object.entries(queryOnSearch).map(([key, value]) => [
          key,
          typeof value === 'object' ? JSON.stringify(value) : value,
        ]),
      )
      history.replace({pathname: location.pathname, search: params.toString()})
    } catch (error) {
      console.error('Update URLSearchParams failed', error)
    }
    return onSearch(queryOnSearch)
  }
  const {onOpen: onPatientRegistrationDialogOpen} = useCreateDialog()
  const [searchCondition, setSearchCondition] =
    React.useState<PatientSearchKind>('CHART_NO')
  const [listSelectItem, setListSelectItem] = React.useState<
    SelectItem<PatientSearchKind>[]
  >([])
  const [searchKeyword, setSearchKeyword] = React.useState('')

  const handleSearchChanged = (event: React.ChangeEvent<{value: unknown}>) =>
    setSearchCondition(event.target.value as PatientSearchKind)

  const handleKeywordSearch = () => {
    const {search, paging: defaultPaging} = queryState
    const {searchName, searchValue} = search

    let paging = {...defaultPaging}
    if (searchName !== searchCondition || searchValue !== searchKeyword) {
      // README: 검색 조건, 키워드 변하면 첫 페이지 부터 다시 검색
      paging = {...defaultPaging, page: 0}
    }
    newOnSearch({
      ...queryState,
      search: {
        searchName: searchCondition,
        searchValue: searchKeyword,
      },
      paging,
    })
  }
  const {onOpen: onOpenRegistrationWhiteList} = useWhiteListCreateDialog()
  const handleAddClick = () => {
    if (isWhiteList) {
      onOpenRegistrationWhiteList()
    } else {
      onPatientRegistrationDialogOpen()
    }
  }

  const handleValue = (value: any) => {
    setSearchKeyword(value)
  }

  /** Set default value for search condition if it is stored in url */
  useEffect(() => {
    const initQuery = getQueryFromUrl(location.search)
    if (initQuery?.searchName || initQuery?.search?.searchName) {
      setSearchCondition(initQuery?.searchName || initQuery?.search?.searchName)
    }
    newOnSearch({
      ...queryState,
      paging: {page: 0, size: 10},
      ...initQuery,
    })
  }, [])
  useEffect(() => {
    if (isWhiteList) {
      setListSelectItem([...SEARCH_SELECT_ITEMS_WHITE_LIST])
    } else {
      setListSelectItem([...SEARCH_SELECT_ITEMS])
    }
  }, [isWhiteList])
  return (
    <div className={classes.root}>
      <div className={classes.searchContainer}>
        {/* <div style={{zIndex: 1000}}> */}
        {/* <RangeDatePicker
            onChangeValue={handleDateValue}
            dateValue={{
              startDate: new Date(),
              endDate: new Date(),
            }}
          /> */}
        {/* </div> */}

        <FormControl variant='outlined' className={classes.searchSelect}>
          <Select
            value={searchCondition}
            onChange={handleSearchChanged}
            input={
              <OutlinedInput
                name='age'
                id='outlined-age-simple'
                classes={outlinedInputClasses}
              />
            }
          >
            {listSelectItem.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {t(item.label)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <SearchBar
          onChangeValue={handleValue}
          onSearchEvent={handleKeywordSearch}
          disabledValue={false}
        />
      </div>
      <div className={`${classes.container} ${classes.containerOptional}`}>
        <ActionButton startIcon={<AddIcon />} onClick={handleAddClick}>
          {t('IAdd')}
        </ActionButton>
        {/* <ActionButton
          startIcon={<GetAppIcon />}
          className={classes.downloadButton}
        >
          {t('IExcelDownload')}
        </ActionButton> */}
        <ActionButton
          startIcon={<DeleteIcon />}
          onClick={onDelete}
          disabled={!isSelected}
        >
          {t('IDelete')}
        </ActionButton>
      </div>
    </div>
  )
}

interface PatientTableProps {
  onPatientClick: (uuid: string) => void
  onPatientSelect: (uuid: string) => void
  isWhiteList: boolean
}

export default function PatientTable(props: PatientTableProps) {
  const classes = useStyles()
  const {onPatientClick, onPatientSelect, isWhiteList} = props
  const location = useLocation()
  const history = useHistory()
  const {t} = useTranslation()
  const {open: openCreateClient} = useWhiteListCreateDialog()
  const {
    patients: patientList,
    loading: patientLoading,
    query: queryState,
    paging: pagingState,
    pagingInfo,
    onSearch,
    onDelete,
    onRefresh,
  } = usePatientSearch()
  const initQuery = getQueryFromUrl(location.search)
  // Refactor onSearch function to save query params to URL
  const newOnSearch = (queryOnSearch: PatientSearchRequest) => {
    try {
      const params = new URLSearchParams(
        Object.entries(queryOnSearch).map(([key, value]) => [
          key,
          typeof value === 'object' ? JSON.stringify(value) : value,
        ]),
      )
      history.replace({pathname: location.pathname, search: params.toString()})
    } catch (error) {
      console.error('Update URLSearchParams failed', error)
    }
    return onSearch(queryOnSearch)
  }
  const {onOpen: onFailureModalOpen} = useFailureModal()

  const setPageIndex = (page: number) => {
    newOnSearch({
      ...queryState,
      paging: {page, size: queryState.paging.size},
      search: initQuery.search,
    })
  }

  const [selected, setSelected] = React.useState<string[]>([])
  const rowsPerPage = 5

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = patientList.map((n) => n.uuid)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event: React.MouseEvent<unknown>, uuid: string) => {
    const selectedIndex = selected.indexOf(uuid)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, uuid)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }
    setSelected(newSelected)
  }

  const isSelected = (name: string) => selected.indexOf(name) !== -1

  const emptyRows = rowsPerPage - patientList.length

  const handleDelete = async () => {
    if (selected.length > 0) {
      await onDelete(selected)
      setPageIndex(pagingState.page)
    } else {
      onFailureModalOpen(t('IOrgUserSelect'))
    }
  }

  useEffect(() => {
    const initQuery = getQueryFromUrl(location.search)
    if (openCreateClient === false)
      onRefresh({
        ...queryState,
        paging: {page: 0, size: 10},
        ...initQuery,
      })
  }, [openCreateClient])

  return (
    <TableContainer className={classes.container}>
      <EnhancedTableToolbar
        onDelete={handleDelete}
        isSelected={selected.length > 0}
        isWhiteList={isWhiteList}
      />
      <Table
        stickyHeader
        className={classes.table}
        aria-labelledby='tableTitle'
        aria-label='enhanced table'
        size='small'
      >
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          onSelectAllClick={handleSelectAllClick}
          // onRequestSort={handleRequestSort}
          rowCount={patientList.length}
          isWhiteList={isWhiteList}
        />
        <TableBody>
          {patientList.map((row, index) => {
            const isItemSelected = isSelected(row.uuid)
            const labelId = `enhanced-table-checkbox-${index}`

            return (
              <TableRow
                hover
                onClick={(event) => handleClick(event, row.uuid)}
                role='checkbox'
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.uuid}
                selected={isItemSelected}
              >
                <TableCell padding='checkbox'>
                  <Checkbox
                    checked={isItemSelected}
                    inputProps={{'aria-labelledby': labelId}}
                  />
                </TableCell>
                <TableCell align='center'>{row.chartNo}</TableCell>
                {isWhiteList && (
                  <TableCell align='center'>{row.latestVisitNo}</TableCell>
                )}
                <TableCell align='center'>
                  <ClientName
                    firstName={row.firstName}
                    lastName={row.lastName}
                  />
                </TableCell>
                <TableCell align='center'>{row.birth}</TableCell>
                <TableCell align='center'>{row.gender}</TableCell>
                <TableCell align='center'>
                  <Button
                    variant='outlined'
                    size='small'
                    className={classes.rowButton}
                    onClick={() => {
                      onPatientClick(row.uuid)
                    }}
                  >
                    {t('IDetailInfo')}
                  </Button>
                </TableCell>
                <TableCell align='center'>{row.latestDate ?? ''}</TableCell>
                <TableCell align='center'>
                  {t('IGetFullName', {
                    firstName: row.primaryFirstName ?? '',
                    lastName: row.primaryLastName ?? '',
                  })}
                </TableCell>
                <TableCell align='center'>
                  <Button
                    color='primary'
                    variant='outlined'
                    size='small'
                    className={classes.rowButton}
                    onClick={(event) => {
                      event.stopPropagation()
                      onPatientSelect(row.uuid)
                    }}
                  >
                    {t('IDetail')}
                  </Button>
                </TableCell>
              </TableRow>
            )
          })}
          {emptyRows > 0 && (
            <TableRow
              style={{height: 43 * emptyRows, backgroundColor: '#F9F9FB'}}
            >
              <TableCell colSpan={12} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Pagination
        totalPageCount={pagingInfo.totalPages}
        currentPageIndex={pagingState.page}
        itemCountPerPage={pagingState.size}
        setCurrentPageIndex={setPageIndex}
        loading={patientLoading}
        onItemCountPerPageChanged={(event) => {
          newOnSearch({
            ...queryState,
            paging: {
              page: 0,
              size: parseInt(event.target.value as string, 10),
            },
            search: initQuery.search,
          })
        }}
      />
    </TableContainer>
  )
}
