import logo from 'assets/img/logo-pdf.png'
import {AnalysisUserInfo} from 'api/analysisApi'
import {ElementPDF} from 'components/molcules/GenerateReportPDF/index'
import {makeDateFormat} from 'helpers/dateHelper'

const getTitleBackdrop = (screen: 'NORM' | 'HRV' | 'EEG', t: any) => {
  if (screen === 'EEG') {
    return t('ITitlePDFIndividual')
  }
  if (screen === 'NORM') {
    return t('ITitlePDFNorm')
  }
  return t('ITitlePDFHRV')
}

const getTitleAnalysisNo = (screen: 'NORM' | 'HRV' | 'EEG', t: any) => {
  if (screen === 'EEG') {
    return t('IAnalysisNo')
  }
  if (screen === 'NORM') {
    return t('IAnalysisNo')
  }
  return t('IAnalysisNoHRV')
}

const getBackdrop = (
  userInfo: AnalysisUserInfo,
  t: any,
  screen: 'NORM' | 'HRV' | 'EEG',
): ElementPDF[] => [
  {
    content: getTitleBackdrop(screen, t),
    type: 'text',
    fontSize: 28,
    x: 15,
    y: 40,
    fontStyle: 'bold',
    uppercase: false,
  },
  {
    content: t('INameV2'),
    type: 'text',
    fontStyle: 'bold',
    fontSize: 8,
    x: 15,
    y: 70,
    uppercase: false,
    color: '#0c978c',
  },
  {
    content: userInfo.name,
    type: 'text',
    fontSize: 10,
    x: 15,
    y: 75,
    uppercase: false,
  },
  {
    content: getTitleAnalysisNo(screen, t),
    type: 'text',
    fontSize: 8,
    x: 70,
    y: 70,
    uppercase: false,
    color: '#0c978c',
  },
  {
    content: userInfo.id.toString(),
    type: 'text',
    fontSize: 10,
    x: 70,
    y: 75,
    uppercase: false,
  },
  {
    content: t('IBirthday'),
    type: 'text',
    fontSize: 8,
    x: 15,
    y: 90,
    uppercase: false,
    color: '#0c978c',
  },
  {
    content: userInfo.birth,
    type: 'text',
    fontSize: 10,
    x: 15,
    y: 95,
    uppercase: false,
  },
  {
    content: t('IMeasurementDate'),
    type: 'text',
    fontSize: 8,
    x: 70,
    y: 90,
    uppercase: false,
    color: '#0c978c',
  },
  {
    content: userInfo.measureDate,
    type: 'text',
    fontSize: 10,
    x: 70,
    y: 95,
    uppercase: false,
  },
  {
    content: t('IGender'),
    type: 'text',
    fontSize: 8,
    x: 15,
    y: 110,
    color: '#0c978c',
    uppercase: false,
  },
  {
    content: userInfo.gender ?? '',
    type: 'text',
    fontSize: 10,
    x: 15,
    y: 115,
    uppercase: false,
  },
  {
    content: screen === 'HRV' ? '' : t('IECEO'),
    type: 'text',
    fontSize: 8,
    x: 70,
    y: 110,
    uppercase: false,
    color: '#0c978c',
  },
  {
    content: screen === 'HRV' ? '' : userInfo.ecEo ?? '',
    type: 'text',
    fontSize: 10,
    x: 70,
    y: 115,
    uppercase: false,
  },
  {
    content: t('IContentPDFIndividual'),
    type: 'text',
    fontSize: 7,
    fontStyle: 'normal',
    x: 15,
    y: 140,
    uppercase: false,
    maxWidth: 120,
    color: '#6f6f6f',
  },
  {
    type: 'line',
    content: '',
    x: 15,
    y: 82,
    x1: 55,
    y1: 82,
    color: '#e4e4e4',
  },
  {
    type: 'line',
    content: '',
    x: 70,
    y: 82,
    x1: 110,
    y1: 82,
    color: '#e4e4e4',
  },
  {
    type: 'line',
    content: '',
    x: 15,
    y: 102,
    x1: 55,
    y1: 102,
    color: '#e4e4e4',
  },
  {
    type: 'line',
    content: '',
    x: 70,
    y: 102,
    x1: 110,
    y1: 102,
    color: '#e4e4e4',
  },
  {
    type: 'image',
    content: logo,
    x: 12,
    y: 270,
    imageWidth: 28,
    imageHeight: 6,
  },
  {
    type: 'line',
    content: '',
    x: 45,
    y: 270,
    x1: 45,
    y1: 276,
    color: '#c3c3c3',
  },
]

export const getBottom: ElementPDF[] = [
  {
    type: 'line',
    content: '',
    lineHeight: 0.4,
    x: 10,
    y: 282,
  },
  {
    type: 'image',
    content: logo,
    x: 12,
    y: 285,
    imageWidth: 28,
    imageHeight: 6,
  },

  {
    type: 'text',
    content: 'Copyright © iMediSync Inc. All rights reserved.',
    x: 50,
    y: 290,
    fontSize: 10,
    fontStyle: 'bold',
    uppercase: false,
  },
]

export const getHeaderPDF = (clientName: string): ElementPDF[] => [
  {
    type: 'text',
    content: clientName,
    x: 12,
    y: 13,
    fontSize: 10,
    fontStyle: 'normal',
    uppercase: false,
  },
  {
    type: 'text',
    content: makeDateFormat(new Date()),
    x: 179,
    y: 13,
    fontSize: 10,
    fontStyle: 'normal',
    uppercase: false,
  },
  {
    type: 'line',
    content: '',
    x: 10,
    y: 15,
  },
]

export const copyRight: ElementPDF[][] = [
  [
    {
      type: 'text',
      content: '[저작권]',
      fontStyle: 'bold',
      fontSize: 12,
      uppercase: false,
      x: 10,
      y: 23,
    },
    {
      type: 'text',
      content: 'Copyright c 2019 주식회사 아이메디신',
      fontStyle: 'normal',
      fontSize: 8,
      color: '#595959',
      uppercase: false,
      x: 10,
      y: 27,
    },
    {
      type: 'text',
      content:
        '이 분석결과지는 저작권법에 의해 보호받는 저작물입니다. 주식회사 아이메디신의 사전 서면 동의 없이 분석결과지의 일부 또는 전체를 복제, 공중 송신, 배포, 번역하 거나 전자 매체 또는 기계가 읽을 수 있는 형태로 바꿀 수 없으며, 무단 도용, 복제 및 사용 시에는 관계 법령에 의거하여 처벌 받을 수 있습니다.',
      fontStyle: 'normal',
      fontSize: 8,
      color: '#595959',
      uppercase: false,
      x: 10,
      y: 37,
    },
    {
      type: 'text',
      content: '[등록상표권]',
      fontStyle: 'bold',
      fontSize: 12,
      uppercase: false,
      x: 10,
      y: 57,
    },
    {
      type: 'text',
      content:
        '아이메디신, iMediSync, iSyncBrain, iSyncHeart, iSyncBeat 로고는 주식회사 아이메디신의 등록상표입니다.',
      fontStyle: 'normal',
      fontSize: 8,
      color: '#595959',
      uppercase: false,
      x: 10,
      y: 61,
    },
  ],
]
export default getBackdrop
