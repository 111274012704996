import React, {useEffect, useMemo, useState} from 'react'
import {useAsync} from 'react-async-hook'
import appHistory from 'AppHistory'
import {useTranslation} from 'react-i18next'
import {CircularProgress} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import ReplayIcon from '@material-ui/icons/Replay'
import {
  AnalysisResultInfo,
  AnalysisResultItem,
  fetchIndividualEEGResult,
  FetchIndividualEEGResultRequest,
  fetchIndividualEegStatus,
  IcaAnalysisResultIndicator,
  TypeScreenGraph,
  fetchIndividualStatus,
} from 'api/analysisApi'
import CardWithTitle from 'components/atoms/CardWithTitle'
import ReportInfo from 'components/molcules/Report/ReportInfo'
import {Settings} from 'components/Settings'
import {
  AnalysisDivision,
  CodeType,
  CodeTypeMap,
  ReportIcaAnalysisTypeMap,
} from 'constants/AnalysisConstant'
import {getEegIndividualReportPath} from 'constants/RouteConstant'
import useFailureModal from 'features/modal/useFailureModal'
import useReloadImg from 'features/analysis/useReloadImg'
import {IcaReportItem} from 'pages/Analysis/EEGIndividual/ReAnalysis/IcaReport/IcaReportItem'
import IcaSettings from 'pages/Analysis/EEGIndividual/ReAnalysis/IcaReport/IcaSettings'
import ReportLoading from 'pages/Analysis/EEGIndividual/ReAnalysis/ReportLoading'

export type RenderingItem = [IcaAnalysisResultIndicator, AnalysisResultItem[]]

export type IcaProcess =
  | 'ica_process_idle'
  | 'ica_process_ica'
  | 'ica_process_tsr'
  | 'ica_process_loading'
  | 'ica_process_success'
  | 'ica_process_failure'

interface ReportProps {
  readonly id: number
  readonly clientName: string
  readonly screen: TypeScreenGraph
}

function IcaReportFetcher({id, clientName, screen}: ReportProps) {
  const {t, i18n} = useTranslation()

  const codeTypeDivision: AnalysisDivision = 'EEG'
  const codeType: CodeType = CodeTypeMap.Individual
  const locale = i18n.language

  const params: FetchIndividualEEGResultRequest = {
    individualRequestIds: [id],
    codeTypeDivision,
    codeType,
    codeVersion: '3.0',
    locale,
  }
  const asyncResult = useAsync(fetchIndividualEEGResult, [params], {
    executeOnMount: true,
    executeOnUpdate: false,
  })
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {reloadImg} = useReloadImg()

  const [icaProcessing, setIcaProcessing] =
    useState<IcaProcess>('ica_process_ica')
  const [renderingItems, setRenderingItems] = useState<RenderingItem[]>()
  const [resultInfo, setResultInfo] = useState<AnalysisResultInfo>()
  const [componentList, setComponentList] = useState<number[]>([])
  const [open, setOpen] = useState(false)
  const [dateUpdate, setDateUpdate] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [onFetching, setOnFetching] = useState(false)

  function isBGreaterThanA(a: string, b: string) {
    const timeA = new Date(a).getTime()
    const timeB = new Date(b).getTime()
    return timeB > timeA
  }

  const fetchStatusRecursively = async () => {
    setLoading(true)
    try {
      const {data} = await fetchIndividualStatus(id)
      const {statusUpdateTime, status} = data
      if (
        statusUpdateTime &&
        dateUpdate &&
        isBGreaterThanA(dateUpdate, statusUpdateTime) &&
        status !== 'PROCESSING'
      ) {
        setDateUpdate(statusUpdateTime)
        reloadImg()
        setLoading(false)
        setOnFetching(false)
      }
    } catch (error) {
      setLoading(false)
      setOnFetching(false)
      console.error('Error fetching status:', error)
    }
  }

  const getFirstDate = async () => {
    const response = await fetchIndividualStatus(id)
    setDateUpdate(response.data.statusUpdateTime)
  }

  const clientNameHandler = useMemo(() => {
    const userNameFromURL = JSON.parse(decodeURIComponent(clientName))
    return t('IGetFullName', {
      firstName: userNameFromURL?.firstName ?? '',
      lastName: userNameFromURL?.lastName ?? '',
      interpolation: {escapeValue: false},
    })
  }, [clientName])

  useEffect(() => {
    if (asyncResult.result?.list) {
      const [result] = asyncResult.result.list

      const {resultDetailList, resultInfo, componentList} = result
      setComponentList(componentList)

      const rawData: RenderingItem = [
        {
          codeId: ReportIcaAnalysisTypeMap.RawData,
          codeName: 'Raw data(Bandpass filtered)',
          codePrintYn: 'Y',
          codeSort: 1,
          titleDescription: '',
        },
        resultDetailList
          .filter((i) => i.analysisType === '1')
          .filter((i) => i.subType === 0)
          .filter((i) => i.bandType === 0)
          .filter((i) => i.componentType === 0)
          .filter((i) => i.psdScaleOrder === 2),
      ]

      const componentTimeSeries: RenderingItem = [
        {
          codeId: ReportIcaAnalysisTypeMap.ComponentDataTimeSeries,
          codeName: 'Component Data Time series',
          codePrintYn: 'Y',
          codeSort: 2,
          titleDescription: '',
        },
        resultDetailList
          .filter((i) => i.analysisType === '12')
          .filter((i) => i.subType === 0)
          .filter((i) => i.bandType === 0)
          .filter((i) => i.componentType === 0),
      ]

      const componentData: RenderingItem = [
        {
          codeId: ReportIcaAnalysisTypeMap.ComponentData,
          codeName: 'Component Data',
          codePrintYn: 'Y',
          codeSort: 3,
          titleDescription: '',
        },
        resultDetailList.filter((i) => i.analysisType === '12'),
      ]

      const cleanedData: RenderingItem = [
        {
          codeId: ReportIcaAnalysisTypeMap.CleanedData,
          codeName: 'Cleaned data(Common average reference)',
          codePrintYn: 'Y',
          codeSort: 4,
          titleDescription: '',
        },
        resultDetailList
          .filter((i) => i.analysisType === '1')
          .filter((i) => i.subType === 0)
          .filter((i) => i.bandType === 0)
          .filter((i) => i.componentType === 0)
          .filter((i) => i.psdScaleOrder === 3),
      ]

      setRenderingItems([
        rawData,
        componentTimeSeries,
        componentData,
        cleanedData,
      ])
      setResultInfo(resultInfo)
    }
  }, [asyncResult.result])

  useEffect(() => {
    if (!onFetching) return
    const intervalId = setInterval(fetchStatusRecursively, 3000)
    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId)
  }, [onFetching])

  useEffect(() => {
    if (id) getFirstDate()
  }, [id])

  const fetchStatus = async () => {
    try {
      const {data: status} = await fetchIndividualEegStatus(id)
      switch (status) {
        case 'SUCCESS':
          setIcaProcessing('ica_process_success')
          setLoading(false)
          break
        case 'ICA':
          setIcaProcessing('ica_process_ica')
          setLoading(false)
          break
        case 'TIME_REJECTION':
          setIcaProcessing('ica_process_tsr')
          setLoading(false)
          break
        case 'PROCESSING':
          setIcaProcessing('ica_process_loading')
          break
        case 'ERROR':
          setIcaProcessing('ica_process_failure')
          setLoading(false)
          break
        default:
          break
      }
    } catch (err) {
      setIcaProcessing('ica_process_failure')
    }
  }

  useEffect(() => {
    if (
      icaProcessing === 'ica_process_success' ||
      icaProcessing === 'ica_process_tsr'
    ) {
      appHistory.push(
        getEegIndividualReportPath(id, undefined, clientName || ''),
      )
      window.location.reload()
      return
    }

    if (icaProcessing === 'ica_process_failure') {
      onFailureModalOpen(t('IRetry'))
    }

    if (icaProcessing !== 'ica_process_loading') return
    setLoading(true)
    const intervalId = setInterval(async () => {
      await fetchStatus()
    }, 3000)
    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId)
  }, [icaProcessing])

  return (
    <CardWithTitle style={{width: '100%'}} title='ICA'>
      <div
        style={{
          minHeight: 'calc(100vh - 91px - 77px - 77px - 15px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        {asyncResult.loading && (
          <CircularProgress size='3rem' color='primary' />
        )}
        {asyncResult.error && (
          <div>
            {asyncResult.error.message}
            <IconButton
              onClick={() => asyncResult.execute(params)}
              size='small'
            >
              <ReplayIcon />
            </IconButton>
          </div>
        )}
        <div style={{width: '100%'}}>
          {resultInfo !== undefined && (
            <ReportInfo {...resultInfo} clientName={clientNameHandler} />
          )}
          {renderingItems !== undefined &&
            renderingItems.map((item, index) => (
              <IcaReportItem
                key={index}
                renderingItem={item}
                codeType={codeType}
                screen={screen}
              />
            ))}
        </div>
      </div>
      {(icaProcessing === 'ica_process_loading' || loading) && (
        <ReportLoading />
      )}
      <Settings open={open} onOpen={() => setOpen((o) => !o)}>
        <IcaSettings
          components={componentList}
          requestId={id}
          onProcess={(isPreview?: boolean) => {
            if (isPreview) {
              fetchStatusRecursively()
              setOnFetching(true)
            }
            setIcaProcessing('ica_process_loading')
          }}
        />
      </Settings>
    </CardWithTitle>
  )
}

export default IcaReportFetcher
