import {ElementPDF} from 'components/molcules/GenerateReportPDF/index'
import {
  HRVAnalysisTypeMap,
  OVERVIEW_ANALYSIS,
  REFERENCE_ANALYSIS,
  ReportAnalysisTypeMap,
} from 'constants/AnalysisConstant'

const TITLE: ElementPDF[] = [
  {
    type: 'text',
    content: 'CONTENTS',
    fontStyle: 'bold',
    fontSize: 12,
    uppercase: false,
    x: 10,
    y: 30,
  },
]

const Y_START = 30
const FIXED_LINE = 5.5

type Types = 'h1' | 'h2' | 'h3'
type LineProps = {
  content: {
    type?: Types
    text: string
    page: number
  }
  index: number
}

const X_VALUE = {
  h1: {
    x: 11,
    fontSize: 10,
    color: '#1a1a1a',
  },
  h2: {
    x: 20,
    fontSize: 10,
    color: '#1a1a1a',
  },
  h3: {
    x: 30,
    fontSize: 9,
    color: '#4d4d4d',
  },
}

function getXValue(type: Types = 'h3'): {
  x: number
  fontSize: number
  color: string
} {
  return X_VALUE[type]
}

function renderLine({content, index}: LineProps): ElementPDF[] {
  const {x, fontSize, color} = getXValue(content?.type)
  return [
    {
      type: 'text',
      content: content.text,
      fontStyle: 'normal',
      fontSize,
      color,
      uppercase: false,
      x,
      y: Y_START + index * FIXED_LINE,
    },
    {
      type: 'line',
      color: '#bebebe',
      content: '',
      x: 100,
      y: Y_START + index * FIXED_LINE,
      x1: 190,
      y1: Y_START + index * FIXED_LINE,
      lineDash: true,
    },
    {
      type: 'text',
      content: `${content.page}`,
      fontStyle: 'normal',
      fontSize: 10,
      color: '#1a1a1a',
      uppercase: false,
      x: 192,
      y: Y_START + index * FIXED_LINE,
    },
  ]
}

function renderTitle(
  number: number,
  index: number,
  page: number,
  text: string,
): ElementPDF[] {
  return renderLine({
    content: {
      text: `${number}. ${text}`,
      type: 'h2',
      page,
    },
    index,
  })
}

const ICAComponent = (
  number: number,
  index: number,
  page: number,
): ElementPDF[] => {
  const data: ElementPDF[] = [
    ...renderTitle(number, index, page, 'ICA components'),
  ]
  let i = 0
  let newPage = page
  while (i < 19) {
    const text = `${number}-${i + 1} Component${i + 1}`
    data.push(
      ...renderLine({
        content: {
          text,
          page: newPage,
        },
        index: index + i + 1,
      }),
    )
    if (i % 2 !== 0) newPage += 1
    i += 1
  }
  return data
}

const bandPower = (
  number: number,
  index: number,
  page: number,
): ElementPDF[] => {
  const text = ['Band', 'Absolute', 'Relative']
  const data: ElementPDF[] = [
    ...renderTitle(number, index, page, 'Band power – Topomap'),
  ]
  let newPage = page
  for (let i = 0; i < text.length; i += 1) {
    data.push(
      ...renderLine({
        content: {
          text: `${number}-${i + 1} ${text[i]}`,
          page: newPage,
        },
        index: index + i + 1,
      }),
    )
    newPage += 1
  }

  return data
}

const powerSpectrum = (
  number: number,
  index: number,
  page: number,
): ElementPDF[] => {
  const text = ['Absolute (μV2/Hz)', 'Absolute (dB/Hz)', 'Relative']
  const data: ElementPDF[] = [
    ...renderTitle(number, index, page, 'Power spectrum'),
  ]
  let newPage = page
  for (let i = 0; i < text.length; i += 1) {
    data.push(
      ...renderLine({
        content: {
          text: `${number}-${i + 1} ${text[i]}`,
          page: newPage,
        },
        index: index + i + 1,
      }),
    )
    newPage += 1
  }

  return data
}

const sourceROI = (
  number: number,
  index: number,
  page: number,
): ElementPDF[] => {
  const text = ['ALL-Absolute', 'ALL-Relative', 'DMN-Absolute', 'DMN-Relative']
  const data: ElementPDF[] = [
    ...renderTitle(number, index, page, 'Source ROI power & connectivity'),
  ]
  let newPage = page
  for (let i = 0; i < text.length; i += 1) {
    data.push(
      ...renderLine({
        content: {
          text: `${number}-${i + 1} ${text[i]}`,
          page: newPage,
        },
        index: index + i + 1,
      }),
    )
    newPage += 9
  }

  return data
}

const renderParentTitle = (
  index: number,
  page: number,
  text: string,
): ElementPDF[] => {
  return renderLine({
    content: {
      text,
      type: 'h1',
      page,
    },
    index,
  })
}

const generateTableOfContents = (
  listChecked: string[],
  screen: 'EEG' | 'NORM' | 'HRV',
): ElementPDF[] => {
  let number = 1
  let index = 1
  let page = 3
  let data: ElementPDF[] = [...TITLE]
  let countOverview = 2

  data = [...data, ...renderParentTitle(index, page, `I. Personal Data`)]
  if (screen !== 'HRV') page += 1
  index += 1

  if (screen !== 'HRV') {
    data = [...data, ...renderParentTitle(index, page, `II.EEG`)]
    index += 1
  } else {
    data = [...data, ...renderParentTitle(index, page, `II.HRV`)]
    index += 1
  }

  if (listChecked.includes(ReportAnalysisTypeMap.RawData)) {
    data = [...data, ...renderTitle(number, index, page, 'Raw Data')]
    number += 1
    page += 1
    index += 1
    countOverview += 1
  }

  if (listChecked.includes(ReportAnalysisTypeMap.ICAComponents)) {
    data = [...data, ...ICAComponent(number, index, page)]
    number += 1
    page += 10
    index += 20
    countOverview += 1
  }

  if (listChecked.includes(ReportAnalysisTypeMap.BandPower)) {
    data = [...data, ...bandPower(number, index, page)]
    number += 1
    page += 3
    index += 4
    countOverview += 3
  }

  if (listChecked.includes(ReportAnalysisTypeMap.PowerSpectrum)) {
    data = [...data, ...powerSpectrum(number, index, page)]
    number += 1
    page += 3
    index += 4
    countOverview += 2
  }

  if (listChecked.includes(ReportAnalysisTypeMap.OccipitalAlphaPeak)) {
    data = [
      ...data,
      ...renderTitle(number, index, page, 'Occipital alpha peak'),
    ]
    number += 1
    page += 1
    index += 1
    countOverview += 1
  }

  if (listChecked.includes(ReportAnalysisTypeMap.PowerRatio)) {
    data = [...data, ...renderTitle(number, index, page, 'Power ratio')]
    number += 1
    page += 1
    index += 1
    countOverview += 1
  }

  if (listChecked.includes(ReportAnalysisTypeMap.SourceROIPower)) {
    data = [...data, ...sourceROI(number, index, page)]
    number += 1
    page += 36
    index += 5
    countOverview += 1
  }

  // Render table of content HRV
  if (listChecked.includes(HRVAnalysisTypeMap.TimeSeries) && screen === 'HRV') {
    data = [...data, ...renderTitle(number, index, page, `RR Time Series Data`)]
    number += 1
    page += 1
    index += 1
  }

  if (listChecked.includes(HRVAnalysisTypeMap.TimeDomain) && screen === 'HRV') {
    data = [...data, ...renderTitle(number, index, page, `Time-Domain Results`)]
    number += 1
    page += 2
    index += 1
  }

  if (
    listChecked.includes(HRVAnalysisTypeMap.FrequencyDomain) &&
    screen === 'HRV'
  ) {
    data = [
      ...data,
      ...renderTitle(number, index, page, ` Frequency-Domain Results `),
    ]
    number += 1
    page += 1
    index += 1
  }

  if (
    listChecked.includes(HRVAnalysisTypeMap.FrequencyDomain) &&
    screen === 'HRV'
  ) {
    data = [
      ...data,
      ...renderTitle(number, index, page, ` Nonlinear Results  `),
    ]
    number += 1
    page += 1
    index += 1
  }

  // Render overview and reference
  if (screen !== 'HRV') {
    if (listChecked.includes(OVERVIEW_ANALYSIS)) {
      data = [...data, ...renderParentTitle(index, page, `III. Overview`)]
      page += countOverview
      index += 1
    }

    if (listChecked.includes(REFERENCE_ANALYSIS)) {
      data = [...data, ...renderParentTitle(index, page, `IV. Reference`)]
      page += 1
      index += 1
    }
  }

  return data
}

export default generateTableOfContents
